import { createSlice, current } from "@reduxjs/toolkit";

export const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState: {}, // Store items by type
    reducers: {
        empty: () => {
            return {}; // Clear all warehouse types
        },
        setWarehouse: (state, action) => {
            if (Array.isArray(action?.payload?.data) && action.payload?.type) {
                state[action.payload.type] = action.payload.data;
            }
        },
        addWarehouse: (state, action) => {
            const { type, data } = action.payload;
            
            if (!type || !data) return state; // Ensure valid payload
        
            if (!state[type]) {
                state[type] = [];
            }
        
            // Make sure data is an array
            const newData = Array.isArray(data) ? data : [data];
        
            state[type] = [...state[type], ...newData];
        
            return state;
        },
        updateWarehouse: (state, action) => {
            const { type, id, data } = action.payload;
            if (type && id && data) {
                return {
                    ...state,
                    [type]: state[type]?.map(item => 
                        item.id === id ? { ...item, ...data } : item
                    ) || []
                };
            }
            console.log('sss', state, 'type, id, data', action.payload)
            return state;
        },        
        updateNote: (state, action) => {
            if (action.payload?.type && action.payload?.id) {
                const items = state[action.payload.type] || [];
                const index = items.findIndex((item) => item.id === action.payload.id);
                if (index !== -1) {
                    items[index] = { ...items[index], note: action.payload.note };
                }
            }
        },
        removeWarehouse: (state, action) => {
            const { type, id } = action.payload;
            if (!type || !id) return state;
        
            return {
                ...state,
                [type]: state[type]?.filter(item => item.id !== id) || [],
            };
        }        
    }
});

export const {
    empty,
    setWarehouse,
    updateWarehouse,
    updateNote,
    addWarehouse,
    removeWarehouse,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
