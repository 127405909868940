import { useEffect, useState } from "react";
import "./styles/index.scss";

const RangeSlider = ({ min, max, current, unit }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [currentDisplacement, setCurrentDisplacement] = useState(50); // Default to the middle
  const [leftDisplacement, setLeftDisplacement] = useState(0);
  const [rightDisplacement, setRightDisplacement] = useState(0);

  useEffect(() => {
    // Handle null min and max
    const minValue = min ?? (max ? current / 2 : current - current / 2);
    const maxValue = max ?? (min ? current * 2 : current + current / 2);

    const maxWidth = maxValue - minValue;

    let currDispl = 50; // Default to middle
    let leftDispl = 0;
    let rightDispl = 0;

    if (min === null || max === null) {
      currDispl = 50;
    } else if(current <= minValue) {
      leftDispl = minValue - current;
      currDispl = 0; // Place at the minimum edge
    } else if (current >= maxValue) {
      rightDispl = (current - maxValue) / current * 100;
      currDispl = 100; // Place at the maximum edge
    } else {
      currDispl = ((current - minValue) * 100) / maxWidth;
    }

    setLeftDisplacement(leftDispl);
    setRightDisplacement(rightDispl);
    setCurrentDisplacement(currDispl);

    // Check validity of current value
    setIsInvalid(current < minValue || current > maxValue);
  }, [min, max, current]);

  return (
    <div className={`slider-range`}>
      <div
        className="range"
        style={{
          left: `${leftDisplacement}%`,
          width: `${100 - leftDisplacement - rightDisplacement}%`,
        }}
      />
      <div
        className={`current-value ${isInvalid ? "invalid" : ""}`}
        style={{ left: `calc(${currentDisplacement}% - 5px)` }}
      >
        {`${current} ${unit}`}
      </div>
      <div
        className={`current-point ${isInvalid ? "invalid" : ""}`}
        style={{ left: `${currentDisplacement}%` }}
      />
      <div
        className="min-max"
        style={{
          width: `${100 - leftDisplacement - rightDisplacement}%`,
          left: `${leftDisplacement}%`,
          right: `${rightDisplacement}%`,
        }}
      >
        <span className="min" style={{ left: `${leftDisplacement}%` }}>
          {min ?? "-"}
        </span>
        <span className="max">{max ?? "-"}</span>
      </div>
    </div>
  );
};

export default RangeSlider;
