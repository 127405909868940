import { createSlice, current } from "@reduxjs/toolkit";

export const drugsSlice = createSlice({
    name: "drugs",
    initialState: [],
    reducers: {
        emptyDrugs: () => {
            return [];
        },
        setDrugs: (state, action) => {
            return Array.isArray(action?.payload) ? action.payload : state;
        },
        addDrugItem: (state, action) => {
            if (action.payload?.id) {
                const exists = state.some(drug => drug.id === action.payload.id);
                if (!exists) {
                    return [...state, action.payload]; // Return a new state array
                }
            }
            return state;
        },
        updateDrugItem: (state, action) => {
            return state.map(drug => 
                drug.id === Number(action?.payload?.id)
                    ? { ...drug, ...action.payload.data } // Correctly merges updated data
                    : drug
            );
        }, 
        removeDrugItem: (state, action) => {
            return state.filter(drug => drug.id !== action?.payload?.id);
        }
    }
});

export const {
    setDrugs, 
    emptyDrugs,
    updateDrugItem,
    removeDrugItem,
    addDrugItem
} = drugsSlice.actions;

export default drugsSlice.reducer;
