import "./styles/index.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAnalysisResults,
  getDrugs,
  getLots,
  getLotTracking,
  getProtocols,
  getTanks,
  getTankTracking,
  getUnits,
  getUsers,
} from "../../../services/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { NavLink, useParams } from "react-router-dom";
import useLabels from "../../../hooks/useLabels";
import { add, format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActiveWinery,
  selectAnalysis,
  selectConfigs,
  selectDrugs,
  selectLots,
  selectStoredAnalysisResults,
  selectTanks,
  selectTreatments,
  selectUnits,
  selectUsers,
} from "../../../context/selectors";
import { setUsers } from "../../../context/users/usersSlice";
import { setAnalysis } from "../../../context/protocols/analysisSlice";
import { setLots } from "../../../context/lots/lotsSlice";
import { setTreatments } from "../../../context/protocols/treatmentsSlice";
import { setDrugs } from "../../../context/substances/drugsSlice";
import { setTanks } from "../../../context/tanks/tanksSlice";
import { DestTankDetail } from "../../OperationsDetail/Details";
import { CTA } from "../../Generic";
import AnalysisEvolution from "../../AnalysisEvolution/AnalysisEvolutions";
import { addStoredAnalysisResults } from "../../../context/substances/storedAnalysisResultsSlice";
import { setUnits } from "../../../context/units/unitsSlice";
import Loader from "../../Loader";

const Timeline = ({ type, dates, lotName = '', labelName = '', name = ''}) => {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [getLabel] = useLabels();
  const { id } = useParams();
  const activeWinery = useSelector(selectActiveWinery);
  const configs = useSelector(selectConfigs);
  const users = useSelector(selectUsers);
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const units = useSelector(selectUnits);
  const analysis = useSelector(selectAnalysis);
  const drugs = useSelector(selectDrugs);
  const treatments = useSelector(selectTreatments);
  const storedAnalysisResults = useSelector(selectStoredAnalysisResults) || [];
  const [scrolling, setScrolling] = useState(false);
  const [analysisResults, setAnalysisResults] = useState([]);
  const [treatmentResults, setTreatmentResults] = useState([]);
  const [verticalTimelinesArray, setVerticalTimelinesArray] = useState([]);
  const [batchNames, setBatchNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchTracking() {
      await initData();

      if (analysis?.length === 0) {
        loadAnalysis();
      }
      const operations = (type === 'tank')
        ? await getTankTracking(axiosPrivate, id)
        : await getLotTracking(axiosPrivate, id);
      
      await initResults(operations);
      
      const ops = [];
      operations?.sort(
        (b, a) => new Date(b?.completion_date) - new Date(a?.completion_date))?.map((o, i) => {
        const tmp = {
          ...o,
          ...(o?.repetitive &&
            o?.main_id !== null && { hideRepetitive: true, opened: false }),
        };
        ops.push(tmp);
      });
      console.log('ops', ops)
      setVerticalTimelinesArray(ops);
      setIsLoading(false);

      // setVerticalTimelinesArray(
      //   operations?.map((o, i) => {
      //     return {
      //       ...o,
      //       ...(o?.repetitive &&
      //         o?.main_id !== null && { hideRepetitive: false, opened: false }),
      //     };
      //   })?.sort(
      //     (b, a) => new Date(b?.completion_date) - new Date(a?.completion_date)
      //   ) || []
      // );

      const batch_names = {};
      operations
        ?.filter((op) => !op.repetitive)
        ?.forEach((op, index) => {
          const operation = op?.dest_tanks?.find((t) => t.tank_id == id) || {};
          const operationSrc = op?.src_tanks?.find((t) => t.tank_id == id) || null;
          if (
            // ((index === 0 &&
            //   ["DESTEMMING", "NEW_LOT"].includes(op?.operation_type) &&
            //   operation?.old_quantity == 0 &&
            //   !operation?.old_batch_id) ||
            //   index > 0) &&
            "new_batch_name" in operation &&
            operation?.new_batch_name
          ) {
            batch_names[op?.operation_id] = {
              name: operation?.new_batch_name,
              type: operation?.new_batch_type,
              color: operation?.postOperation?.batch?.color?.toLowerCase() || 'red'
            };

            if (Number(operation?.old_quantity) === 0 && index === 0) {
              batch_names[op?.operation_id].isEmpty = true;
              batch_names[op?.operation_id].isFirst = true;
            }
          } else if (operationSrc !== null) {   // is a src, no new_batch_name in src so put it manually
            batch_names[op?.operation_id] = {
              name: operationSrc?.postOperation?.batch?.batch_name,
              type: 'OLD',
              color: operationSrc?.postOperation?.batch?.color?.toLowerCase() || 'red'
            };
            if (Number(operationSrc?.postOperation?.tank?.quantity) === 0)
              batch_names[op?.operation_id].isEmpty = true;
          }
        });
      setBatchNames(batch_names);
    }

    fetchTracking();

    // const handleScroll = () => {
    //   if (!scrolling) {
    //     setScrolling(true);
    //     !window.requestAnimationFrame
    //       ? setTimeout(checkTimelineScroll, 250)
    //       : window.requestAnimationFrame(checkTimelineScroll);
    //   }
    // };

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  const initData = async () => {
    const promiseArray = [];

    if (units?.length === 0) {
      promiseArray.push(loadUnits());
    }
    if (users?.length === 0) {
      promiseArray.push(loadUsers());
    }
    if (tanks?.length === 0) {
      promiseArray.push(loadTanks());
    }
    if (lots?.length === 0) {
      promiseArray.push(loadLots());
    }
    if (drugs?.length === 0) {
      promiseArray.push(loadDrugs());
    }
    if (treatments?.length === 0) {
      promiseArray.push(loadTreatments());
    }

    return Promise.all(promiseArray);
  };

  const initResults = async (operations) => {
    const analysisInStore =
      id === null
        ? []
        : storedAnalysisResults?.filter(
            (stored) => stored.operation_id === id
          ) || [];
    // console.log('analysisInStore', analysisInStore)
    // console.log('storedAnalysisResults', storedAnalysisResults)
    const analysisOpIds =
      (operations || [])?.filter((op) => op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT")?.map((o) => o.id) ||
      [];
    if (analysisOpIds?.length === 0) return;
    if (analysisInStore?.length === 0) {
      const anRes =
        (await getAnalysisResults(analysisOpIds, axiosPrivate)) || [];
      const anResults = anRes?.sort((a, b) => a?.id - b?.id);
      // console.log('anResults', anResults)

      if (anResults?.length > 0) {
        setAnalysisResults(anResults?.filter((op) => op?.operation_type === "ANALYSIS"));
        setTreatmentResults(anResults?.filter((op) => op?.operation_type === "TREATMENT"));
        dispatch(addStoredAnalysisResults(anResults));
        // console.log('not found')
      }

      //  else if (anResults?.length > 0 && anResults?.[0]?.results === null) {
      //     // setAnalysisState('NOT_INSERTED');
      // }
    } else {
      // console.log('found')
      setAnalysisResults(analysisInStore?.filter((op) => op?.operation_type === "ANALYSIS") || []);
      setTreatmentResults(analysisInStore?.filter((op) => op?.operation_type === "TREATMENT") || []);
    }
  };

  const loadUnits = async () => {
    const currentUnits = await getUnits(axiosPrivate);
    dispatch(setUnits(currentUnits));
  };

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery?.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadDrugs = async () => {
    const currentDrugs = await getDrugs(axiosPrivate);
    dispatch(setDrugs(currentDrugs));
  };

  const loadTreatments = async () => {
    const currentTreatments = await getProtocols("treatment", axiosPrivate);
    dispatch(setTreatments(currentTreatments));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery?.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const loadAnalysis = async () => {
    const currentAnalysis = await getProtocols("analysis", axiosPrivate);
    dispatch(setAnalysis(currentAnalysis));
  };

  const checkTimelineScroll = () => {
    verticalTimelinesArray?.forEach((timeline) => showBlocks(timeline));
    setScrolling(false);
  };

  const hideBlocks = (timeline) => {
    if (!"classList" in document.documentElement) {
      return; // no animation on older browsers
    }
    //hide timeline blocks which are outside the viewport
    var self = timeline;
    for (var i = 0; i < this.blocks.length; i++) {
      (function (i) {
        if (
          self.blocks[i].getBoundingClientRect().top >
          window.innerHeight * self.offset
        ) {
          self.images[i].classList.add("timeline-img--hidden");
          self.contents[i].classList.add("timeline-content--hidden");
        }
      })(i);
    }
  };

  const showBlocks = (timeline) => {
    if (!"classList" in document.documentElement) {
      return;
    }
    const self = timeline;
    for (var i = 0; i < this.blocks.length; i++) {
      (function (i) {
        if (
          self.contents[i].classList.contains("timeline-content--hidden") &&
          self.blocks[i].getBoundingClientRect().top <=
            window.innerHeight * self.offset
        ) {
          // add bounce-in animation
          self.images[i].classList.add("timeline-img--bounce-in");
          self.contents[i].classList.add("timeline-content--bounce-in");
          self.images[i].classList.remove("timeline-img--hidden");
          self.contents[i].classList.remove("timeline-content--hidden");
        }
      })(i);
    }
  };

  const getIconByOperationType = (type) => {
    switch (type) {
      case "TREATMENT":
        return "fa-prescription-bottle";
      case "ANALYSIS":
        return "fa-flask-vial";
      case "RACKING":
        return "fa-sitemap";
      case "CUT":
        return "fa-sitemap";
      case "DECANT":
        return "fa-sitemap";
      case "DESTEMMING":
        return "fa-cubes-stacked";
      case "BOTTLING":
        return "fa-wine-bottle";
      case "NEW_LOT":
        return "fa-tent-arrow-down-to-line";
      default:
        return "";
    }
  };

  const renderInOutArrow = (op) => {
    let isSrc = null;
    if (type === 'tank') {
      if (op?.src_tanks?.find((t) => t?.tank_id === Number(id)) !== undefined) isSrc = true;
      else if (op?.dest_tanks?.find((t) => t?.tank_id === Number(id)) !== undefined) isSrc = false;
    } else {
      if (op?.src_tanks?.find((t) => t?.postOperation?.batch?.batch_id === Number(id)) !== undefined) isSrc = true;
      else if (op?.dest_tanks?.find((t) => t?.postOperation?.batch?.batch_id === Number(id)) !== undefined) isSrc = false;
    }
    if (isSrc === null) return;

    return <span className={`timeline-arrow ${isSrc ? "out" : "in"}`}>
      <FontAwesomeIcon
        icon={'fa-arrow-left'}
        className={`timeline-icon operation`}
      />
    </span>
  }

  const renderLegend = () => {
    return <div className={'timeline-legend'}>
      <h5>{getLabel("legend")}</h5>
      <ul>
        {type === 'tank' && <li>
          <span>{getLabel("timelineLotNameTank")}</span>
          <span className={"legend-span"}>
            <span className={"legend-item"}>
              {"test"}
            </span>
          </span>
        </li>}
        {type === 'tank' && <li>
          <span>{getLabel("timelineLotNewNameTank")}</span><span className="timeline-tag legend-item">{getLabel("new")}</span>
        </li>}
        <li>
          <span>{getLabel("timelineTankIn")}</span><span className={"legend-span legend-arrow in"}><FontAwesomeIcon icon={'fa-arrow-left'} /></span>
        </li>
        <li>
          <span>{getLabel("timelineTankOut")}</span><span className={"legend-span legend-arrow out"}><FontAwesomeIcon icon={'fa-arrow-left'} /></span>
        </li>
      </ul>
    </div>;
  };

  const toggleTooltip = (op_id) => {
    if (!id || !op_id) return;
    const index = verticalTimelinesArray?.findIndex((o) => o?.operation_id === op_id);
    
    if (index === -1) return;
    
    setVerticalTimelinesArray((prevState) => [
      ...prevState?.slice(0, index),
      {
        ...prevState[index],
        showTooltip: !prevState[index]?.showTooltip,
      },
      ...prevState?.slice(index + 1),
    ]);
  };

  const getReassemblyData = (op_data) => {
    return (<>
      {op_data?.homogenizationType && <div className="user">
        <span>{getLabel("protocolHomogenizationType")}</span>
        <p className="par">{getLabel(`protocolHomogenizationMass_${op_data?.homogenizationType}`)}</p>
      </div>}
      {op_data?.work_time && <div className="user">
        <span>{getLabel("protocolWorkTime")}</span>
        <p className="par">{op_data?.work_time} {op_data?.work_timeUnit || 'min'}</p>
      </div>}
      {op_data?.homogenizationpause_time && <div className="user">
        <span>{getLabel("protocolPauseTime")}</span>
        <p className="par">{op_data?.homogenizationpause_time} {op_data?.pause_time_unit || 'h'}</p>
      </div>}
      {op_data?.homogenization_motor_speed && <div className="user">
        <span>{getLabel("protocolMotorSpeed")}</span>
        <p className="par">{op_data?.homogenization_motor_speed} {'%'}</p>
      </div>}
      {op_data?.iter_per_day && <div className="user">
        <span>{getLabel("protocolOxygenationIterPerDay")}</span>
        <p className="par">{op_data?.iter_per_day} </p>
      </div>}
    </>)
  }

  const getOxygenationData = (op_data) => {
    return (<>
      {op_data?.protocolType && <div className="user">
        <span>{getLabel("protocolOxigenationTypology")}</span>
        <p className="par">{getLabel(`protocolOXYGENATION_${op_data?.protocolType}`)}</p>
      </div>}
      {op_data?.quantity && <div className="user">
        <span>{getLabel("protocolQuantity")}</span>
        <p className="par">{op_data?.quantity} {op_data?.product_unit}{op_data?.protocolType === 'MICRO' ? getLabel('protocolOxygenationMonth') : 
          op_data?.protocolType === 'MACRO' ? 
                getLabel('protocolOxygenationDay') : op_data?.protocolType === 'CUSTOM' ?
                `/${op_data?.oxygenation_dose_hours}h:${op_data?.oxygenation_dose_minutes}m` : ''}</p>
      </div>}
    </>)
  }

  const getTankData = (op) => {
    const allTanks = [...(op?.src_tanks || []), ...(op?.dest_tanks || [])];
    const tank = allTanks?.find((t) => Number(t?.tank_id) == id) || {};
    const tankData = ((op?.completion_date === null) ? tank?.preOperation?.tank : tank?.postOperation?.tank) || {};
    
    if (Object.keys(tankData)?.length === 0 && ['ANALYSIS', 'TREATMENT'].includes(op?.operation_type)) {
      const srcTank = op?.src_tanks?.find((t) => Number(t?.tank_id) == id) || {};
      tankData.name = srcTank?.name;
      tankData.batch_name = srcTank?.batch_name;
    }

    return tankData || {};
  };

  const renderLotData = (op) => {    
    const lot = op?.transfers?.[0] || {};
    const additionalSrc = [];
    for (let i = 0; i < lot?.source_batch_varieties?.length; i++) {
      const v = lot?.source_batch_varieties?.[i] ? getLabel(`lotVarieties_${lot?.source_batch_varieties?.[i]}`) : '';
      const y = lot?.source_batch_years?.[i] ? ` (${lot?.source_batch_years?.[i]})` : '';
      const o = lot?.source_batch_origins?.[i] ? `, ${lot?.source_batch_origins?.[i]}` : '';
      additionalSrc.push(`${v} ${y}${o}`);
    }
    
    const additionalDest = [];
    for (let i = 0; i < lot?.destination_batch_varieties?.length; i++) {
      const v = lot?.destination_batch_varieties?.[i] ? getLabel(`lotVarieties_${lot?.destination_batch_varieties?.[i]}`) : '';
      const y = lot?.destination_batch_years?.[i] ? ` (${lot?.destination_batch_years?.[i]})` : '';
      const o = lot?.destination_batch_origins?.[i] ? `, ${lot?.destination_batch_origins?.[i]}` : '';
      additionalDest.push(`${v} ${y}${o}`);
    }
    // if (op?.operation_type == 'DESTEMMING') console.log(lot, additionalDest)
    const destLots = [];
    op?.transfers?.forEach((d) => {
      const lotName = d?.batch_name || '';
      if (destLots?.indexOf(lotName) === -1) destLots.push(lotName);
    });

    if (op?.operation_type === 'NEW_LOT') additionalSrc.push(`${op?.src_tanks?.[0]?.state && getLabel(`lotState_${op?.src_tanks?.[0]?.state}`)}, ${op?.src_tanks?.[0]?.quantity || 0}L`);
    
    const srcLot = type === 'lot' ? 
      op?.src_tanks?.[0] : {};

    return (
      <>
        {!['TREATMENT', 'ANALYSIS', 'ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN', 'HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && <>
          {((op?.operation_type !== 'CUT' && type === 'tank') || (['DESTEMMING', 'NEW_LOT'].includes(op?.operation_type) && type === 'lot')) && <p className="user">
            <span>{getLabel("operationSrcLot")}</span>
            {['DESTEMMING', 'NEW_LOT', 'DECANT', 'RACKING'].includes(op?.operation_type) ? op?.transfers?.[0]?.source_batch_name || '' : type === 'lot' ? srcLot?.name : lot?.name}
            {/* {old_actual_quantity} */}
          </p>}
          <div className="user">
            <span>{getLabel("lotDetailsIn")}</span>
            <div className="additional">{additionalSrc?.map((a, index) => { return <p key={`par-src-${index}`} className="par">{a}</p>})}</div>
          </div>
          {/* {type === 'lot' && <div className="user">
            <span>{getLabel("lotQuantity")}</span>
            {lot?.quantity || ''} L
          </div>} */}
          <div className="user">
            <span>{destLots?.length > 1 ? getLabel("operationDestLots") : getLabel("operationDestLot")}</span>
            {/* {destLots?.map((d, i) => `${i > 0 && d ? ', ' : ''}${d}`)} */}
            <div className="additional">{destLots?.map((d, index) => { return <p key={`add-${index}`} className="par">{d}</p>})}</div>
          </div>
          <div className="user">
            <span>{getLabel("lotDetailsOut")}</span>
            <div className="additional">{additionalDest?.map((a, index) => { return <p key={`par-dest-${index}`} className="par">{a}</p>})}</div>
          </div>
          {Number(lot?.drop) > 0 && <div className="user">
            <span>{getLabel("operationDrop")}</span>
            {lot?.drop || ''} L
          </div>}
        </>}
        {op?.operation_type === 'TREATMENT' && <>
          <div className="user">
            <span>{getLabel("treatmentProtocols")}</span>{(treatments?.find((t) => t?.protocols_id === op?.protocol_id))?.protocols_name || ''}
          </div>
          <div className="user">
            <span>{getLabel("protocolTreatmentSubstancesUsed")}</span>
            <div className="additional">
              {op?.treatments?.filter((o) => o?.removed !== true)?.map((t, index) => {
                return <p key={`par-${index}`} className="par"><span className="bold">{t?.substance_name}</span>{` - ${t?.quantity} ${units?.find((u) => u.id == t?.unit_id)?.unit || ''}`}</p>
                // treatments?.map((t) => { return <p className="par">{t}</p>})}
              })}
            </div>
          </div>
        </>}
        {['ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN', 'HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && <>
          <div className="user">
            <span>{getLabel("protocolDay")}</span>
            <p className="par">{op?.src_tanks?.[0]?.day}</p>
          </div>
          {op?.src_tanks?.[0]?.phase && <div className="user">
            <span>{getLabel("protocolPhase")}</span>
            <p className="par">{getLabel(`protocolPhase_${op?.src_tanks?.[0]?.phase}`)}</p>
          </div>}
        </>}
        {['ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN'].includes(op?.operation_type) && <>
          <div className="user">
            <span>{getLabel("protocolTreatmentSubstancesUsed")}</span>
            <div className="additional">
              <p className="par"><span className="bold">{op?.src_tanks?.[0]?.product_name}</span>{` - ${op?.src_tanks?.[0]?.quantity} ${op?.src_tanks?.[0]?.product_unit || ''}`}</p>
            </div>
          </div>
        </>}
        {['HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && op?.src_tanks?.[0]?.protocolType === 'HOMOGENIZATION_TEMP' && <>
          <div className="user">
            <span>{getLabel("operationTypeHOMOGENIZATION_TEMP")}</span>
              <p className="par">{op?.src_tanks?.[0]?.quantity || '-'} {'\u00b0C'}</p>
          </div>
        </>}
        {['HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && op?.src_tanks?.[0]?.protocolType === 'HOMOGENIZATION_REASSEMBLY' && 
          getReassemblyData(op?.src_tanks?.[0])
        }
        {op?.src_tanks?.[0]?.operation === 'OXYGENATION' && 
          getOxygenationData(op?.src_tanks?.[0])
        }
      </>
    );
  };

  // const renderLotData2 = (op) => {
  //   const allTanks = [...(op?.src_tanks || []), ...(op?.dest_tanks || [])];
  //   // const dest = type === 'lot' || ['ANALYSIS', 'TREATMENT', 'NEW_LOT'].includes(op?.operation_type) ? op?.src_tanks?.[0] : op?.dest_tanks?.find((tank) => Number(tank?.tank_id) == id);
  //   const dest = (type === 'lot' && !['ANALYSIS', 'TREATMENT', 'NEW_LOT'].includes(op?.operation_type)) ? 
  //     allTanks?.find((tank) => Number(tank?.postOperation?.batch?.batch_id) == id || 
  //       tank?.postOperation?.batch?.batch_name === lotName) : 
  //     ['ANALYSIS', 'TREATMENT', 'NEW_LOT'].includes(op?.operation_type) ? 
  //       op?.src_tanks?.[0] : 
  //     allTanks?.find((tank) => Number(tank?.tank_id) == id);
  //   const batch_id = Number(op?.batch_id) || dest?.old_batch_id || ['ANALYSIS', 'TREATMENT', 'CUT'].includes(op?.operation_type) ? dest?.batch_id : null || null;
  //   // const lot = (type === 'lot' ? dest : dest?.postOperation?.batch) || null;//lots?.find((lot) => Number(lot?.id) === batch_id);
  //   const lot = dest?.postOperation?.batch || null;
  //   if (!lot === null || !batch_id === null) return <p></p>;
  //   // const old_actual_quantity = op?.src_tanks?.[0]?.old_actual_quantity
  //   //   ? ` (${op?.src_tanks?.[0]?.old_actual_quantity} Kg)`
  //   //   : "";

  //   const additional = [];
  //   for (let i = 0; i < lot?.varieties?.length; i++) {
  //     const v = lot?.varieties?.[i] ? getLabel(`lotVarieties_${lot?.varieties?.[i]}`) : '';
  //     const y = lot?.years?.[i] ? ` (${lot?.years?.[i]})` : '';
  //     const o = lot?.origins?.[i] ? `, ${lot?.origins?.[i]}` : '';
  //     additional.push(`${v} ${y}${o}`);
  //   }
    
  //   const destLots = [];
  //   op?.dest_tanks?.forEach((d) => {
  //     const lotName = d?.new_batch_name || '';
  //     if (destLots?.indexOf(lotName) === -1) destLots.push(lotName);
  //   });

  //   if (op?.operation_type === 'NEW_LOT') additional.push(`${op?.src_tanks?.[0]?.state && getLabel(`lotState_${op?.src_tanks?.[0]?.state}`)}, ${op?.src_tanks?.[0]?.quantity || 0}L`);
    
  //   const srcLot = type === 'lot' ? 
  //     op?.src_tanks?.[0] : {};

  //   // const treatments = [];
  //   // if (op?.operation_type === 'TREATMENT') {
  //   //   op?.treatments?.forEach((t) => {
  //   //     treatments.push(<span className="bold">{t?.substance_name}</span>`- ${t?.quantity} ${units?.find((u) => u.id == t?.unit_id)?.unit || ''}`);
  //   //   })
  //   // }

  //   // TO DO: op?.dest_tanks?.length > 1 not working, must loop the dest_tanks for new_batch_name... 
    
  //   return (
  //     <>
  //       {!['TREATMENT', 'ANALYSIS', 'ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN', 'HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && <>
  //         {((op?.operation_type !== 'CUT' && type === 'tank') || (['DESTEMMING', 'NEW_LOT'].includes(op?.operation_type) && type === 'lot')) && <p className="user">
  //           <span>{getLabel("operationSrcLot")}</span>
  //           {['DESTEMMING', 'NEW_LOT'].includes(op?.operation_type) ? op?.transfers?.[0]?.source_batch_name || '' : type === 'lot' ? srcLot?.name : lot?.name}
  //           {/* {old_actual_quantity} */}
  //         </p>}
  //         <div className="user">
  //           <span>{getLabel("lotDetails")}</span>
  //           <div className="additional">{additional?.map((a, index) => { return <p key={`par-${index}`} className="par">{a}</p>})}</div>
  //         </div>
  //         {/* {type === 'lot' && <div className="user">
  //           <span>{getLabel("lotQuantity")}</span>
  //           {lot?.quantity || ''} L
  //         </div>} */}
  //         <div className="user">
  //           <span>{destLots?.length > 1 ? getLabel("operationDestLots") : getLabel("operationDestLot")}</span>
  //           {/* {destLots?.map((d, i) => `${i > 0 && d ? ', ' : ''}${d}`)} */}
  //           <div className="additional">{destLots?.map((d, index) => { return <p key={`add-${index}`} className="par">{d}</p>})}</div>
  //         </div>
  //       </>}
  //       {op?.operation_type === 'TREATMENT' && <>
  //         <div className="user">
  //           <span>{getLabel("treatmentProtocols")}</span>{(treatments?.find((t) => t?.protocols_id === op?.protocol_id))?.protocols_name || ''}
  //         </div>
  //         <div className="user">
  //           <span>{getLabel("protocolTreatmentSubstancesUsed")}</span>
  //           <div className="additional">
  //             {op?.treatments?.filter((o) => o?.removed !== true)?.map((t, index) => {
  //               return <p key={`par-${index}`} className="par"><span className="bold">{t?.substance_name}</span>{` - ${t?.quantity} ${units?.find((u) => u.id == t?.unit_id)?.unit || ''}`}</p>
  //               // treatments?.map((t) => { return <p className="par">{t}</p>})}
  //             })}
  //           </div>
  //         </div>
  //       </>}
  //       {['ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN', 'HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && <>
  //         <div className="user">
  //           <span>{getLabel("protocolDay")}</span>
  //           <p className="par">{op?.src_tanks?.[0]?.day}</p>
  //         </div>
  //         {op?.src_tanks?.[0]?.phase && <div className="user">
  //           <span>{getLabel("protocolPhase")}</span>
  //           <p className="par">{getLabel(`protocolPhase_${op?.src_tanks?.[0]?.phase}`)}</p>
  //         </div>}
  //       </>}
  //       {['ENZYME', 'SO2', 'TANNISSAGE', 'BOIS', 'INOCULATION', 'NITROGEN'].includes(op?.operation_type) && <>
  //         <div className="user">
  //           <span>{getLabel("protocolTreatmentSubstancesUsed")}</span>
  //           <div className="additional">
  //             <p className="par"><span className="bold">{op?.src_tanks?.[0]?.product_name}</span>{` - ${op?.src_tanks?.[0]?.quantity} ${op?.src_tanks?.[0]?.product_unit || ''}`}</p>
  //           </div>
  //         </div>
  //       </>}
  //       {['HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && op?.src_tanks?.[0]?.protocolType === 'HOMOGENIZATION_TEMP' && <>
  //         <div className="user">
  //           <span>{getLabel("operationTypeHOMOGENIZATION_TEMP")}</span>
  //             <p className="par">{op?.src_tanks?.[0]?.quantity || '-'} {'\u00b0C'}</p>
  //         </div>
  //       </>}
  //       {['HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && op?.src_tanks?.[0]?.protocolType === 'HOMOGENIZATION_REASSEMBLY' && 
  //         getReassemblyData(op?.src_tanks?.[0])
  //       }
  //       {op?.src_tanks?.[0]?.operation === 'OXYGENATION' && 
  //         getOxygenationData(op?.src_tanks?.[0])
  //       }
  //     </>
  //   );
  // };

  const renderOperationData = (op) => {

    switch (op?.operation_type) {
      case "NEW_LOT":
        const lotIn = op?.src_tanks?.[0];
        const varieties = lotIn?.varieties?.map((v) =>
          v ? getLabel(`lotVarieties_${v}`) : ""
        );
        const years = lotIn?.years?.map((y, index) =>
          y ? y : index > 0 ? lotIn?.years[index - 1] : ""
        );
        const origins = lotIn.origins?.map((o) => o || "");

        return (<>
          {/* <p className="user">
            <span>{getLabel("lotNameExternal")}</span>        // TO DO: manca label[]
            {lot?.name || ''}
          </p> */}
          
        {/* <p className="user">
          <span>{getLabel("operationDestemmingLot")}</span>
          {lotIn?.name}
         </p> */}
        {/* <p className="user">
          <span>{getLabel("lotState")}</span>
          {getLabel(`lotState_${lotIn?.state}`)}
        </p>
        <p className="user">
          <span>{getLabel("quantity")}</span>
          {lotIn?.quantity || 0}
        </p> */}
        {/* <p className="user">
          <span>{getLabel("lotVarieties")}</span>
          {varieties}
        </p>
        <p className="user">
          <span>{getLabel("lotYears")}</span>
          {years}
        </p>
        <p className="user">
          <span>{getLabel("lotOrigins")}</span>
          {origins}
        </p> */}
        {/* <div className="user">
          <span>{getLabel(op?.dest_tanks?.length > 1 ? "operationNewLotsOut" : "operationNewLotOut")}</span>
          {op?.dest_tanks?.map((l) => {return <DestLotDetail isDestemming={true} lot={l}/>}) }
        </div> */}
        </>)
      default:
        return;
    }
  };

  const toggleHideRepetitive = (main_id) => {
    if (!main_id) return;
    setVerticalTimelinesArray((prevState) => [
      ...prevState?.map((o) => {
        return {
          ...o,
          ...(o?.id === main_id && o?.opened && { showTooltip: false }),
          ...(o?.main_id === main_id && {
            hideRepetitive: !o?.hideRepetitive,
            opened: !o?.opened,
          }),
          ...(o?.id === main_id && { opened: !o?.opened }),
        };
      }),
      // ...prevState?.slice(0, index),
      //   {
      //       ...prevState[index],
      //       showTooltip: !prevState[index]?.showTooltip,
      //   },
      //   ...prevState?.slice(index + 1)
    ]);
  };

  const getTanksByBatch = () => {
    const tankList = new Set();
    verticalTimelinesArray?.forEach((t) => {
      if (t?.type === 'ANALYSIS') {
        t?.src_tanks?.forEach(tank => {
          const foundTank = tanks?.find((tt) => tt.id == tank?.tank_id) || null;
          if (foundTank !== null) tankList.add(foundTank);
        });
      }
    });

    return Array.from(tankList);
  }

  const filterTreatments = () => {
    if (type === 'tank') return treatmentResults?.filter((op) => Number(op?.tank_id) == id);
    else if (type === 'lot') {
      // must have unique operation_id in case of lot
      const result = Object.values(
        treatmentResults?.reduce((acc, obj) => ({ ...acc, [obj?.operation_id]: obj }), {})
      );
      return result;
    }
    return treatmentResults;
  }

  const renderAnalysisEvolution = () => {
    return (
      <div>
        <div className="primary_container_menu">
          {/* <h4>{getLabel('nextOperations')}</h4> */}
        </div>
        {analysis?.length > 0 && analysisResults?.length > 0 && (
          <AnalysisEvolution
            configs={configs}
            treatmentResults={filterTreatments()}
            analysisResults={analysisResults?.filter(
              (op) => type === 'tank' ? Number(op?.tank_id) == id : Number(op?.batch_id) 
            )}
            lots={[
              ...new Set(
                analysisResults
                  ?.filter((op) => type === 'tank' ? Number(op?.tank_id) == id : Number(op?.batch_id))
                  ?.map((r) =>
                    lots?.find((l) => l.id == r.batch_id && r.tank_id === null)
                  )
                  .filter(Boolean)
              ),
            ]}
            tanks={type === 'tank' ? [tanks?.find((t) => Number(t?.id) == id)] : getTanksByBatch()}
            exams={[
              ...new Set(
                analysis?.filter((exam) =>
                  analysisResults
                    ?.filter((op) => type === 'tank' ? Number(op?.tank_id) == id : Number(op?.batch_id))
                    ?.find((an) => exam.protocols_id === an.protocol_id)
                )
              ),
            ]}
          />
        )}
      </div>
    );
  };

  const renderAnalysisData = (op) => {
    const exam =
      analysisResults?.find((an) => an?.operation_id === op?.operation_id) || null;
    const analysisProtocol =
      analysis?.find((an) => an?.protocols_id === exam?.protocol_id) || null;
    if (exam === null || analysisProtocol === null) return;

    const treatments = [];
    if (op?.operation_type === 'TREATMENT') {
      op?.treatments?.forEach((t) => {
        treatments.push(`${t?.substance_name} - ${t?.quantity} ${units?.find((u) => u.id == t?.unit_id)?.unit || ''}`);
      })
    }

    return (
      <>
        <p className="user">
          <span>{getLabel("dateCollection")}</span>
          {format(
            new Date(exam?.collection_date) || null,
            configs.hourMediumDateFormat
          )}
        </p>
        <p className="user">
          <span>{getLabel("analysisAnalyst")}</span>
          {exam?.analyst}
        </p>
        <p className="user">
          <span>{getLabel("analysisProtocols")}</span>
          {analysisProtocol?.protocols_name}
        </p>
        {type === 'tank' && <div>
          <div className="user">
            <span>{op?.operation_type === 'ANALYSIS' ? getLabel("analysisResults"): getLabel("protocolTreatmentSubstancesUsed")}</span>
            {op?.operation_type === 'ANALYSIS' ? <div className="additional">{Object.keys(exam?.results)?.map((res) => {
              return (
                  <p className="par"><span className="bold">{getLabel(`substance_${res}`)}</span>{" - "}{exam?.results?.[res]}
                  {" "}{analysisProtocol?.analysis?.find(
                    (a) => a?.substance_name === res
                  )?.unit || ""}</p>
                  // TO DO: recurrent analysis count must be done on completed number, not total numb of repetition
              );
            })}</div> :
            <div className="additional">{treatments?.map((t) => { return <p className="par">{t}</p>})}</div>}
          </div>
          {/* <ul className="result-list">
            {Object.keys(exam?.results)?.map((res) => {
              return (
                <li className="result">
                  <span>{getLabel(`substance_${res}`)}</span>
                  {op?.operation_type === 'ANALYSIS' && <span>{exam?.results?.[res]}{" "}
                  {analysisProtocol?.analysis?.find(
                    (a) => a?.substance_name === res
                  )?.unit || ""}
                  </span>}
                </li>
              );
            })}
          </ul> */}
        </div>}
        {type === 'lot' && <div>
          <div className="user">
            <span>{op?.operation_type === 'ANALYSIS' ? getLabel("protocolAnalysisSubstanceIds"): getLabel("protocolTreatmentSubstancesUsed")}</span>
            {op?.operation_type === 'ANALYSIS' ? <div className="additional">
              {Object.keys(exam?.results)?.map((res) => {
              return (
                  <p className="par"><span className="bold">{getLabel(`substance_${res}`)}</span>
                  {" - "}{exam?.results?.[res]}
                  {" "}{analysisProtocol?.analysis?.find(
                    (a) => a?.substance_name === res
                  )?.unit || ""}
                  </p>
              );
            })}</div>:
            <div className="additional">{treatments?.map((t) => { return <p className="par">{t}</p>})}</div>}
          </div>
          {/* <ul className="result-list">
            {Object.keys(exam?.results)?.map((res) => {
              return (
                <li className="result">
                  <span>{getLabel(`substance_${res}`)} </span>
                </li>
              );
            })}
          </ul> */}
        </div>}
      </>
    );
  };

  const getTitle = (op) => {
    if (['HOMOGENIZATION_MASS', 'OXYGENATION'].includes(op?.operation_type) && op?.src_tanks?.[0]?.protocolType === 'HOMOGENIZATION_REASSEMBLY') 
      return getLabel(`operationType${op?.src_tanks?.[0]?.protocolType}`)
    else if (op?.src_tanks?.[0]?.operation === 'OXYGENATION') {
      const opType = getLabel(`protocolType_${op?.src_tanks?.[0]?.protocolType}`);
      const shortenOpType = op?.src_tanks?.[0]?.protocolType === 'CUSTOM' ? (opType?.slice(0, 6) + '.') : opType;
      return `${getLabel(`protocolOperation_${op?.src_tanks?.[0]?.operation}`)} ${shortenOpType?.toLowerCase()}`
    } else return getLabel(`operationType${op?.operation_type}`)
  }

  if (isLoading) return <div className="loader_wrapper"><Loader /></div>

  return (
    <div>
      {/* <div className={''}><h4>{name}</h4></div>   // show name when scrolling out of the window  */}
      <div className={"timeline-block operation no-click"}>
        <div className={"timeline-img operation start"}>
          {format(
           dates?.startDate < new Date(verticalTimelinesArray?.[0]?.completion_date) ?
           new Date(verticalTimelinesArray?.[0]?.completion_date) : dates?.startDate,
            configs.shortDateFormat
          )}
          <FontAwesomeIcon
            icon={"fa-calendar"}
            className={"timeline-icon operation"}
          />
        </div>
      </div>
      <section className="timeline">
        {!isLoading && verticalTimelinesArray?.length === 0 && <h5 className="alert_no_info">{getLabel("tankTimelineNoData")}</h5>}

        {!isLoading && verticalTimelinesArray?.length > 0 && <div className="timeline-container">
          {verticalTimelinesArray
            ?.filter(
              (o) =>
                !o?.hideRepetitive &&
                  new Date(o?.completion_date) >= (dates?.startDate || new Date(new Date().getFullYear() - 1, 7, 1)) &&
                  new Date(o?.completion_date) <= (dates?.endDate || new Date())
            )
            ?.map((op, index) => {
              return (
                <div key={`timeline-${index}`}>
                  {(type === 'tank' || type === 'lot') && <div>
                    {batchNames?.[op?.operation_id]?.name !== undefined && batchNames?.[op?.operation_id]?.isEmpty === true && batchNames?.[op?.operation_id]?.isFirst === true && (
                      <div className={"timeline-block operation no-click"}>
                        <div className={'timeline-img empty_tank_tile_border'}></div>
                        <div className={'timeline-img empty_tank_tile'}>{getLabel('tankState_EMPTY')}</div>
                    </div>
                    )}
                    <div
                      className={`timeline-block operation ${
                        (op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT") && op?.repetitive
                          ? "repetitive"
                          : ""
                      }`}
                      onClick={() =>
                        op?.opened || !op?.repetitive || (op?.repetitive && Number(op?.completed) <= 1)
                          ? toggleTooltip(op?.operation_id)
                          : toggleHideRepetitive(op?.operation_id)
                      }
                    >
                      {["CUT", "DECANT", "DESTEMMING", "RACKING", "NEW_LOT", "BOTTLING"].includes(op?.operation_type) && renderInOutArrow(op)}  
                      <div
                        className={`timeline-img operation ${
                          op?.showTooltip ? "tooltip" : ""
                        }`}
                      >
                        {getTitle(op)}
                        {getIconByOperationType(op?.operation_type) && (
                          <FontAwesomeIcon
                            icon={getIconByOperationType(op?.operation_type)}
                            className={`timeline-icon operation ${
                              op?.operation_type === "CUT" ? "reverse" : ""
                            }`}
                          />
                        )}
                        {(op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT") &&
                          op?.repetitive &&
                          // Number(op?.total) > 0 && (
                          Number(op?.completed) > 1 && (
                              <>
                              {!op?.opened && (
                                <span
                                  className={`timeline-counter ${
                                    op?.showTooltip ? "tooltip" : ""
                                  }`}
                                >
                                  {op?.completed}
                                  {/* {op?.total} */}
                                </span>
                              )}
                              {op?.main_id === null && Number(op?.completed) > 1 && (
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    toggleHideRepetitive(op?.operation_id);
                                    e.stopPropagation();
                                  }}
                                  icon={
                                    op?.opened ? "fa-angles-up" : "fa-angles-down"
                                  }
                                  className={`timeline-icon operation ${
                                    op?.opened ? "expand-opened" : "expand"
                                  }`}
                                />
                              )}
                            </>
                          )}
                      </div>
                      {!op?.opened &&
                        (op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT") &&
                        op?.repetitive &&
                        Number(op?.completed) > 1 &&
                        // Number(op?.total) > 0 &&
                        op?.main_id === null && (
                          <>
                            <div
                              className={`timeline-img operation shifted second`}
                            ></div>
                            <div
                              className={`timeline-img operation shifted`}
                            ></div>
                          </>
                        )}
                        <div className={`timeline-date-container ${op?.showTooltip ? "reverse" : ""}`}>
                          <p className="timeline-date operation">
                            {format(new Date(op?.completion_date) || null, configs.hourMediumDateFormat)}
                          </p>
                        </div>
                      <div className={`timeline-content ${op?.showTooltip ? 'visible' : 'hidden'}`}>
                        <div className="tooltip">
                        <p className="user">
                            <span>{getLabel("insertionBy")}</span>
                            {users?.find?.(
                              (user) =>
                                Number(user.id) === Number(op?.programmed_by)
                            )?.username || ""}
                          </p>
                          <p className="user">
                            <span>{getLabel("operationCompletionUser")}</span>
                            {users?.find?.(
                              (user) =>
                                Number(user.id) === Number(op?.completed_by)
                            )?.username || ""}
                          </p>
                          <p className="user">
                            <span>{getLabel("operationPriority")}</span>
                            {getLabel(`operationPriority${op?.priority}`)}
                          </p>
                          {renderLotData(op)}
                          {[
                            "RACKING",
                            "CUT",
                            "DECANT",
                            "DESTEMMING",
                            "NEW_LOT",
                            "BOTTLING"
                          ].includes(op?.operation_type) && op?.dest_tanks?.find((t) => Number(t?.tank_id) == id) !== undefined && (<>
                            <p className="user"><span className="recap">{getLabel("operationRecap")}</span></p>
                            <p className="user">
                              <span>{getLabel("operationDrop")}</span>
                              {`${
                                op?.drops?.find((t) => t.tank_id == id)
                                  ?.quantity || 0
                              }`} {op?.operation_type === 'DESTEMMING' ? 'Kg' : 'L'}
                            </p>
                            </>
                          )}
                          {["ANALYSIS"].includes(op?.operation_type) &&
                            renderAnalysisData(op)}
                          {/* {(type === 'lot') && (
                            <p className="user">
                              <span>{getLabel("tankName")}</span>
                              {getTankData(op)?.name || ""}
                            </p>
                          )} */}
                          {/* {(type === 'tank') && !['CUT', 'NEW_LOT'].includes(op?.operation_type) && (
                            <p className="user">
                              <span>{getLabel("lotName")}</span>
                              {getTankData(op)?.batch_name || ""}
                            </p>
                          )} */}
                          <div className="operation_info_container">
                            <div
                              className="operation_info_container_row"
                              style={{
                                scale: "0.85",
                                justifyContent: "center",
                              }}
                            >
                              {[
                                "RACKING",
                                "CUT",
                                "DECANT",
                                "DESTEMMING",
                                "NEW_LOT",
                              ].includes(op?.operation_type) && type === 'tank' && (
                                <DestTankDetail
                                  tank={op?.transfers?.find((t) => t?.source_tank_id == id || t?.destination_tank_id == id) || {}}
                                  // tank={getTankData(op)}
                                  operation={op}
                                  isName={false}
                                  isDrop={false}
                                  // isMobile={isMobile}
                                ></DestTankDetail>
                              )}
                            </div>
                            <div
                              className="operation_info_container_row"
                              style={{
                                scale: "0.85",
                                justifyContent: "center",
                              }}
                            >
                              <NavLink
                                to={`/programming/${op?.operation_type}/${op?.operation_id}`}
                              >
                                <CTA customClassName={"add"}>
                                  {getLabel("goToOperationDetail")}
                                </CTA>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {batchNames?.[op?.operation_id]?.name && (
                      <div className={"timeline-block operation no-click"}>
                        <div className={`timeline-img operation new-batch-name color_${batchNames?.[op?.operation_id]?.color}`}>
                          {batchNames?.[op?.operation_id]?.name}
                        </div>
                        <p className="timeline-tag">
                          {["NEW", "INHERITED"].includes(
                            batchNames?.[op?.operation_id]?.type
                          )
                            ? getLabel("new")
                            : ""}
                        </p>
                      </div>
                    )}
                    {batchNames?.[op?.operation_id]?.name === undefined && batchNames?.[op?.operation_id]?.isEmpty === true && batchNames?.[op?.operation_id]?.isFirst === undefined && (
                      <div className={"timeline-block operation no-click"}>
                        <div className={'timeline-img empty_tank_tile_border'}></div>
                        <div className={'timeline-img empty_tank_tile'}>{getLabel('tankState_EMPTY')}</div>
                    </div>
                    )}
                  </div>}
                  {/* {type === 'lot2' && <div>
                    <div
                      className={`timeline-block operation ${
                        (op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT")
                        && op?.repetitive
                          ? "repetitive"
                          : ""
                      }`}
                      onClick={() =>
                        op?.opened || !op?.repetitive
                          ? toggleTooltip(op?.operation_id)
                          : toggleHideRepetitive(op?.operation_id)
                      }
                    >
                      <div
                        className={`timeline-img operation ${
                          op?.showTooltip ? "tooltip" : ""
                        }`}
                      >
                        {getTitle(op)}
                        {getIconByOperationType(op?.operation_type) && (
                          <FontAwesomeIcon
                            icon={getIconByOperationType(op?.operation_type)}
                            className={`timeline-icon operation ${
                              op?.operation_type === "CUT" ? "reverse" : ""
                            }`}
                          />
                        )}
                        {(op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT") &&
                          op?.repetitive &&
                          Number(op?.total) > 0 && (
                            <>
                              {!op?.opened && (
                                <span
                                  className={`timeline-counter ${
                                    op?.showTooltip ? "tooltip" : ""
                                  }`}
                                >
                                  {op?.total}
                                </span>
                              )}
                              {op?.main_id === null && (
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    toggleHideRepetitive(op?.operation_id);
                                    e.stopPropagation();
                                  }}
                                  icon={
                                    op?.opened ? "fa-angles-up" : "fa-angles-down"
                                  }
                                  className={`timeline-icon operation ${
                                    op?.opened ? "expand-opened" : "expand"
                                  }`}
                                />
                              )}
                            </>
                          )}
                      </div>
                      {!op?.opened &&
                        (op?.operation_type === "ANALYSIS" || op?.operation_type === "TREATMENT") &&
                        op?.repetitive &&
                        Number(op?.total) > 0 &&
                        op?.main_id === null && (
                          <>
                            <div
                              className={`timeline-img operation shifted second`}
                            ></div>
                            <div
                              className={`timeline-img operation shifted`}
                            ></div>
                          </>
                        )}
                      {op?.showTooltip && (
                        <div className="timeline-content">
                          <div className="tooltip">
                            <p className="user">
                              <span>{getLabel("operationCompletionUser")}</span>
                              {users?.find?.(
                                (user) =>
                                  Number(user.id) === Number(op?.completed_by)
                              )?.username || ""}
                            </p>
                            <p className="user">
                              <span>{getLabel("operationPriority")}</span>
                              {getLabel(`operationPriority${op?.priority}`)}
                            </p>
                            {renderLotData(op)}
                            {renderOperationData(op)}
                            {["ANALYSIS"].includes(op?.operation_type) &&
                              renderAnalysisData(op)}
                            <div className="operation_info_container">
                              <div
                                className="operation_info_container_row"
                                style={{
                                  scale: "0.85",
                                  justifyContent: "center",
                                }}
                              >
                                <NavLink
                                  to={`/programming/${op?.operation_type}/${op?.operation_id}`}
                                >
                                  <CTA customClassName={"add"}>
                                    {getLabel("goToOperationDetail")}
                                  </CTA>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="timeline-date-container">
                        <span
                          className={`timeline-date operation ${
                            op?.showTooltip ? "reverse" : ""
                          }`}
                        >
                          {format(
                            new Date(op?.completion_date) || null,
                            configs.hourMediumDateFormat
                          )}
                        </span>
                      </div>
                    </div>
                    {batchNames?.[op?.operation_id]?.name && (
                      <div className={"timeline-block operation no-click"}>
                        <div className={`timeline-img operation new-batch-name`}>
                          {batchNames?.[op?.operation_id]?.name}
                        </div>
                        <p className="timeline-tag">
                          {["NEW", "INHERITED"].includes(
                            batchNames?.[op?.operation_id]?.type
                          )
                            ? getLabel("new")
                            : ""}
                        </p>
                      </div>
                    )}
                  </div>} */}
                </div>
              );
            })}
        </div>}
      </section>

      <div className={"timeline-block operation no-click"}>
        <div className={"timeline-img operation end"}>
          {format(dates?.endDate || new Date(), configs.shortDateFormat)}
          <FontAwesomeIcon
            icon={"fa-calendar-check"}
            className={"timeline-icon operation"}
          />
        </div>
      </div>
      {renderAnalysisEvolution()}
      <div>
        {renderLegend()}
      </div>
    </div>
  );
};

export default Timeline;
