import { Player } from "@lottiefiles/react-lottie-player";
import {
  Children,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useClickOutside from "../../hooks/useClickOutside";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Tree, TreeNode } from "react-organizational-chart";
import { renderToStaticMarkup } from "react-dom/server";

import json_arrow_black from "../../assets/lottieJson/black_arrow.json";
import json_arrow_white from "../../assets/lottieJson/white_arrow.json";
import json_check_white from "../../assets/lottieJson/white_check.json";
import json_check_black from "../../assets/lottieJson/black_check.json";
import json_red_grape from "../../assets/lottieJson/red_grape.json";
import json_white_grape from "../../assets/lottieJson/white_grape.json";
import json_rose_grape from "../../assets/lottieJson/rose_grape.json";

import "react-toastify/dist/ReactToastify.css";
import "./styles/button/index.scss";
import "./styles/cta/index.scss";
import "./styles/card/index.scss";
import "./styles/modal/index.scss";
import "./styles/dropdown/index.scss";
import "./styles/tooltip/index.scss";
import "./styles/tankicon/index.scss";
import "./styles/bottleicon/index.scss";
import "./styles/operation_recap/index.scss";
import "./styles/infocard/index.scss";
import "./styles/carousel/index.scss";

import useLabels from "../../hooks/useLabels";
import { DatePicker, Input, Label, Select, TextArea } from "../FormComponents";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  ALPHA_SPACE_APEX_DOT_REGEX,
  INT_FLOAT_REGEX,
  INT_REGEX,
  PWD_REGEX,
  USER_REGEX,
} from "../../constants/regex";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DestLotDetail } from "../OperationsDetail/Details";
import { motion } from "framer-motion";
import { selectUnits } from "../../context/selectors";
import { useSelector } from "react-redux";

const Button = ({
  label,
  isRed,
  isFull,
  customClassName,
  onClick,
  onMouseOver,
  onMouseLeave,
  arrowDirection = "right",
  disabled = false,
  customIcon = null,
  isNavLink,
  isActive,
  disableIcon,
}) => {
  const lottieRef = useRef();

  const handleClick = (event) => {
    if (disabled) return;
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  const handleMouseOver = (event) => {
    if (disabled) return;
    lottieRef?.current?.play?.();
    if (onMouseOver) {
      event.preventDefault();
      onMouseOver();
    }
  };

  const handleMouseLeave = (event) => {
    if (disabled) return;
    lottieRef?.current?.stop?.();
    if (onMouseLeave) {
      event.preventDefault();
      onMouseLeave();
    }
  };

  const renderNavLinkIcon = () => {
    if (disableIcon) return;
    return (
      <div className={`button_hole ${arrowDirection}`}>
        <div className="button_hole_icon_wrapper">
          {arrowDirection === "right" && (
            <Player
              autoplay={false}
              controls={false}
              hover={false}
              src={isRed ? json_check_white : json_check_black}
              style={{ width: "24px", height: "24px" }}
              ref={isActive ? lottieRef : null}
            />
          )}
          <div className="button_hole_icon_main">
            <Player
              autoplay={false}
              controls={false}
              hover={false}
              src={
                customIcon
                  ? customIcon
                  : isRed
                  ? json_arrow_white
                  : json_arrow_black
              }
              style={{
                width: "24px",
                height: "24px",
                transform: "rotateX(180deg)",
              }}
              ref={(isNavLink && !isActive) || !isNavLink ? lottieRef : null}
            />
            {/* {!isActive && <Player
              autoplay={false}
              controls={false}
              hover={true}
              src={customIcon}
              style={{ width: "24px", height: "24px", transform: 'rotateX(180deg)' }}
              ref={(isNavLink && !isActive) || !isNavLink ? lottieRef : null}
            />} */}
          </div>
          {arrowDirection === "left" && (
            <Player
              autoplay={false}
              controls={false}
              hover={false}
              src={isRed ? json_check_white : json_check_black}
              style={{ width: "24px", height: "24px" }}
              ref={isActive ? lottieRef : null}
            />
          )}
        </div>
      </div>
    );
  };

  const renderArrowIcon = () => {
    return (
      <div className={`button_hole ${arrowDirection}`}>
        <div className="button_hole_icon_main">
          <Player
            autoplay={false}
            controls={false}
            hover={false}
            src={
              customIcon
                ? customIcon
                : isRed
                ? json_arrow_white
                : json_arrow_black
            }
            style={{ width: "24px", height: "24px" }}
            ref={(isNavLink && !isActive) || !isNavLink ? lottieRef : null}
          />
        </div>
      </div>
    );
  };

  const renderLeftIcon = () => {
    return (
      <span>
        <Player
          autoplay={false}
          controls={false}
          hover={false}
          loop={false}
          src={customIcon}
          style={{
            width: "24px",
            height: "24px",
            display: "inline-flex",
            alignItems: "center",
          }}
          ref={(isNavLink && !isActive) || !isNavLink ? lottieRef : null}
        />
      </span>
    );
  };

  return (
    <button
      className={`button${isRed ? "_red" : ""}${isFull ? "_full" : ""} ${
        customClassName || ""
      }`}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
    >
      {" "}
      {isNavLink &&
        !customClassName.includes("burger_container_action") &&
        renderLeftIcon()}
      {isFull && (
        <label className={`button_label ${customClassName || ""}`}>
          {label}
        </label>
      )}
      {isNavLink ? renderNavLinkIcon() : renderArrowIcon()}
    </button>
  );
};

const CTA = ({
  children,
  customClassName,
  onClick,
  onMouseOver,
  onMouseLeave,
  isRed,
  isFull = false,
  disabled,
}) => {
  const handleClick = (event) => {
    if (disabled) return;
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
  };

  const handleMouseOver = (event) => {
    if (disabled) return;
    if (onMouseOver) {
      event.preventDefault();
      onMouseOver();
    }
  };

  const handleMouseLeave = (event) => {
    if (disabled) return;
    if (onMouseLeave) {
      event.preventDefault();
      onMouseLeave();
    }
  };

  return (
    <button
      className={`cta${isRed ? "_red" : ""}${isFull ? "_full" : ""} ${
        customClassName || ""
      }`}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
    >
      {children || ""}
      {customClassName === "complete"}
    </button>
  );
};

const Card = ({ children, customClassName }) => {
  return <div className={`base_card ${customClassName || ""}`}>{children}</div>;
};

const Modal = ({
  children,
  forwardRef,
  closeHandle,
  isOpen = false,
  customClassName,
  modalType = null,
}) => {
  if (!isOpen && modalType === null) return;

  return (
    <div className="modal_wrapper">
      {modalType === null && <div className="modal-overlay"></div>}
      <dialog
        ref={forwardRef}
        className={`base_modal ${customClassName || ""}`}
      >
        <div className="modal_container">{children}</div>
      </dialog>
    </div>
  );
};

const TankColumn = ({ rowData = [], tanks }) => {
  const getTankName = (id) => {
    return (
      tanks?.find?.((tank) => {
        return Number(tank.id) === Number(id);
      })?.name || ""
    );
  };
  return (
    <div>
      {rowData
        ?.filter(
          (
            item,
            index,
            self // filter duplicate tank_id (because of transfer_details)
          ) => index === self.findIndex((t) => t.tank_id === item.tank_id)
        )
        ?.map?.((row, index) => {
          return <div key={index}>{getTankName(row.tank_id)}</div>;
        })}
    </div>
  );
};

const BatchColumn = ({ rowData = {}, lots, type }) => {
  const getBatchName = (id) => {
    return (
      lots?.find?.((lot) => {
        return Number(lot.id) === Number(id);
      })?.name ||
      rowData?.src_tanks?.[0]?.batch_name ||
      ""
    );
  };

  if (type === "DESTEMMING")
    return <div>{getBatchName(rowData?.batch_id_in)}</div>;
  else
    return (
      <div>
        {rowData?.map?.((row) => {
          return <div>{getBatchName(row?.batch_id)}</div>;
        })}
      </div>
    );
};

const BottlingColumn = ({ rowData = {} }) => {
  return (
    <div>
      <p>
        <FontAwesomeIcon
          icon={"fa-bread-slice"}
          size={"xs"}
          style={{ color: "rgb(157, 126, 104)" }}
        />{" "}
        {rowData?.capLot || ""}
      </p>
      <p>
        <FontAwesomeIcon icon={"fa-wine-bottle"} size={"xs"} />{" "}
        {rowData?.bottleLot || ""}
      </p>
    </div>
  );
};

const CellarmanColumn = ({ rowData = [], users }) => {
  const getCellarmanName = (id) => {
    return (
      users?.find?.((user) => Number(user.id) === Number(id))?.username || ""
    );
  };

  return (
    <div>
      {rowData?.map?.((id) => {
        return <div key={id}>{getCellarmanName(id)}</div>;
      })}
    </div>
  );
};

const Dropdown = ({ label, options, customClassName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef();
  useClickOutside(wrapperRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <div
      className={`base_dropdown ${customClassName ? customClassName : ""}`}
      ref={wrapperRef}
      onClick={handleClick}
    >
      {label}
      {isOpen && (
        <div className="base_dropdown_list">
          {options?.map?.((option, idx) => {
            return (
              <div
                key={`option_dropdown_${idx}`}
                className="base_dropdown_list_option"
                onClick={option.handler}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Tooltip = ({
  variant = "warning",
  place,
  events,
  content,
  html,
  children,
  customClassName,
}) => {
  const [randomID, setRandomID] = useState(String(Math.random()));

  return (
    <div
      className={`tooltip-container ${
        customClassName === "is_submit" ? customClassName : ""
      }`}
    >
      <a
        data-tooltip-id={randomID}
        data-tooltip-content={content}
        data-tooltip-html={html}
        data-tooltip-offset={10}
      >
        {children || ""}
      </a>
      <ReactTooltip
        key={randomID}
        place={place}
        id={randomID}
        // className={status}
        className={`tooltip-diff-arrow${
          variant?.length > 0 ? `__${variant}` : ""
        } ${customClassName}`}
        classNameArrow={`tooltip-arrow`}
        event={events}
      />
    </div>
  );
};

const InfoTooltip = ({ html }) => {
  return (
    html?.length > 0 && (
      <span className="info-tooltip">
        <Tooltip
          variant={"info"}
          html={html}
          place="right"
          events={["hover"]}
          customClassName="tooltip-text"
        >
          <span className="icon" style={{ opacity: 100 }}>
            <FontAwesomeIcon icon="fa-circle-info" className="icon" />
          </span>
        </Tooltip>
      </span>
    )
  );
};

const CustomToastWithLink = ({ message, navigateTo }) => (
  <div>
    <Link to={navigateTo}>{message}</Link>
  </div>
);

const Toast = ({
  toastId,
  message,
  position = toast.POSITION.BOTTOM_RIGHT,
  type,
  autoClose = 4000,
  navigateTo = null,
  customClassName = "",
}) => {
  const getToastType = () => {
    switch (type) {
      case "info":
        return toast.TYPE.INFO;
      case "success":
        return toast.TYPE.SUCCESS;
      case "warning":
        return toast.TYPE.WARNING;
      case "error":
        return toast.TYPE.ERROR;
      default:
        return toast.TYPE.DEFAULT;
    }
  };

  const getToastPosition = () => {
    switch (position) {
      case "top-right":
        return toast.POSITION.TOP_RIGHT;
      case "top-left":
        return toast.POSITION.TOP_LEFT;
      case "top-center":
        return toast.POSITION.TOP_CENTER;
      case "bottom-right":
        return toast.POSITION.BOTTOM_RIGHT;
      case "bottom-left":
        return toast.POSITION.BOTTOM_LEFT;
      case "bottom-center":
        return toast.POSITION.BOTTOM_CENTER;
      default:
        return toast.POSITION.BOTTOM_RIGHT;
    }
  };

  const notify = () => {
    toast(
      navigateTo === null ? (
        message
      ) : (
        <CustomToastWithLink navigateTo={navigateTo} message={message} />
      ),
      {
        position: getToastPosition(),
        autoClose: autoClose,
        type: getToastType(),
        toastId: toastId,
        icon: true,
      }
    );
  };

  return (
    <div className={`base_toast ${customClassName || ""}`}>{notify()}</div>
  );
};

const ConfirmationModal = ({
  isOpen,
  hasCloseBtn = true,
  onConfirm,
  onClose,
  children,
  title,
  description,
  description2 = null,
  totalStep = 0,
  step,
  setStep,
  confirmMessage,
  closeMessage,
  isOperationRecap = false,
  errors,
  getValues,
  setNewBatchName,
  isAnalysis = false,
  setError,
  clearErrors,
  type,
}) => {
  const [getLabel] = useLabels();
  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isOpen) {
        modalElement.close();
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isOpen]);

  return (
    isOpen && (
      <Modal
        forwardRef={modalRef}
        closeHandle={onClose}
        isOpen={isOpen}
        children={children}
        customClassName={`confirmation winemaking ${
          isOperationRecap ? "recap" : ""
        }`}
      >
        <div className={"confirmation-modal-container"}>
          <h2 className="title">
            {title ? getLabel(title) : getLabel("modalNoTitle")}
          </h2>
          <br></br>
          {(isAnalysis || totalStep === 0 || step === totalStep) && (
            <>
              <p className="description">
                {description || getLabel("modalNoDescription")}
              </p>
              {description2 && <p className="description2">{description2}</p>}
            </>
          )}
          {!isAnalysis && totalStep > 0 && step < totalStep && (
            <p className="description">
              {getLabel("modalNewBatchNameDescription") ||
                getLabel("modalNoDescription")}
            </p>
          )}
          {children}
          <div className="button-container">
            <CTA
              customClassName={"dialog confirm"}
              isRed={false}
              onClick={() => {
                if (!isAnalysis) {
                  if (totalStep === 0) {
                    onConfirm();
                  } else {
                    // if (errors?.new_batch_name) return;

                    // if (
                    //   !getValues("new_batch_name") &&
                    //   !getValues("batch_name_selector")?.label
                    // )
                    //   return;
                    // const new_batch_name = getValues("new_batch_name") ||
                    // getValues("batch_name_selector")?.label;

                    // setNewBatchName(new_batch_name);

                    if (totalStep > 0 && step < totalStep) {
                      setStep((prev) => prev + 1);
                    }
                    if (step === totalStep) {
                      onConfirm();
                    }
                  }
                } else {
                  // console.log('qui', errors, getValues('collection_date')?.filter((e) => e))
                  // return;
                  // if (step === totalStep) {

                  // }
                  // else {
                  if (
                    getValues("collection_date")?.filter((e) => e)?.length === 0
                  ) {
                    setError("collection_date", {
                      type: "manual",
                      message: getLabel("inputRequiredError"),
                    });
                    // return;
                    // } else if (errors?.collection_date) {
                    //   setError(errors?.collection_date);
                    // return;
                  } else clearErrors("collection_date");
                  // }

                  if (
                    !getValues("start_date") &&
                    !["ANALYST", "EXCEL_ANALYSIS"].includes(type)
                  ) {
                    setError("start_date", {
                      type: "manual",
                      message: getLabel("errorAnalysisExamDateFrom"),
                    });
                    // return;
                    // } else if (errors?.start_date) {
                    //   setError(errors?.start_date);
                    // return;
                  } else clearErrors("start_date");

                  if (!getValues("end_date")) {
                    setError("end_date", {
                      type: "manual",
                      message: getLabel("errorAnalysisExamDateTo"),
                    });
                    // return;
                    // } else if (errors?.end_date) {
                    // setError(errors?.end_date);
                    // return;
                  } else clearErrors("end_date");

                  if (
                    new Date(getValues("end_date")) <=
                    new Date(getValues("start_date"))
                  ) {
                    setError("end_date", {
                      type: "manual",
                      message: getLabel("errorAnalysisExamDateInvalidOrder"),
                    });
                    // return;
                  } else clearErrors("end_date");

                  if (errors?.temperature || errors?.collection_date) return;
                  // return;
                  if (totalStep > 0 && step < totalStep) {
                    setStep((prev) => prev + 1);
                  }

                  if (step === totalStep) {
                    // return;
                    onConfirm();
                  }
                }
              }}
            >
              {totalStep === 0 && <p>{getLabel("modalConfirmButton")}</p>}
              {totalStep > 0 && step < totalStep && (
                <p>{getLabel("modalNextStep")}</p>
              )}
              {totalStep > 0 && step === totalStep && (
                <p>{getLabel("modalConfirmButton")}</p>
              )}
            </CTA>
            <CTA
              customClassName={"dialog deny"}
              isRed={false}
              onClick={onClose}
            >
              {getLabel("modalDenyButton")}
            </CTA>
          </div>
        </div>
      </Modal>
    )
  );
};

const WineMakingProtocolRowModal = ({
  isOpen = false,
  hasCloseBtn = true,
  onConfirm,
  onClose,
  children,
  title,
  confirmMessage,
  closeMessage,
  errors,
  getValues,
  setError,
  clearErrors,
}) => {
  const [getLabel] = useLabels();
  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isOpen) {
        modalElement.close();
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isOpen]);

  return (
    isOpen && (
      <Modal
        forwardRef={modalRef}
        closeHandle={onClose}
        isOpen={isOpen}
        children={children}
        customClassName={"winemaking"}
      >
        <div className={"confirmation-modal-container"}>
          <h2 className="title">
            {title ? getLabel(title) : getLabel("modalNoTitle")}
          </h2>
          <br></br>
          {children}
          <div className="button-container">
            <CTA
              customClassName={"dialog confirm"}
              isRed={false}
              onClick={() => onConfirm()}
            >
              <p>{getLabel("modalConfirmButton")}</p>
            </CTA>
            <CTA
              customClassName={"dialog deny"}
              isRed={false}
              onClick={onClose}
            >
              {getLabel("modalDenyButton")}
            </CTA>
          </div>
        </div>
      </Modal>
    )
  );
};

const OperationRecap = ({
  control,
  errors,
  register,
  getValues,
  setValue,
  setError,
  clearErrors,
  availableLotNames = [],
  lotNames = [],
  src_tanks = [],
  dest_tanks = [],
  step,
  totalStep = 0,
  type,
  recursiveDates,
  setRecursiveDates,
  configs,
  lots,
  tanks,
  units,
  setFile,
  isScale = true,
  setPointBoundaries = {},
  isCredential = false,
  additionalType = null,
  additionalData = [],
  arrayFields = [],
}) => {
  const [getLabel] = useLabels();
  const [key, setKey] = useState(0);

  const customId = "custom-id-reuse-name";

  const disableDate = (item) => {
    const tmpRecursiveDates = recursiveDates;
    const foundDate = tmpRecursiveDates?.find((d, i) => i === item) || null;
    if (foundDate === null) return;
    foundDate.checked = !foundDate.checked;

    setRecursiveDates(tmpRecursiveDates);
    setKey((prev) => prev + 1);
  };

  const filterUnits = () => {
    const allowedTreatmentTypes = [1, 2, 6]; // mg/L, g/hL, g/L needed for concentrations
    return [
      ...units?.filter((u) =>
        type === "treatment" ? allowedTreatmentTypes.includes(u?.id) : true
      ),
    ]?.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  };

  const getUnitOptions = (substance) => {
    const unit_id = substance?.unit_id || null;
    if (!unit_id) return [];

    const options = [
      {
        value: "3",
        label: getLabel("drugBatchUnit_L"),
      },
      {
        value: "4",
        label: getLabel("drugBatchUnit_Kg"),
      },
      {
        value: "5",
        label: getLabel("drugBatchUnit_Unit"),
      },
    ];

    switch (unit_id) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 7:
        // Kg
        return [options[1]];
      case 3:
        // L
        return [options[0]];
      case 5:
        // unit
        return [options[2]];
    }

    return [];
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const renderByType = () => {
    if (type === "NEW_STOCK")
      return (
        <div className="rows">
          <h5>{getLabel("drugBatchUnit_Unit")}</h5>
          <div className="row_wrapper">
            <Select
              name="unit"
              control={control}
              label={getLabel("drugBatchUnit")}
              placeholder={getLabel("drugBatchUnitPlaceholder")}
              error={errors["unit"]}
              required={getLabel("inputRequiredError")}
              options={getUnitOptions(additionalData?.[0])}
            />
          </div>
          <h5>{getLabel("drugBatchNumbers")}</h5>
          <div className="row_wrapper">
            <div className="substance_form_wrapper_rows">
              {getValues("unit")?.value &&
                arrayFields?.fields?.map?.((field, idx) => {
                  return (
                    <div
                      key={field.id}
                      className="substance_form_wrapper_row fields"
                    >
                      <Input
                        name={`batches.${idx}.batch_number`}
                        register={register}
                        label={getLabel(`drugBatchNumber`)}
                        placeholder={getLabel("drugBatchNumberPlaceholder")}
                        error={getError(idx, errors?.batches, "batch_number")}
                        required={getLabel("inputRequiredError")}
                        minLength={{
                          value: 3,
                          message: getLabel("errorMinLength", { value: 3 }),
                        }}
                        maxLength={{
                          value: 24,
                          message: getLabel("errorMaxLength", {
                            value: 24,
                          }),
                        }}
                        regExpPattern={{
                          value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                          message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        // tooltip={
                        //   idx === 0
                        //     ? getLabel("destemmingTankDestQuantityTooltip")
                        //     : ""
                        // }
                      />
                      {getValues(`batches.${idx}.batch_number`) && (
                        <DatePicker
                          name={`batches.${idx}.expire_date`}
                          control={control}
                          label={getLabel("drugExpireDate")}
                          error={errors["expire_date"]}
                          required={getLabel("inputRequiredError")}
                          dateFormat={configs.shortDateFormat}
                          placeholder={getLabel("expiringDatePlaceholder")}
                        />
                      )}
                      {getValues(`batches.${idx}.batch_number`) && (
                        <>
                          <Input
                            name={`batches.${idx}.stock`}
                            error={getError(idx, errors?.batches, "stock")}
                            register={register}
                            type="number"
                            label={getLabel("drugBatchQuantity")}
                            placeholder={getLabel(
                              "drugBatchQuantityPlaceholder",
                              {
                                unit: getValues("unit")?.label,
                              }
                            )}
                            // error={errors["new_batch_name"]}
                            required={getLabel("inputRequiredError")}
                            min={{
                              value: 0.1,
                              message: getLabel("errorMinValue", {
                                value: 0.1,
                              }),
                            }}
                            max={{
                              value: 1000,
                              message: getLabel("errorMaxValue", {
                                value: 1000,
                              }),
                            }}
                            regExpPattern={{
                              value:
                                getValues("unit")?.value === 5
                                  ? INT_REGEX
                                  : INT_FLOAT_REGEX,
                              message: getLabel("errorIntFloat"),
                            }}
                            tooltip={
                              idx === 0
                                ? getLabel("drugBatchQuantityTooltip")
                                : ""
                            }
                          />
                          <Input
                            name={`batches.${idx}.cost`}
                            error={getError(idx, errors?.batches, "cost")}
                            register={register}
                            type="number"
                            label={getLabel("drugBatchUnitCost")}
                            placeholder={getLabel(
                              "drugBatchUnitCostPlaceholder",
                              { currency: "€", unit: getValues("unit")?.label }
                            )}
                            // error={errors["new_batch_name"]}
                            required={getLabel("inputRequiredError")}
                            min={{
                              value: 1,
                              message: getLabel("errorMinValue", { value: 1 }),
                            }}
                            max={{
                              value: 1000,
                              message: getLabel("errorMaxValue", {
                                value: 1000,
                              }),
                            }}
                            regExpPattern={{
                              value: INT_FLOAT_REGEX,
                              message: getLabel("errorIntFloat"),
                            }}
                            tooltip={
                              idx === 0
                                ? getLabel("drugBatchUnitCostTooltip", {
                                    batch: getValues(
                                      `batches.${idx}.batch_number`
                                    ),
                                    unit: getValues("unit")?.label,
                                  })
                                : ""
                            }
                          />
                        </>
                      )}
                      {arrayFields?.fields?.length > 0 && (
                        <button
                          className="minus_button"
                          onClick={(e) => {
                            e.preventDefault();
                            arrayFields.remove(idx);
                          }}
                        >
                          &minus;
                        </button>
                      )}
                    </div>
                  );
                })}
              <button
                id="batchesD"
                className="plus_button"
                onClick={(e) => {
                  e.preventDefault();
                  arrayFields.append({
                    batchesData: {},
                  });
                }}
              >
                +
              </button>
            </div>
          </div>
          <h5>{getLabel("drugAdditionalNotes")}</h5>
          <div className="row_wrapper">
            <TextArea
              name="notes"
              register={register}
              label={getLabel("drugNotes")}
              placeholder={getLabel("drugNotesPlaceholder")}
              error={errors["notes"]}
              maxLength={{
                value: 100,
                message: getLabel("errorMaxLength", { value: 100 }),
              }}
            />
          </div>
        </div>
      );
    else if (type === "NEW_TREATMENT")
      return (
        <div className="rows">
          <h5>{getLabel("drugTitle")}</h5>
          <div className="row_wrapper">
            <Input
              name="name"
              register={register}
              type="text"
              label={getLabel("drugName")}
              placeholder={getLabel("drugNamePlaceholder")}
              error={errors["name"]}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", { value: 24 }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
            />
            <Select
              name="unit_id"
              control={control}
              label={getLabel("unit")}
              placeholder={getLabel("unitPlaceholder")}
              error={errors["unit"]}
              required={getLabel("inputRequiredError")}
              options={filterUnits()}
              defaultValue={{
                value: 0,
                label: "",
              }}
            />
            <Select
              name="type"
              control={control}
              label={getLabel("drugType")}
              placeholder={getLabel("drugTypePlaceholder")}
              error={errors["type"]}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  value: "",
                  label: "",
                },
                {
                  value: "additive",
                  label: getLabel("drugType_ADDITIVE"),
                },
                {
                  value: "adjuvant",
                  label: getLabel("drugType_ADJUVANT"),
                },
              ]}
            />
          </div>
          <h5>{getLabel("substanceApplicationRang")}</h5>
          <div className="row_wrapper">
            <Input
              name="min_range"
              register={register}
              type="number"
              label={getLabel("drugMinRange")}
              placeholder={getLabel("drugMinRangePlaceholder")}
              error={errors["min_range"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              max={{
                value: getValues("max_range")?.toString() || null,
                message: getLabel("errorDrugQuantityOverMax"),
              }}
            />
            <Input
              name="max_range"
              register={register}
              type="number"
              label={getLabel("drugMaxRange")}
              placeholder={getLabel("drugMaxRangePlaceholder")}
              error={errors["max_range"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_range")?.toString() || -1,
                message: getLabel("errorDrugQuantityUnderMin"),
              }}
            />
            <Input
              name="quantity"
              register={register}
              type="number"
              required={getLabel("inputRequiredError")}
              label={getLabel("drugIdealQuantity")}
              placeholder={getLabel("drugIdealQuantityPlaceholder")}
              error={errors["quantity"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_range")?.toString() || -1,
                message: getLabel("errorDrugQuantityUnderMin"),
              }}
              max={{
                value: getValues("max_range")?.toString() || null,
                message: getLabel("errorDrugQuantityOverMax"),
              }}
            />
          </div>
          <h5>{getLabel("substanceTemperature")}</h5>
          <div className="row_wrapper">
            <Input
              name="min_temperature"
              register={register}
              type="number"
              label={getLabel("drugMinTemperature")}
              placeholder={getLabel("drugMaxTempPlaceholder")}
              error={errors["min_temperature"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              max={{
                value: getValues("max_temperature")?.toString() || null,
                message: getLabel("errorDrugTemperatureOverMax"),
              }}
            />
            <Input
              name="max_temperature"
              register={register}
              type="number"
              label={getLabel("drugMaxTemperature")}
              placeholder={getLabel("drugMaxTempPlaceholder")}
              error={errors["max_temperature"]}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 7,
                message: getLabel("errorMaxLength", { value: 7 }),
              }}
              regExpPattern={{
                value: INT_FLOAT_REGEX,
                message: getLabel("errorIntFloat"),
              }}
              min={{
                value: getValues("min_temperature")?.toString() || -1,
                message: getLabel("errorDrugTemperatureUnderMin"),
              }}
            />
          </div>
          <h5>{getLabel("drugIngredients")}</h5>
          <div className="row_wrapper">
            <div className="substance_form_wrapper_rows">
              {arrayFields?.fields?.map?.((field, idx) => {
                return (
                  <div
                    key={field.id}
                    className="substance_form_wrapper_row fields"
                  >
                    <Input
                      name={`ingredients.${idx}.name`}
                      register={register}
                      label={getLabel(`drugIngredient`)}
                      placeholder={getLabel("drugIngredientPlaceholder")}
                      error={getError(idx, errors?.ingredients, "name")}
                      // required={getLabel("inputRequiredError")}
                      minLength={{
                        value: 3,
                        message: getLabel("errorMinLength", { value: 3 }),
                      }}
                      maxLength={{
                        value: 24,
                        message: getLabel("errorMaxLength", {
                          value: 24,
                        }),
                      }}
                      regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                      }}
                      validate={(value) => {
                        if (
                          additionalData?.some(
                            (c) =>
                              c?.name?.toLowerCase() ===
                              value?.toLowerCase()?.trim()
                          )
                        ) {
                          return getLabel("errorNewCapNameAlreadyTaken", {
                            name: value,
                          });
                        }

                        return true; // Validation passed
                      }}
                      // tooltip={
                      //   idx === 0
                      //     ? getLabel("destemmingTankDestQuantityTooltip")
                      //     : ""
                      // }
                    />
                    {getValues(`ingredients.${idx}.name`) && (
                      <Input
                        name={`ingredients.${idx}.quantity`}
                        error={getError(idx, errors?.ingredients, "quantity")}
                        register={register}
                        type="number"
                        label={getLabel("drugIngredientQuantity")}
                        placeholder={getLabel(
                          "drugIngredientQuantityPlaceholder"
                        )}
                        required={getLabel("inputRequiredError")}
                        min={{
                          value: 1,
                          message: getLabel("errorMinValue", { value: 1 }),
                        }}
                        max={{
                          value: 100,
                          message: getLabel("errorMaxValue", {
                            value: 100,
                          }),
                        }}
                        regExpPattern={{
                          value: INT_REGEX,
                          message: getLabel("errorInt"),
                        }}
                        tooltip={
                          idx === 0
                            ? getLabel("drugIngredientQuantityTooltip")
                            : ""
                        }
                      />
                    )}
                    {arrayFields?.fields?.length > 0 && (
                      <button
                        className="minus_button"
                        onClick={(e) => {
                          e.preventDefault();
                          arrayFields.remove(idx);
                        }}
                      >
                        &minus;
                      </button>
                    )}
                  </div>
                );
              })}
              <button
                className="plus_button"
                id="ingredientsD"
                onClick={(e) => {
                  e.preventDefault();
                  arrayFields.append({
                    ingredientData: {},
                  });
                }}
              >
                +
              </button>
            </div>
          </div>
          <h5>{getLabel("drugAdditionalNotes")}</h5>
          <div className="row_wrapper">
            <TextArea
              name="notes"
              register={register}
              label={getLabel("drugNotes")}
              placeholder={getLabel("drugNotesPlaceholder")}
              error={errors["notes"]}
              maxLength={{
                value: 100,
                message: getLabel("errorMaxLength", { value: 100 }),
              }}
            />
          </div>
        </div>
      );
    else if (type === "REMOVE_TREATMENT")
      return (
        <div className="remove_item">
          <Label label={getLabel("drugName")}>
            {additionalData?.[0]?.name}
          </Label>
          <Label label={getLabel("drugQuantity")}>
            {`${additionalData?.[0]?.quantity} ${
              additionalData?.[0]?.unit || ""
            }`}
          </Label>
          <Label label={getLabel("drugType")}>
            {getLabel(`drugType_${additionalData?.[0]?.type}`)}
          </Label>
        </div>
      );
    else if (type === "REMOVE_WAREHOUSE")
      return (
        <div className="remove_item">
          <Label
            label={getLabel(
              additionalType === "bottles" ? "bottleName" : "capName"
            )}
          >
            {additionalData?.[0]?.name}
          </Label>
          <Label
            label={getLabel(
              additionalType === "bottles" ? "bottleOrigin" : "capOrigin"
            )}
          >
            {additionalData?.[0]?.origin}
          </Label>
          <Label
            label={getLabel(
              additionalType === "bottles" ? "bottleQuantity" : "capQuantity"
            )}
          >
            {additionalData?.[0]?.quantity}
          </Label>
        </div>
      );
    else if (type === "NEW_WAREHOUSE")
      return (
        <div className="new_item">
          <div className="row_wrapper">
            <Input
              name={"new_warehouse_name"}
              // error={"new_warehouse_name"}
              error={errors["new_warehouse_name"]}
              register={register}
              type="text"
              label={getLabel(
                additionalType === "bottles" ? "bottleName" : "capName"
              )}
              placeholder={getLabel("operationNewBatchNamePlaceholder")}
              required={getLabel("inputRequiredError")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", {
                  value: 24,
                }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
              validate={(value) => {
                if (
                  additionalData?.some(
                    (c) =>
                      c?.name?.toLowerCase() === value?.toLowerCase()?.trim()
                  )
                ) {
                  return getLabel("errorNewCapNameAlreadyTaken", {
                    name: value,
                  });
                }

                return true; // Validation passed
              }}
            />
            <Input
              name={"new_warehouse_origin"}
              error={"new_warehouse_origin"}
              register={register}
              type="text"
              label={getLabel(
                additionalType === "bottles" ? "bottleOrigin" : "capOrigin"
              )}
              placeholder={getLabel("capOriginPlaceholder")}
              minLength={{
                value: 3,
                message: getLabel("errorMinLength", { value: 3 }),
              }}
              maxLength={{
                value: 24,
                message: getLabel("errorMaxLength", {
                  value: 24,
                }),
              }}
              regExpPattern={{
                value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                message: getLabel("errorAlphaNumSpace_-"),
              }}
            />
            <Input
              name={"new_warehouse_quantity"}
              label={getLabel(
                additionalType === "bottles" ? "bottleQuantity" : "capQuantity"
              )}
              placeholder={getLabel("capQuantityPlaceholder")}
              // tooltip={getLabel("bulkStartTooltip")}
              register={register}
              error={errors["new_warehouse_quantity"]}
              required={getLabel("inputRequiredError")}
              type="number"
              min={{
                value: 1,
                message: getLabel("errorMinValue", { value: 1 }),
              }}
              max={{
                value: 10000,
                message: getLabel("errorMaxValue", { value: 10000 }),
              }}
              regExpPattern={{
                value: INT_REGEX,
                message: getLabel("errorInt"),
              }}
            />
          </div>
          <TextArea
            name="new_warehouse_notes"
            register={register}
            label={getLabel(
              additionalType === "bottles" ? "bottleNote" : "capNote"
            )}
            placeholder={getLabel("clientNotesPlaceholder")}
            // error={errors["new_warehouse_notes"]}
          />
        </div>
      );
    else if (type === "SENSOR_SETPOINT") {
      return (
        <Input
          name="new_set_point"
          register={register}
          type="number"
          label={getLabel("sensorNewSetPoint")}
          placeholder={getLabel("sensorNewSetPointPlaceholder")}
          error={errors["new_set_point"]}
          required={getLabel("inputRequiredError")}
          defaultValue={setPointBoundaries?.default || ""}
          min={{
            value: setPointBoundaries?.min || "0",
            message: getLabel("errorMinValue", {
              value: setPointBoundaries?.min || "0",
            }),
          }}
          max={{
            value: setPointBoundaries?.max,
            message: getLabel("errorMinValue", {
              value: setPointBoundaries?.max,
            }),
          }}
          regExpPattern={{
            value: INT_FLOAT_REGEX,
            message: getLabel("errorIntFloat"),
          }}
        />
      );
    } else if (type === "ANALYST") {
      return (
        <Input
          name="analyst"
          register={register}
          type="text"
          label={getLabel("analysisAnalyst")}
          placeholder={getLabel("analysisAnalystPlaceholder")}
          error={errors["analyst"]}
          required={getLabel("inputRequiredError")}
          isReverse={true}
          minLength={{
            value: 3,
            message: getLabel("errorMinLength", { value: 3 }),
          }}
          maxLength={{
            value: 32,
            message: getLabel("errorMaxLength", { value: 32 }),
          }}
          regExpPattern={{
            value: ALPHA_SPACE_APEX_DOT_REGEX,
            message: getLabel("errorAlphaSpaceApex"),
          }}
        />
      );
    } else if (type === "EXCEL_ANALYSIS") {
      return (
        <Input
          name="file"
          register={register}
          type="file"
          label={getLabel("operationExcelFileImport")}
          placeholder={getLabel("operationExcelFileImportPlaceholder")}
          error={errors["file"]}
          tooltip={getLabel("operationExcelFileImportTooltip")}
          accept=".xls,.xlsx"
          onChange={(e) => {
            setFile(e.target.files[0]);
            e.stopPropagation();
          }}
        />
      );
    } else if (type === "RECURRENT_ANALYSIS") {
      return (
        <ul key={`ul-date-${key}`} className={"recursive_date"}>
          {recursiveDates?.map((date, index) => (
            <li key={`rec-${index}`}>
              <input
                type="checkbox"
                checked={date?.checked}
                onChange={() => disableDate(index)}
              />
              <p>{format(date?.date, date?.format)}</p>
            </li>
          ))}
        </ul>
      );
    } else
      return (
        <div className={`operation_recap`}>
          {type !== "ANALYSIS" && (
            <>
              {totalStep > 0 && step < totalStep && (
                <div className="batch_name">
                  <Select
                    control={control}
                    defaultValue={{
                      value: "",
                      label: "",
                    }}
                    name="batch_name_selector"
                    label={""}
                    placeholder={getLabel("operationNewLotNamePlaceholder")}
                    error={errors["batch_id_in"]}
                    required={getLabel("inputRequiredError")}
                    options={[
                      {
                        value: true,
                        label: getLabel("lotNewName"),
                      },
                      ...availableLotNames,
                    ]}
                  />
                  {getValues("batch_name_selector")?.value && (
                    <Input
                      name="new_batch_name"
                      register={register}
                      type="text"
                      label={getLabel("operationNewBatchName")}
                      placeholder={getLabel("operationNewBatchNamePlaceholder")}
                      error={errors["new_batch_name"]}
                      required={getLabel("inputRequiredError")}
                      isReverse={true}
                      minLength={{
                        value: 3,
                        message: getLabel("errorMinLength", { value: 3 }),
                      }}
                      maxLength={{
                        value: 24,
                        message: getLabel("errorMaxLength", { value: 24 }),
                      }}
                      regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                      }}
                      validate={(value) => {
                        if (lotNames?.includes(value?.trim()))
                          setError("new_batch_name", {
                            type: "manual",
                            message: getLabel("errorNewBatchNameAlreadyTaken", {
                              name: value,
                            }),
                          });
                        else if (
                          getValues("batch_name_selector")?.value &&
                          lotNames?.includes(value?.trim())
                        ) {
                          toast.warning(
                            getLabel("toast_lotNameAlreadyPresent"),
                            {
                              toastId: customId,
                              position: toast.POSITION.BOTTOM_RIGHT,
                              exclude: true,
                              autoClose: 5000,
                            }
                          );
                        } else {
                          clearErrors("new_batch_name");
                          toast.dismiss(customId);
                        }
                      }}
                    />
                  )}
                </div>
              )}
              {(totalStep === 0 || step === totalStep) && (
                <div
                  className={`graph ${
                    ["CUT"].includes(type) ? "reverse" : ""
                  } ${!isScale ? "no-scale" : ""}`}
                >
                  {type === "CUT" && dest_tanks?.length > 0 && (
                    <Tree
                      lineWidth={"2px"}
                      lineColor={"#64242E"}
                      lineBorderRadius={"20px"}
                      label={
                        <TankIcon // dest_tank
                          tank={{
                            ...dest_tanks[0],
                          }}
                          isReverse={true}
                          isSrc={false}
                          isRecap={true}
                          isText={true}
                          isArrowQuantity={true}
                          noDestError={errors?.dest_tanks}
                        />
                      }
                    >
                      {src_tanks?.map((tank) => (
                        <TreeNode
                          label={
                            <TankIcon
                              tank={{
                                ...tank,
                                toRemove: -Number(tank.quantity),
                                quantity: Number(tank.quantity),
                              }}
                              isText={true}
                              isReverse={true}
                              isRecap={true}
                              noDestError={errors?.dest_tanks}
                              isArrowQuantity={true}
                              isSrc={true}
                            />
                          }
                        ></TreeNode>
                      ))}
                    </Tree>
                  )}
                  {type === "DESTEMMING" && src_tanks?.length > 0 && (
                    <Tree
                      lineWidth={"2px"}
                      lineColor={"#64242E"}
                      lineBorderRadius={"20px"}
                      label={
                        <TreeNode
                          label={
                            <DestLotDetail
                              lot={src_tanks[0]}
                              isDestemming={true}
                              isRecap={true}
                              toRemove={
                                dest_tanks?.reduce((acc, curr) => {
                                  return (
                                    acc + (Number(curr?.add_quantity) || 0)
                                  );
                                }, 0) || 0
                              }
                            />
                          }
                        ></TreeNode>
                      }
                    >
                      {dest_tanks
                        // ?.filter((d) => d?.tankData?.value && d?.quantity)
                        ?.map((tank) => (
                          <TreeNode
                            label={
                              <TankIcon tank={tank} isArrowQuantity={true} />
                            }
                          ></TreeNode>
                          // <TreeNode
                          //   label={
                          //     <TankIcon
                          //       tank={{
                          //         ...tank?.tankData,
                          //         lot:
                          //           tank?.batch_name_selector?.value === true
                          //             ? tank?.new_batch_name
                          //             : tank?.batch_name_selector?.label,
                          //         name: tank?.new_batch_name,
                          //         old_quantity: Number(tank?.tankData?.quantity) || 0,
                          //         quantity:
                          //           Number(tank?.quantity) || 0,
                          //         toAdd: Number(tank?.quantity) || 0 || 1,
                          //         color:
                          //           tank?.tankData?.color ||
                          //           src_tanks[0]?.color,
                          //       }}
                          //       isText={true}
                          //       isDestemming={true}
                          //       isReverse={false}
                          //       isRecap={true}
                          //       isSrc={false}
                          //       noDestError={errors?.dest_tanks?.tankData}
                          //     />
                          //   }
                          // ></TreeNode>
                        ))}
                    </Tree>
                  )}
                  {type === "NEW_LOT" && src_tanks?.length > 0 && (
                    <Tree
                      lineWidth={"2px"}
                      lineColor={"#64242E"}
                      lineBorderRadius={"20px"}
                      label={
                        <TreeNode
                          label={
                            <DestLotDetail
                              lot={src_tanks?.[0]}
                              isDestemming={true}
                              isRecap={true}
                              toRemove={Number(src_tanks?.[0]?.quantity) || 0}
                            />
                          }
                        ></TreeNode>
                      }
                    >
                      {dest_tanks
                        // ?.filter((d) => d?.tankData?.value && d?.quantity)
                        ?.map((tank) => (
                          <TreeNode
                            label={
                              <TankIcon
                                tank={tank}
                                isText={true}
                                isDestemming={true}
                                isReverse={false}
                                isArrowQuantity={true}
                                isRecap={true}
                                isSrc={false}
                                noDestError={errors?.dest_tanks?.tankData}
                              />
                            }
                          ></TreeNode>
                        ))}
                    </Tree>
                  )}
                  {["RACKING", "DECANT"].includes(type) &&
                    src_tanks?.length > 0 && (
                      <Tree
                        lineWidth={"2px"}
                        lineColor={"#64242E"}
                        lineBorderRadius={"20px"}
                        label={
                          <TreeNode
                            label={
                              <TankIcon
                                tank={{
                                  ...src_tanks[0],
                                  is_src: true,
                                  remove_quantity: src_tanks[0]?.arrow_quantity,
                                }}
                                isArrowQuantity={true}
                                isText={true}
                                isReverse={false}
                                isRecap={true}
                                noDestError={errors?.dest_tanks?.tankData}
                              />
                            }
                          ></TreeNode>
                        }
                      >
                        {dest_tanks
                          // ?.filter((d) => d?.tankData?.value && d?.quantity)
                          ?.map((tank) => (
                            <TreeNode
                              label={
                                <TankIcon tank={tank} isArrowQuantity={true} />
                              }
                            ></TreeNode>
                          ))}
                      </Tree>
                    )}
                  {type === "BOTTLING" && src_tanks?.length > 0 && (
                    <Tree
                      lineWidth={"2px"}
                      lineColor={"#64242E"}
                      lineBorderRadius={"20px"}
                      label={
                        <TreeNode
                          label={
                            <TankIcon
                              tank={{
                                ...src_tanks[0],
                                is_src: true,
                                remove_quantity: src_tanks[0]?.arrow_quantity,
                              }}
                              isArrowQuantity={true}
                              isText={true}
                              isReverse={false}
                              isRecap={true}
                              noDestError={errors?.dest_tanks?.tankData}
                            />
                          }
                        ></TreeNode>
                      }
                    >
                      {dest_tanks?.map((tank) => (
                        <TreeNode
                          label={
                            <BottleIcon
                              batch_name={tank?.batch_name}
                              color={tank?.color}
                              capLot={tank?.cap_lot}
                              bottleLot={tank?.bottle_lot}
                              toAdd={tank?.add_quantity}
                              isRecap={true}
                            />
                          }
                        ></TreeNode>
                      ))}
                    </Tree>
                  )}
                </div>
              )}
            </>
          )}
          {type === "ANALYSIS" && (
            <div className="analysis_wrapper_row">
              {src_tanks?.map((tank, index) => (
                <>
                  {index === step && (
                    <div key={`analysis_recap_${index}`}>
                      <h6>
                        {
                          (tank?.tank_id
                            ? tanks?.find((t) => t.id === Number(tank?.tank_id))
                            : lots?.find((l) => l.id === Number(tank.batch_id))
                          )?.name
                        }
                      </h6>
                      {(tank?.tank_id || tank?.batch_id) && (
                        <Input
                          name={`temperature.${
                            tank?.tank_id || tank?.batch_id
                          }`}
                          register={register}
                          type="number"
                          error={
                            errors[`temperature`]?.[
                              tank?.tank_id || tank?.batch_id
                            ]
                          }
                          label={getLabel("analysisExamTemperature")}
                          placeholder={
                            getLabel("analysisExamTemperaturePlaceholder") +
                            " (\u00b0C)"
                          }
                          required={getLabel("inputRequiredError")}
                          min={{
                            value: 15,
                            message: getLabel("errorMinValue", { value: 15 }),
                          }}
                          max={{
                            value: 35,
                            message: getLabel("errorMaxValue", { value: 35 }),
                          }}
                        />
                      )}
                      {/* <div className={`interval_value exam`}>
                <DatePicker
                  name={`collection_date.${tank?.batch_id || tank?.tank_id}`}
                  control={control}
                  label={getLabel("dateExamFrom")}
                  error={errors[`collection_date`]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.dateFormat}
                  isTime={true}
                />
              </div>
              <Input
                name={`analyst.${tank?.batch_id || tank?.tank_id}`}
                register={register}
                type="text"
                error={errors[`analyst`]?.[tank?.batch_id || tank?.tank_id]}
                label={getLabel("analysisAnalyst")}
                placeholder={getLabel("analysisAnalystPlaceholder")}
                required={getLabel("inputRequiredError")}
                minLength={{
                  value: 3,
                  message: getLabel("errorMinLength", { value: 3 }),
                }}
                maxLength={{
                  value: 30,
                  message: getLabel("errorMaxLength", { value: 30 }),
                }}
                regExpPattern={{
                  value: ALPHA_SPACE_APEX_DOT_REGEX,
                  message: getLabel("errorAlphaSpaceApex"),
                }}
              />
              <div className={`interval_value exam`}>
                <DatePicker
                  name={`start_date.${tank?.batch_id || tank?.tank_id}`}
                  control={control}
                  label={getLabel("dateExamFrom")}
                  error={errors["start_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.dateFormat}
                  isTime={true}
                />
                <DatePicker
                  name={`end_date.${tank?.batch_id || tank?.tank_id}`}
                  control={control}
                  label={getLabel("dateExamTo")}
                  error={errors["end_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.dateFormat}
                  isTime={true}
                />
              </div>
               */}
                    </div>
                  )}
                </>
              ))}
              {/* </> */}
              {/* )} */}
              {/* {step === totalStep && (
            <>
              <Input
                name="analyst"
                register={register}
                type="text"
                error={errors["analyst"]}
                label={getLabel("analysisAnalyst")}
                placeholder={getLabel("analysisAnalystPlaceholder")}
                required={getLabel("inputRequiredError")}
                minLength={{
                  value: 3,
                  message: getLabel("errorMinLength", { value: 3 }),
                }}
                maxLength={{
                  value: 30,
                  message: getLabel("errorMaxLength", { value: 30 }),
                }}
                regExpPattern={{
                  value: ALPHA_SPACE_APEX_DOT_REGEX,
                  message: getLabel("errorAlphaSpaceApex"),
                }}
              />
              <div className={`interval_value exam`}>
                <DatePicker
                  name="start_date"
                  control={control}
                  label={getLabel("dateExamFrom")}
                  error={errors["start_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.dateFormat}
                  isAnalysis={true}
                />
                <DatePicker
                  name="end_date"
                  control={control}
                  label={getLabel("dateExamTo")}
                  error={errors["end_date"]}
                  required={getLabel("inputRequiredError")}
                  dateFormat={configs.dateFormat}
                  isAnalysis={true}
                />
              </div>
            </>
          )} */}
            </div>
          )}
        </div>
      );
  };
  const renderCrendentials = () => {
    return (
      <>
        <h5 className="login_title">{getLabel("modalCredentials")}</h5>
        <div className="login_wrapper">
          <Input
            name="username"
            register={register}
            regExpPattern={{
              value: USER_REGEX,
              message: getLabel("inputPatternError"),
            }}
            type="text"
            label={getLabel("username")}
            placeholder={getLabel("usernamePlaceholder")}
            error={errors["username"]}
            required={getLabel("inputRequiredError")}
            isRequiredTooltip={false}
            // onChange={resetError}
          />
          <Input
            name="password"
            register={register}
            regExpPattern={{
              value: PWD_REGEX,
              message: getLabel("inputPatternError"),
            }}
            type="password"
            label={getLabel("password")}
            placeholder={getLabel("passwordPlaceholder")}
            error={errors["password"]}
            required={getLabel("inputRequiredError")}
            isRequiredTooltip={false}
            // onChange={resetError}
          />
        </div>
      </>
    );
  };

  return (
    <div>
      {renderByType()}
      {isCredential && renderCrendentials()}
    </div>
  );
};

const RecapTankIcon = ({ tank, wineColor }) => {
  return (
    <div className="init_status">
      <div className="tank_representation">
        <div
          className={"tank_tile_progress_" + wineColor}
          style={{
            height: `${
              (Number(tank?.quantity) / Number(tank?.capacity)) * 100
            }%`,
          }}
        ></div>
      </div>
      <div>
        <p className="tank_name">{tank?.name}</p>
        {/* <p className="tank_quantity">{tank?.quantity || 0} L</p> */}
        <p className="tank_quantity">{`${tank?.quantity || 0} L (${Math.round(
          (tank?.quantity * 100) / (Number(tank?.capacity) || 1)
        )}%)`}</p>
      </div>
    </div>
  );
};

const TankIcon2 = ({
  tank,
  isText = false,
  isDestemming = false,
  total,
  isReverse = false,
  isSrc = true,
  isRecap = false,
  isName = false,
  isBatchName = false,
}) => {
  const [getLabel] = useLabels();
  const error =
    getLabel("errorMaxCapacity", { value: tank?.availability }) || "";

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "white";
      case "ROSE":
        return "rose";
      default:
        return "red";
    }
  };

  return (
    <div className={`recap ${isReverse ? "reverse" : ""}`}>
      <div>
        {isText && (
          <>
            <h6
              className={`bold tank_name ${isRecap ? "unset-width" : ""}`}
            >{`${tank?.label || tank?.name}`}</h6>
            <p
              className={`bold tank_name fixed-width ${
                isRecap ? "unset-width" : ""
              }`}
            >{`${
              Number(tank?.quantity) ||
              0 + Number(tank?.toAdd) ||
              0 > 0 ||
              isSrc
                ? tank?.lot || tank?.batch_name || getLabel("tankState_EMPTY")
                : getLabel("tankState_EMPTY")
            }`}</p>
            {tank?.batch_type && (
              <p className="centered">
                {getLabel(`lotType_${tank?.batch_type}`)}
              </p>
            )}
          </>
        )}
        <div
          className={`tank_representation recap ${isName ? "icon_name" : ""} ${
            ["TONNEAU", "BARRIQUE"].includes(tank?.type) ? "barrel" : ""
          } ${tank?.type === "TONNEAU" ? "tonneau" : ""}`}
        >
          <div
            className={"tank_tile_progress_" + getWineColor(tank?.color)}
            style={{
              height: `${
                ((Number(tank?.quantity) + Number(tank?.toAdd || 0) || 0) / // TO DO: aggiunto + Number(tank?.toAdd || 0) per il CUT, ma non so se funziona per le altre op pure
                  (Number(tank?.capacity) || 1)) *
                100
              }%`,
            }}
          ></div>
        </div>
        {isRecap && !isSrc && isDestemming && <p>+{tank?.toAdd || 0} L</p>}
        {isName && <p className="tank_name">{tank?.name}</p>}
        {isBatchName && <p>{tank?.batch_name}</p>}
      </div>

      {isText && (
        <>
          {isRecap && (
            <>
              {!isDestemming && (
                <>
                  <div>
                    {`${
                      Number(tank?.quantity) + Number(tank?.toAdd || 0) || 0
                    }/${Number(tank?.capacity) || 1}L (${Math.round(
                      ((Number(tank?.quantity) + Number(tank?.toAdd || 0) ||
                        0) /
                        (Number(tank?.capacity) || 1)) *
                        100
                    )}%)`}
                  </div>
                  {isSrc && <h6>{`-${Number(tank?.toRemove) || 0}L`}</h6>}
                  {!isSrc && <h6>{`+${tank?.arrow_quantity || 0}L`}</h6>}
                </>
              )}
              {isDestemming && (
                <>
                  {!isSrc && (
                    <>
                      <h6>{`${
                        tank?.quantity + tank?.toAdd || 0
                      } L (${Math.round(
                        ((tank?.quantity + tank?.toAdd) * 100) /
                          (Number(tank?.capacity) || 1)
                      )}%)`}</h6>
                      {/* <p>{`${getLabel('destemmingQuantityEstimated')}`}</p> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {!isRecap && (
            <>
              {!isDestemming && (
                <>
                  {isSrc && (
                    <p className="tank_data">{`${tank?.quantity || 0}/${
                      Number(tank?.capacity) || 0
                    }L`}</p>
                  )}
                  {!isSrc && (
                    <p className="tank_data">{`${Number(tank?.quantity) || 0}/${
                      Number(tank?.capacity) || 1
                    }L (${Math.round(
                      ((Number(tank?.quantity) || 0) /
                        (Number(tank?.capacity) || 1)) *
                        100
                    )}%)`}</p>
                  )}
                </>
              )}
              {isDestemming && (
                <>
                  {!isSrc && (
                    <p className="centered">{`${Number(tank?.quantity) || 0}/${
                      Number(tank?.capacity) || 0
                    }L`}</p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {!isRecap && total > 0 && (
        <h6 className="centered">
          {isSrc ? "-" : "+"}
          {`${total}L`}
        </h6>
      )}
      {!isRecap && total > tank?.availability && (
        <div className={`tank_icon ${error ? "is_error" : ""}`}>
          <input style={{ visibility: "hidden" }} />
          <div className="error_message">
            <span>{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};

const TankIcon = ({ tank, isArrowQuantity = false, isReverse = false }) => {
  const [getLabel] = useLabels();

  return (
    <div className={`tank_icon ${isReverse ? "reverse" : ""}`}>
      <div className="tank_name">
        <h6 className="bold tank_name unset-width">{tank?.name}</h6>
      </div>
      <div className="init_wrapper">
        <div
          className={`init_status ${
            ["TONNEAU", "BARRIQUE"].includes(tank?.type) ? "barrel" : ""
          } ${tank?.type === "TONNEAU" ? "tonneau" : ""}`}
        >
          {tank?.batch_name && (
            <p className={`batch_name 'bold' : ''}`}>
              {Number(tank?.quantity) > 0
                ? tank?.batch_name || getLabel("tankState_EMPTY")
                : getLabel("tankState_EMPTY")}
            </p>
          )}
          <div
            className={`tank_representation  ${
              tank?.is_focused === true ? "focused" : ""
            } ${["TONNEAU", "BARRIQUE"].includes(tank?.type) ? "barrel" : ""} ${
              tank?.type === "TONNEAU" ? "tonneau" : ""
            }`}
          >
            <div
              className={`tank_tile_progress_${tank?.color?.toLowerCase()}`}
              style={{
                height: `${
                  (Number(tank?.quantity) / Number(tank?.capacity)) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="tank_info">
            {tank?.quantity || 0} / {tank?.capacity}L
            {true
              ? " (" +
                Math.round(
                  ((Number(tank?.quantity) || 0) * 100) / Number(tank?.capacity)
                ) +
                "%)"
              : ""}
          </div>

          {isArrowQuantity && (
            <h6>{`${tank?.remove_quantity ? "-" : "+"}${
              Number(tank?.add_quantity || tank?.remove_quantity) || 0
            }L`}</h6>
          )}
          {/* {isArrowQuantity && <div>
            {tank?.is_src && <h6>{`-${Number(tank?.toRemove) || 0}L`}</h6>}
            {!tank?.is_src && <h6>{`+${tank?.arrow_quantity || 0}L`}</h6>}
          </div>} */}
          {/* {true && <div>
            {" (" + Math.round(
              ((Number(tank?.old_quantity) || 0) * 100) /
                Number(tank?.capacity)
            ) +
            "%)"}
          </div>} */}
        </div>
      </div>
    </div>
  );
};

const BottleIcon = ({
  bottleLot,
  capLot,
  // isReverse = false,
  isRecap = false,
  isDetail = false,
  batch_name = null,
  quantity = null,
  drop = null,
  toAdd = null,
  color,
}) => {
  const [getLabel] = useLabels();

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "rgb(249, 213, 115)";
      case "ROSE":
        return "rgb(199, 79, 89)";
      case "RED":
        return "#64242e";
      default:
        return "rgb(50, 90, 50)";
    }
  };

  return (
    <div className={`bottle_recap  ${isRecap ? "recap" : ""}`}>
      <p className="bold">
        {batch_name || ""}
        {isDetail && Number(quantity) > 0 ? ` (${quantity} L)` : ""}
      </p>
      <div className="bottle_data">
        {/* Cap (Icon + Text on the same line) */}
        <div className="cap_icon">
          <div className="icon">
            <FontAwesomeIcon
              icon={"fa-bread-slice"}
              size={"sm"}
              style={{ color: "rgb(157, 126, 104)" }}
            />
          </div>
        </div>

        {/* Bottle */}
        <div className="bottle_icon">
          <div className="icon">
            <FontAwesomeIcon
              icon="fa-wine-bottle"
              style={{ color: getWineColor(color) }}
              size={"4x"}
            />
          </div>
          {!isDetail && <p className="bold">{bottleLot || ""}</p>}
        </div>
        {/* <div className="lots">
          {lotNames?.length > 0 && (
            <p className="bold tank_name fixed-width">
              {getLabel("lots")}:{" "}
              <p className="normal">{lotNames?.toString()}</p>
            </p>
          )}
        </div> */}
      </div>
      {!isDetail && <p className="normal">{capLot || ""}</p>}
      {isDetail && (
        <div>
          <p>
            {getLabel("bottleLot")}:{" "}
            <span className="bold">{capLot || ""}</span>
          </p>
          <p>
            {getLabel("capLot")}:{" "}
            <span className="bold">{bottleLot || ""}</span>
          </p>
          {drop > 0 && (
            <p className="drop">
              {getLabel("operationDrop")}:{" "}
              <span className="bold">{drop} L</span>
            </p>
          )}
        </div>
      )}
      {isRecap && toAdd && <h6>{`+${toAdd || 0}L`}</h6>}
    </div>
  );
};

const BatchIcon = ({
  lot,
  isText = false,
  isReverse = false,
  isSrc = true,
  isRecap = false,
  isName = false,
  size,
}) => {
  const getGrapeColor = () => {
    switch (lot?.color) {
      case "WHITE":
        return json_white_grape;
      case "ROSE":
        return json_rose_grape;
      default:
        return json_red_grape;
    }
  };

  return (
    <div className={`recap ${isReverse ? "reverse" : ""}`}>
      {!isName && <h6 className="bold centered">{lot?.label}</h6>}
      <Player
        autoplay={false}
        controls={false}
        hover={false}
        src={getGrapeColor()}
        style={{
          width: isName ? "64px" : size ? size : "84px",
          height: isName ? "64px" : size ? size : "84px",
          marginTop: isName ? "12px" : "",
        }}
      />
      {isName && <p className="batch_name">{lot?.name}</p>}

      {isText && (
        <>
          <div>
            <span>{`${
              (Number(lot?.quantity) || 0) + (Number(lot?.toRemove) || 0) || 0
            }/${Number(lot?.initial_quantity) || 1} Kg (${Math.round(
              ((Number(lot?.quantity) + Number(lot?.toRemove || 0) || 0) /
                (Number(lot?.initial_quantity) || 1)) *
                100
            )}%)`}</span>
            {isRecap && (
              <h6 className="bold">{`-${Number(lot?.toRemove) || 0} Kg`}</h6>
            )}
          </div>
          <div className="arrow">
            {`${Number(lot?.arrow_quantity)} L`}
            <Player
              autoplay={false}
              controls={false}
              hover={false}
              src={json_arrow_black}
              style={{
                width: "24px",
                height: "24px",
                transform: "rotate(180deg)",
              }}
            />
          </div>
          <span>{`${
            (Number(lot?.quantity) || 0) + (Number(lot?.toRemove) || 0) || 0
          }/${Number(lot?.initial_quantity) || 1} Kg (${Math.round(
            ((Number(lot?.quantity) + Number(lot?.toRemove || 0) || 0) /
              (Number(lot?.initial_quantity) || 1)) *
              100
          )}%)`}</span>
        </>
      )}
    </div>
  );
};

const BatchDetail = ({
  tank: lot,
  color,
  operation,
  isName = true,
  isDrop = true,
  isArrow = false,
}) => {
  const [getLabel] = useLabels();

  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "white";
      case "ROSE":
        return "rose";
      default:
        return "red";
    }
  };

  return (
    <>
      {isName && (
        <div className="tank_name">
          {lot?.name}
          {/* {operation?.batch_name_out && <p className="batch_name">{operation?.batch_name_out}</p>} */}
          {/* {tank?.new_batch_name && (
            <p className="batch_name">{tank?.new_batch_name}</p>
          )} */}
        </div>
      )}
      <div className="init_status">
        {lot?.old_batch_name && (
          <p className="batch_name">{lot?.old_batch_name}</p>
        )}
        <div className="tank_representation">
          <div
            className={
              "tank_tile_progress_" + getWineColor(color || lot?.color)
            }
            style={{
              height: `${
                (Number(lot?.old_quantity) / Number(lot?.capacity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {lot?.old_quantity || 0} / {lot?.capacity}L (
          {Math.round(
            ((Number(lot?.old_quantity) || 0) * 100) / Number(lot?.capacity)
          )}
          %)
        </div>
      </div>
      <div className="arrow">
        {`${Number(lot?.arrow_quantity) > 0 ? "+" : "-"}${Number(
          lot?.arrow_quantity
        )} L`}
        <Player
          autoplay={false}
          controls={false}
          hover={false}
          src={json_arrow_black}
          style={{ width: "24px", height: "24px", transform: "rotate(180deg)" }}
        />
      </div>
      <div className="end_status">
        {lot?.batch_name && (
          <p className="batch_name bold">{lot?.batch_name}</p>
        )}
        <div className="tank_representation">
          <div
            className={
              "tank_tile_progress_" + getWineColor(color || lot?.color)
            }
            style={{
              height: `${
                (Number(lot?.quantity) / Number(lot?.capacity)) * 100
              }%`,
            }}
          ></div>
        </div>
        <div>
          {Number(lot?.quantity) || 0} / {lot?.capacity}
          {"L"} (
          {Math.round(
            ((Number(lot?.quantity) || 0) * 100) / Number(lot?.capacity)
          )}
          %)
        </div>
      </div>
      {isDrop && operation?.completion_date !== null && (
        <div className="operation_info_container_row">
          <p className="tank_name">
            {getLabel("operationDrop")}
            <span className="batch_name">
              {" "}
              {`${lot?.drop || 0}`}{" "}
              {operation?.type === "DESTEMMING" ? "Kg" : "L"}
            </span>
          </p>
        </div>
      )}
    </>
  );
};

const ExternalLotIcon = ({ lot }) => {
  const getWineColor = (color) => {
    switch (color) {
      case "WHITE":
        return "white";
      case "ROSE":
        return "rose";
      default:
        return "red";
    }
  };

  return (
    <div className={"recap"}>
      <div>
        <h6 className="bold">{`${lot?.name}`}</h6>
        <p className="bold">{`${lot?.quantity || 0} L`}</p>
        <div
          className={`tank_representation recap ${"isName" ? "icon_name" : ""}`}
        >
          <div
            className={"tank_tile_progress_" + getWineColor(lot?.color)}
            style={{
              height: `${
                (((Number(lot?.quantity) || 0) + Number(lot?.toAdd || 0)) /
                  (Number(lot?.capacity) || 1)) *
                100
              }%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const NotificationWithLink = ({ content }) => {
  const navigate = useNavigate();

  const notify = () => {
    toast(content?.message, {
      icon: true,
      ...content?.props,
      autoClose: false,
    });
  };

  return (
    // <CustomToastWithLink navigateTo={content?.link || '/lots'} message={content?.message} />
    <div onClick={() => navigate(content?.link || "lots")}>
      New Message{notify()}
    </div>
  );
};

const InfoCard = ({
  label,
  number,
  icon = null,
  img = null,
  isOnlyIcon = false,
  isGrey = false,
  size = "xs",
  content = <></>,
  img_name = "",
}) => {
  const html = renderToStaticMarkup(content);

  return (
    <div className="info_card_container">
      <Tooltip variant={"info"} html={html} place="bottom" events={["hover"]}>
        <div className="info_card">
          <div className="info_card_label">
            {isOnlyIcon ? "" : label || ""}
            {icon && <FontAwesomeIcon icon={icon} size={size} />}
          </div>
          <div className="info_card_number">
            {img && (
              <img
                className={`icon ${isGrey ? "grey" : ""}`}
                src={img}
                alt={img_name}
              />
            )}
            {number || "0"}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

const Carousel = ({
  children,
  direction = "horizontal",
  autoscroll = false,
  speed = 3000,
  redirect = null,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const totalWidth = carouselRef.current.scrollWidth;
      const containerWidth = carouselRef.current.clientWidth;

      setShowButtons(totalWidth > containerWidth);
    }
  }, [children]);

  const handleCarouselClick = (event) => {
    if (event.target !== carouselRef.current) {
      if (redirect) redirect();
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.addEventListener("click", handleCarouselClick);
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener("click", handleCarouselClick);
      }
    };
  }, [carouselRef]);

  const nextSlide = (event) => {
    if (event) event.stopPropagation();
    const totalWidth = carouselRef.current.scrollWidth;
    const containerWidth = carouselRef.current.clientWidth;
    const currentIndexWidth = currentIndex * itemWidth;

    if (currentIndexWidth + containerWidth < totalWidth) {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, children.length - 1)
      );
    }
  };

  const prevSlide = (event) => {
    if (event) event.stopPropagation();
    const currentIndexWidth = currentIndex * itemWidth;
    if (currentIndexWidth > 0) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const itemWidth = useMemo(() => {
    if (direction === "horizontal") {
      return carouselRef.current
        ? carouselRef.current.clientWidth / children.length
        : 0;
    } else {
      return carouselRef.current
        ? carouselRef.current.clientHeight / children.length
        : 0;
    }
  }, [children, direction, carouselRef]);

  useEffect(() => {
    if (autoscroll) {
      const intervalId = setInterval(() => {
        if (direction === "horizontal") {
          nextSlide();
        } else {
          nextSlide();
        }
      }, speed);

      return () => clearInterval(intervalId);
    }
  }, [autoscroll, speed, nextSlide]);

  return (
    <div className="carousel">
      {showButtons && (
        <button onClick={prevSlide} className="carousel-button left">
          ❮
        </button>
      )}
      <motion.div
        ref={carouselRef}
        className={`carousel-container ${direction}`}
        animate={{
          x: direction === "horizontal" ? -currentIndex * 100 : 0,
          y: direction === "vertical" ? -currentIndex * 100 : 0,
        }}
        transition={
          autoscroll
            ? {
                duration: 5,
                repeat: Infinity,
                repeatType: "mirror",
                delay: 1, // aggiungi un ritardo di 2 secondi tra le ripetizioni
                ease: "linear", // utilizza la funzione di easing lineare per renderizzare l'animazione più fluida
              }
            : {}
        }
      >
        {Children.map(children, (child) => (
          <div
            className="carousel-item"
            style={{
              minWidth: direction === "horizontal" ? `${itemWidth}px` : "auto",
              minHeight: direction === "vertical" ? `${itemWidth}px` : "auto",
              flexBasis: `${itemWidth}px`, // Add this to set the flex basis
            }}
          >
            {child}
          </div>
        ))}
      </motion.div>

      {showButtons && (
        <button onClick={nextSlide} className="carousel-button right">
          ❯
        </button>
      )}
    </div>
  );
};

export {
  Button,
  CTA,
  Tooltip,
  Card,
  Modal,
  TankColumn,
  CellarmanColumn,
  Dropdown,
  BatchColumn,
  BottlingColumn,
  Toast,
  ConfirmationModal,
  OperationRecap,
  TankIcon,
  TankIcon2,
  RecapTankIcon,
  BatchIcon,
  BottleIcon,
  InfoTooltip,
  InfoCard,
  WineMakingProtocolRowModal,
  NotificationWithLink,
  Carousel,
};
