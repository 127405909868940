import { useFieldArray, useForm, useWatch } from "react-hook-form";
import useLabels from "../../hooks/useLabels";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperations,
  newOperation,
  updateOperation,
} from "../../services/utils";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { ConfirmationModal, CTA, OperationRecap, TankIcon } from "../Generic";
import { ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX, INT_REGEX } from "../../constants/regex";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { selectAllLotNames, selectOperations } from "../../context/selectors";
import { addMinutes, addMonths } from "date-fns";
import { DestTankDetail } from "../OperationsDetail/Details";
import { NavLink } from "react-router-dom";

const CutForm = ({ operation, activeWinery, tanks, users, configs, lots, type, isMobile }) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const operations = useSelector(selectOperations);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const lotNames = useSelector(selectAllLotNames);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      // dest_tanks: operation?.dest_tanks || [
      //   { tankData: { value: null, label: null } },
      // ],
      priority: operation?.priority || "",
      protocolFrequency: "",
      expire_date: operation?.expire_date || null,
      type: "CUT",
      src_tanks: operation?.src_tanks || [
        { tankData: { value: null, label: null }, quantity: 0 },
      ],
      cellarman_ids: operation?.cellarman_ids || "",
      new_batch_name: operation?.new_batch_name || "",
    },
    mode: "onTouched",
  });

  useEffect(() => {
    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      const srcs = [];

      operation?.src_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === s.tank_id);
        
        srcs.push({
          quantity: s?.arrow_quantity,
          tankData: {
            value: s?.tank_id,
            label: tank?.name,
            availability: Number(tank?.quantity),
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            color: tank?.color,
            lot: tank?.batch_name ||
              getLabel("tankState_EMPTY"),  
            name: tank?.name + ` (${tank?.batch_name})`,
          },
        });
      });
      srcTankFieldArray.replace(srcs);
      // setValue("src_tanks", srcs);

      let dest = {};
      operation?.dest_tanks?.map((d) => {
        console.log('d', d)
        const tank = tanks?.find((t) => t.id === d.tank_id);
        dest = {
            value: d?.tank_id,
            label: tank?.name,
            availability: Number(tank?.capacity) - Number(tank?.quantity),
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            color: tank?.color,
            lot:
              lots.find((lot) => lot?.id === tank?.batch_id)?.name ||
              getLabel("tankState_EMPTY"),
        }
        setValue('dest_tanks.batch_name_selector', {
          value: d?.is_new,
          label: d?.is_new ? getLabel("lotNewName") : d?.new_batch_name,
        });
        setValue('dest_tanks.new_batch_name', d?.is_new ? d?.batch_name : "");
        setValue('dest_tanks.new_batch_type', d?.new_batch_type);
      });
      setValue('dest_tanks.tankData', dest)

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      if (operation?.expire_dates && operation?.expire_dates?.length > 0) {
        setValue('protocolFrequency', {
          label: getLabel("protocolRepeat"),
          value: "repeat",
        });
      } else {
        setValue('protocolFrequency', {
          label: getLabel("protocolSpot"),
          value: "spot",
        });
        setValue('expire_date', operation?.expire_date);
      }

      setValue('note', operation?.note);

      // if (operation?.batch_name_out) {
      //   setValue('batch_name_selector', {
      //     value: true,
      //     label: getLabel('lotNewName')
      //   });
      //   setValue('new_batch_name', operation?.batch_name_out);  
      // }
    }
  }, [operations, operation, tanks]);

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  const srcTankFieldArray = useFieldArray({
    control,
    name: "src_tanks",
  });

  const { src_tanks } = useWatch({ control });

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const getTotalQuantity = () => {
    return src_tanks?.reduce((acc, curr) => {
      return acc + (Number(curr?.quantity) || 0);
    }, 0) || 0;
  };

  const computeLotNames = () => {

    const names = new Set();
    src_tanks?.reduce((_, item) => names.add(item?.tankData?.lot), null);
    
    // shows only 6 months old must batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    // lots?.filter((l) => (l?.state === 'MUST' || l?.state === 'WINE') && (new Date(l?.insertion_date) >= addMonths(new Date(), -6)))?.reduce((_, l) => names.add(l?.name), null);
    lotNames?.filter((l) => l?.state === 'MUST' || l?.state === 'WINE')?.reduce((_, l) => names.add(l?.name), null);

    src_tanks?.filter((tank) => tank?.tankData?.quantity > 0)?.reduce((_, item) => names.add(item?.tankData?.lot), null);
    
    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });
    
    return nameObjects?.sort((a, b) => (a?.label > b?.label) ? 1 : ((b?.label > a?.label) ? -1 : 0));
  };

  const customIdMissingSrcs = "custom-id-missing-srcs";
  const customIdUnavailableLotName = "custom-id-unavailable-lot-name";
  const customIdInvalidLotName = "custom-id-invalid-lot-name";
  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  const openFormModal = () => {
    trigger();
    if (src_tanks?.filter((t) => t?.tankData?.value)?.length === 0) {
      toast(
        getLabel("toast_destemmingMissingSrcs"),
        {
          toastId: customIdMissingSrcs,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingSrcs);
    }

    if (getValues('dest_tanks.batch_name_selector')?.value === '' || (!getValues('dest_tanks.batch_name_selector')?.value && getValues('dest_tank.batch_name_selector')?.label?.length === 0) ||
     (getValues('dest_tank.batch_name_selector')?.value === true && getValues('dest_tank.new_batch_name')?.length === 0)) {
      toast(
        getLabel("toast_cutMissingLotSelectors"),
        {
          toastId: customIdMissingSrcs,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    } else {
      toast.dismiss(customIdMissingSrcs);
    }
    
    // const filteredTanks = dest_tanks?.filter((d) => d?.tankData?.value);
    // if (filteredTanks?.length === 1)
    //   setValue(
    //     "dest_tanks.0.quantity",
    //     getTotalQuantity()
    //   );

    const cutLiters = getTotalQuantity();
    
    const totalKgAvailable = Number(getValues("dest_tanks.tankData")?.availability) || 0;
    if (
      cutLiters <= totalKgAvailable
    ) {
      toast.dismiss(customIdInvalidQuantities);
      setIsOpen(true);
    } else {
      toast(
        getLabel("toast_cutInvalidQuantities", {
          sum: cutLiters || '0',
          total: totalKgAvailable,
        }),
        {
          toastId: customIdInvalidQuantities,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    }
  };

  const sendData = async () => {
    const data = getValues();
    // console.log(data);
    
    const dests = [];
    
    // TO DO: finisci qui
    const totalCutLiters = src_tanks?.reduce((acc, curr) => {
      return acc + (Number(curr?.quantity) || 0);
    }, 0);
    const dest = data?.dest_tanks || null;
    if (dest === null) return;

    if(dest?.tankData?.value && totalCutLiters > 0 && 
      ((dest?.batch_name_selector?.value === true && dest?.new_batch_name) ||
        (dest?.batch_name_selector?.value === false && dest?.batch_name_selector?.label))) {
        // const type2 = dest?.batch_name_selector?.value === true && dest?.new_batch_name?.length > 0 && lots?.find((l) => (l?.name === dest?.new_batch_name && dest?.batch_name_selector?.value === true) || 
        // (l?.name === dest?.batch_name_selector?.label && dest?.batch_name_selector?.value === false)) === undefined ? 'NEW' :
        // lots?.find((l) => (l?.name === dest?.new_batch_name && dest?.batch_name_selector?.value === true) || 
        // (l?.name === dest?.batch_name_selector?.label && dest?.batch_name_selector?.value === false)) === undefined !== undefined ? 
        // lots?.find((l) => (l?.name === dest?.new_batch_name)) === undefined && !dest?.batch_name_selector?.value && dest?.batch_name === undefined ? 'NEW' // lot not existing yet but created in another operation TO DO check if is ok
        // : 'OLD' : null;

        const new_batch_name = dest?.new_batch_name;
        const batch_name_label = dest?.batch_name_selector?.label;
        const batch_name_value = dest?.batch_name_selector?.value;
        const type =
          (batch_name_value === true &&
          new_batch_name?.length > 0 &&
          lots?.find(
            (l) =>
              (l?.name === new_batch_name &&
                batch_name_value === true) ||
              (l?.name === batch_name_label &&
                batch_name_value === false)
          ) === undefined) ||
            (batch_name_value === true &&                         // modify an existing operation that created PLANNED batches
              new_batch_name?.length > 0 &&
              (lots?.find(
                (l) => l?.name === new_batch_name && l?.status === 'PLANNED'
              ) !== undefined))
            ? "NEW"
            : "OLD";
        // lots?.find((l) => (l?.name === dest?.new_batch_name && dest?.batch_name_selector?.value === true) || 
        //   (l?.name === dest?.batch_name_selector?.label && dest?.batch_name_selector?.value === false)) !== undefined ? 'OLD' : null;
        if (type === null) {
          toast.error(
            getLabel("toast_invalidType"),
            {
              toastId: customIdInvalidLotName,
              position: toast.POSITION.BOTTOM_RIGHT,
              exclude: true,
              autoClose: 5000,
            }
          );
          return;
        }
   
        const destTank = {
          tank_id: dest?.tankData?.value,
          quantity: totalCutLiters,
          is_new: (dest?.batch_name_selector?.value === true && dest?.new_batch_name?.length > 0),
          new_batch_name: (dest?.batch_name_selector?.value === true ? dest?.new_batch_name : dest?.batch_name_selector?.label)?.trim(),
          new_batch_type: type,
          old_batch_id:
            tanks?.find((t) => t.id === dest?.tankData?.value)?.batch_id ||
            "",
          old_quantity:
            tanks?.find((t) => t.id === dest?.tankData?.value)?.quantity ||
            0,
          new_quantity:
            (Number(
              tanks?.find((t) => t.id === dest?.tankData?.value)?.quantity ||
                0
            ) || 0) + totalCutLiters,
        };
        dests.push(destTank);
      };
    
    if (dests?.length === 0) {    
      toast.error(
        getLabel("toast_missingDestTanks"),
        {
          toastId: customIdInvalidLotName,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
          autoClose: 5000,
        }
      );
      return;
    }
    
    const tmpDate = new Date(data?.expire_date)?.setHours(23, 59, 59);

    const requestPayload = {
      src_tanks: data?.src_tanks?.map((srcT) => {
        return { tank_id: srcT?.tankData?.value, quantity: srcT?.quantity };
      }),
      dest_tanks: dests,
      priority: data.priority?.value,
      // expire_date: addMinutes(
      //   new Date(data?.expire_date)?.setHours(23, 59, 59),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString(),
      is_exact_date: isExactDate,
      expire_date: new Date(tmpDate)?.toISOString(),
      type: data?.type,
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      new_batch_name: (dest?.batch_name_selector?.value === true ? dest?.new_batch_name : dest?.batch_name_selector?.label)?.trim(),
      note: data?.note,
    };
    console.log(requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    let response = null;
    if (id) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log("response", response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/CUT/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const getError = (idx, data, key) => {
    if (idx === null) return data?.[key] || null;

    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const customId = "custom-id-different-color";
  const checkColors = () => {
    const colors = new Set();
    src_tanks?.reduce(
      (_, item) =>
        item?.tankData?.color ? colors.add(item?.tankData?.color) : null,
      null
    );
    if (getValues("dest_tanks.tankData")?.color)
      colors.add(getValues("dest_tanks.tankData")?.color);

    if (colors?.size > 1)
      toast(getLabel("toast_WarningDiffentWineColor"), {
        toastId: customId,
        type: toast.TYPE.WARNING,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      });
    else toast.dismiss(customId);
  };

  const getSrcTankData = (idx) => {
    const t = src_tanks?.[idx]?.tankData;
    const quantity = src_tanks?.[idx]?.quantity > src_tanks?.[idx]?.tankData?.quantity ? 0 : src_tanks?.[idx]?.quantity;
    const tank = { ...t, remove_quantity: quantity, name: t?.label, old_quantity: t?.quantity, old_batch_name: t?.lot, batch_name: t?.lot, quantity: t?.quantity - quantity, arrow_quantity: -quantity };
    
    return tank;
  }

  const getDestTankData = () => {
    const dest = getValues("dest_tanks");
    const t = dest?.tankData;
    const totalCutQuantity = src_tanks?.reduce((acc, curr) => {
          return acc + (Number(curr?.quantity) || 0);
        }, 0);
    const quantity = totalCutQuantity > t?.availability ? 0 : totalCutQuantity;   // TO DO: recheck this with real data
    const tank = { ...t, add_quantity: totalCutQuantity, color: t?.color || src_tanks?.[0]?.tankData?.color, name: t?.label, old_quantity: t?.quantity, old_batch_name: t?.quantity > 0 ? t?.lot : null, batch_name: dest?.batch_name_selector?.value === true ? dest?.new_batch_name : dest?.batch_name_selector?.label, quantity: t?.quantity + quantity, arrow_quantity: quantity };

    return tank;
  }


  return (
    <div className="operation_form_wrapper">
      {operations && <form
        onSubmit={handleSubmit(openFormModal)}
        autoComplete="off"
        noValidate
      >
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("fromTanks")}</h6>
          {srcTankFieldArray?.fields?.map?.((field, idx) => {
            return (
              <div key={field.id} className="operation_form_wrapper_row">
                <Select
                  control={control}
                  label={getLabel("operationSrcTankName")}
                  placeholder={getLabel("operationSrcTanksPlaceholder")}
                  required={getLabel("inputRequiredError")}
                  name={`src_tanks.${idx}.tankData`}
                  error={errors}
                  //   error={errors[`src_tanks.${idx}.tankData`]}
                  options={tanks
                    ?.filter(
                      (tank) =>
                        tank?.quantity > 0 &&
                        !srcTankFieldArray?.fields?.some?.(
                          (field) =>
                            field.tankData.value == tank.id ||
                            tank.id == getValues("dest_tanks.tankData")?.value
                        )
                    )
                    ?.map?.((tank) => {
                      return {
                        value: tank?.id,
                        label: tank?.name,
                        name: tank?.name + ` (${lots?.find((lot) => lot?.id === tank?.batch_id)
                          ?.name || getLabel("tankState_EMPTY")})`,
                        lot: lots.find((lot) => lot?.id == tank?.batch_id)
                          ?.name,
                        availability: Number(tank?.quantity),
                        capacity: Number(tank?.capacity),
                        quantity: Number(tank?.quantity),
                        color: tank?.color,
                      };
                    })}
                  onTableChange={checkColors(idx)}
                  tooltip={(idx === 0) ? getLabel("cutTankInTooltip") : ''}
                />
                {getValues(`src_tanks.${idx}.tankData`)?.value && (
                  // <TankIcon
                  //   tank={getValues(`src_tanks.${idx}.tankData`)}
                  //   isText={true}
                  //   total={null}
                  // />
                  <DestTankDetail
                    tank={getSrcTankData(idx)}
                    isDrop={false}
                    isName={false}
                    isFirst={true}
                    isMobile={isMobile}
                  ></DestTankDetail>
                )}
                {getValues(`src_tanks.${idx}.tankData`)?.value && (
                  <Input
                    name={`src_tanks.${idx}.quantity`}
                    register={register}
                    type="number"
                    label={getLabel(`operationSrcTankQuantity`)}
                    placeholder={getLabel(
                      "operationSrcTankQuantityPlaceholder"
                    )}
                    error={getError(idx, errors?.src_tanks, 'quantity')}
                    required={getLabel("inputRequiredError")}
                    min={{
                      value: 1,
                      message: getLabel("errorMinValue", { value: 1 }),
                    }}
                    max={{
                      value: src_tanks?.[idx]?.tankData?.availability ?? null,
                      message: getLabel("errorMaxValue", {
                        value: src_tanks?.[idx]?.tankData?.availability ?? null,
                      }),
                    }}
                    regExpPattern={{
                      value: INT_REGEX,
                      message: getLabel("errorInt"),
                    }}
                    tooltip={(idx === 0) ? getLabel("cutTankInQuantityTooltip") : ''}
                  />
                )}
                {srcTankFieldArray?.fields?.length > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      srcTankFieldArray?.remove(idx);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            );
          })}
          <button
            onClick={(e) => {
              e.preventDefault();
              srcTankFieldArray?.append({
                tankData: {},
                quantity: 0,
              });
            }}
          >
            +
          </button>
        </div>
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("toTank")}</h6>
          <div className="operation_form_wrapper_row">
            <Select
              label={getLabel("operationDestTankName")}
              placeholder={getLabel("operationDestTanksPlaceholder")}
              error={errors}
              required={getLabel("inputRequiredError")}
              name={`dest_tanks.tankData`}
              control={control}
              options={tanks
                ?.filter(
                  (tank) =>
                    !src_tanks?.some?.(
                      (field) => field.tankData.value == tank.id
                    )
                )
                ?.map?.((tank) => {
                  return {
                    value: tank?.id,
                    label: tank?.name,
                    name: tank?.name + ` (${lots?.find((lot) => lot?.id === tank?.batch_id)
                      ?.name || getLabel("tankState_EMPTY")})`,
                    availability: Number(tank?.capacity) - Number(tank?.quantity),
                    capacity: Number(tank?.capacity),
                    quantity: Number(tank?.quantity),
                    color: tank?.color,
                    lot:
                      lots.find((lot) => lot?.id == tank?.batch_id)?.name ||
                      getLabel("tankState_EMPTY"),
                  };
                })}
                tooltip={getLabel("cutTankOutTooltip")}
            />
            {getValues("dest_tanks.tankData") && (
              // <TankIcon
              //   tank={getValues("dest_tanks.tankData")}
              //   isText={true}
              //   isRecap={false}
              //   isSrc={false}
              //   total={src_tanks?.reduce((acc, curr) => {
              //     return acc + (Number(curr?.quantity) || 0);
              //   }, 0)}
              //   noDestError={errors?.dest_tanks?.tankData}
              // />
              <DestTankDetail
                tank={getDestTankData()}
                isDrop={false}
                isName={false}
                isFirst={true}
                isMobile={isMobile}
              ></DestTankDetail>
            )}
            {getValues(`dest_tanks.tankData`)?.value && (
                  <div className="batch_name">
                    <Select
                      control={control}
                      defaultValue={{
                        value: "",
                        label: "",
                      }}
                      name={`dest_tanks.batch_name_selector`}
                      label={getLabel(
                        "operationNewLotName"
                      )}
                      placeholder={getLabel(
                        "operationNewLotNamePlaceholder"
                      )}
                      error={errors}
                      required={getLabel("inputRequiredError")}
                      options={[
                        {
                          value: true,
                          label: getLabel("lotNewName"),
                          className: 'bold',
                        },
                        ...computeLotNames(),
                      ]}
                      tooltip={getLabel('destemmingTankDestBatchNameSelectorTooltip')}
                    />
                    {getValues(`dest_tanks.batch_name_selector`)?.value && (
                      <Input
                        name={`dest_tanks.new_batch_name`}
                        error={getError(null, errors?.dest_tanks, 'new_batch_name')}
                        // name="new_batch_name"
                        register={register}
                        type="text"
                        label={getLabel("operationNewBatchName")}
                        placeholder={getLabel(
                          "operationNewBatchNamePlaceholder"
                        )}
                        // error={errors["new_batch_name"]}
                        required={getLabel("inputRequiredError")}
                        isReverse={true}
                        minLength={{
                          value: 3,
                          message: getLabel("errorMinLength", { value: 3 }),
                        }}
                        maxLength={{
                          value: 24,
                          message: getLabel("errorMaxLength", {
                            value: 24,
                          }),
                        }}
                        regExpPattern={{
                          value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                          message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        validate={(value) => {
                          if (
                            getValues(`dest_tanks.batch_name_selector`)?.value &&
                            lots?.map((l) => l?.status !== 'PLANNED' && l?.name?.toLowerCase?.())?.includes(value?.toLowerCase?.()?.trim())
                          ) {
                            // toast.error(
                            //   getLabel("toast_lotNameAlreadyPresent"),
                            //   {
                            //     toastId: customIdInvalidLotName,
                            //     position: toast.POSITION.BOTTOM_RIGHT,
                            //     exclude: true,
                            //     autoClose: 5000,
                            //   }
                            // );
                            // return false;
                            return getLabel("errorNewBatchNameAlreadyTaken",
                                {
                                  name: value,
                                }
                              );
                          }
                          // else {
                            // toast.dismiss(customIdInvalidLotName);
                            // return true;
                          // }
                        }}
                      />
                    )}
                  </div>
                )}
          </div>
        </div>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
            name={`cellarman_ids`}
            control={control}
            label={getLabel("operationUser")}
            placeholder={getLabel("operationUserPlaceholder")}
            error={errors["cellarman_ids"]}
            required={getLabel("inputRequiredError")}
            options={users?.map?.((user) => {
              return {
                value: user?.id,
                label: user?.username,
              };
            })}
            tooltip={getLabel("destemmingCellarmenTooltip")}
          />
          <Select
            name={"priority"}
            control={control}
            label={getLabel("operationPriority")}
            placeholder={getLabel("operationPriorityPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lotQuality_HIGH"),
                value: 2,
              },
              {
                label: getLabel("lotQuality_MEDIUM"),
                value: 1,
              },
              {
                label: getLabel("lotQuality_LOW"),
                value: 0,
              },
            ]}
            // defaultValue={{
            //     value: "",
            //     label: "",
            //   }}
          />
          {/* <Select
            name={"protocolFrequency"}
            control={control}
            label={getLabel("protocolFrequency")}
            placeholder={getLabel("protocolFrequencyPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("protocolSpot"),
                value: "spot",
              },
              {
                label: getLabel("protocolRepeat"),
                value: "repeat",
              },
            ]}
            // defaultValue={[
            //   {
            //     label: getLabel("protocolSpot"),
            //     value: "spot",
            //   },
            // ]}
          /> */}
          <DatePicker
            name="expire_date"
            control={control}
            label={getLabel("operationExpireDateForm")}
            error={errors["expire_date"]}
            required={getLabel("inputRequiredError")}
            dateFormat={configs.shortDateFormat}
            placeholder={getLabel("expiringDatePlaceholder")}
          />
          {getValues("expire_date") && <Input
            name={'expire_date_exact'}
            register={register}
            label={getLabel("isExactDate")}
            error={errors["expire_date_exact"]}
            // isLabel={false}
            type={"checkbox"}
            checked={isExactDate}
            onChange={() => setIsExactDate(prev => !prev)}
            customClassName={'confirm'}
            tooltip={getLabel("destemmingExactDate")}
          />}
        </div>
        <div className="client_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>}
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel("modalNewCutDescription", {
          name: operation?.id || "",
        })}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
      >
        <OperationRecap
          type={"CUT"}
          control={control}
          register={register}
          getValues={getValues}
          errors={errors}
          lots={lots}
          allLotNames={lots?.map((l) => l.name)}
          src_tanks={src_tanks?.map((s, i) => getSrcTankData(i))}
          dest_tanks={[getDestTankData()]}
        />
      </ConfirmationModal>
    </div>
  );
};

export default CutForm;
