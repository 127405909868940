import "./styles/index.scss";

const ReportCard = ({ title, img, alt_img, children, isMobile }) => {
  // const [getLabel] = useLabels();

  return (
    <div className="report_card_wrapper">
      <div className="report_card">
        {img && <div className="report_card_img">
          <h6 className="title">{title}</h6>
          <img
            className={'img'}
            src={img}
            alt={alt_img || ''}
          />
          </div>}
        <div className="report_card_content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
