import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Slider,
  Submit,
  Switch,
  TextArea,
} from "../FormComponents";
import useLabels from "../../hooks/useLabels";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperations,
  newOperation,
  updateOperation,
} from "../../services/utils";
import { ConfirmationModal, CTA, OperationRecap, TankIcon } from "../Generic";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_REGEX,
} from "../../constants/regex";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { selectAllLotNames, selectOperations } from "../../context/selectors";
import { addMonths } from "date-fns";
import { DestTankDetail } from "../OperationsDetail/Details";
import { NavLink } from "react-router-dom";

const TransferForm = ({
  operation,
  lots,
  activeWinery,
  tanks,
  users,
  configs,
  type,
  isWineMakingApplication = false,
  isDuplicate = false,
  isMobile,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const [isPressed, setIsPressed] = useState([]);
  const wineMakingData =
    type === "DECANT"
      ? JSON.parse(localStorage.getItem("wineMakingData")) || {}
      : {};
  const operations = useSelector(selectOperations);
  const lotNames = useSelector(selectAllLotNames);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      dest_tanks: operation?.dest_tanks || [
        { tankData: { value: null, label: null }, quantity: 0 },
      ],
      priority: operation?.priority || "",
      expire_date: operation?.expire_date || null,
      type: { type },
      tank_id_in: operation?.tank_id_in || "",
      cellarman_ids: operation?.cellarman_ids || "",
      new_batch_name: operation?.new_batch_name || "",
    },
    mode: "onTouched",
  });

  const { dest_tanks } = useWatch({ control });

  // const {
  //   fields: pomacesFields,
  //   append: appendP,
  //   prepend: prependP,
  //   remove: removeP,
  //   swap: swapP,
  //   move: moveP,
  //   insert: insertP,
  //   replace: replaceP,
  // } = useFieldArray({
  //   control,
  //   name: "pomaces",
  // });

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "dest_tanks",
    });

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  useEffect(() => {
    // localStorage.removeItem('tanks');
    // console.log('tanks', wineMakingData)
    if (!id && wineMakingData?.dest_tanks?.length > 0) {
      const tank =
        tanks?.find((t) => t.id === wineMakingData?.dest_tanks?.[0]?.tank_id) ||
        null;
      setValue("tank_id_in", {
        value: tank?.id,
        label: tank?.name,
        color: tank?.color,
        quantity: tank?.quantity,
        capacity: tank?.capacity,
        color: tank?.color,
        lot: tank?.batch_name,
      });

      setValue("priority", {
        label: getLabel(`operationPriority${wineMakingData?.priority}`),
        value: wineMakingData?.priority,
      });

      const cellarmans = [];
      wineMakingData?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });
      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", wineMakingData?.expire_date);

      // if (wineMakingData?.expire_date || cellarmans?.length === 0 || tank === null)
      // history.pushState();
      return;
    } else if (!id && wineMakingData?.dest_tanks?.length === 0) {
      localStorage.removeItem("wineMakingData");
      // navigate('/programming/WINEMAKING/new');
      return;
    }

    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      const tank = tanks?.find(
        (t) => t.id === operation?.src_tanks[0]?.tank_id
      );
      setValue("tank_id_in", {
        value: tank?.id,
        label: tank?.name,
        color: tank?.color,
        quantity: tank?.quantity,
        capacity: tank?.capacity,
        color: tank?.color,
        lot: tank?.batch_name,
      });

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      const tmpIsPressed = [];
      const dests = [];

      operation?.dest_tanks?.map((s) => {
        const tank = tanks?.find((t) => t.id === s.tank_id);
        dests.push({
          quantity: s?.arrow_quantity,
          tankData: {
            value: tank?.id,
            label: tank?.name,
            capacity: Number(tank?.capacity),
            quantity: Number(tank?.quantity),
            availability: Number(tank?.capacity) - Number(tank?.quantity),
            color: tank?.color,
            lot:
              lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
              getLabel("tankState_EMPTY"),
            ...(type === "RACKING" && {
              batch_type: tank?.batch_type || "PRESSED",
            }),
          },
          batch_name_selector: {
            value: s?.is_new,
            label: s?.is_new ? getLabel("lotNewName") : s?.new_batch_name,
          },
          new_batch_name: s?.is_new ? s?.batch_name : "",
        });
        tmpIsPressed.push(s?.batch_type === "PRESSED");
      });
      replace(dests);

      setIsPressed(tmpIsPressed);

      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });

      // const pomaces = [];
      //   operation?.pomaces?.map((s) => {
      //     const tank = tanks?.find((t) => t.id === s.tank_id);
      //     pomaces.push({
      //       quantity: s?.quantity * 100 / chosenQuantity,
      //       tankData: {
      //         value: tank?.id,
      //         label: tank?.name,
      //         capacity: Number(tank?.capacity),
      //         quantity: Number(tank?.quantity),
      //         availability:
      //         Number(tank?.capacity) - Number(tank?.quantity),
      //         color: tank?.color,
      //         lot:
      //         lots.find((lot) => lot?.id === tank?.batch_id)?.name ||
      //         getLabel("tankState_EMPTY"),
      //       },
      //     });
      //   });
      // replaceP(operation?.pomaces);

      setValue("pomaces", operation?.pomaces);
      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", operation?.expire_date);

      setValue("note", operation?.note);

      // if (lots?.find((l) => l.name === operation?.batch_name_out)) {
      //   setValue("batch_name_selector", {
      //     value: false,
      //     label: operation?.batch_name_out,
      //   });
      //   setValue("new_batch_name", "");
      // } else {
      //   setValue("batch_name_selector", {
      //     value: true,
      //     label: getLabel("lotNewName"),
      //   });
      // }
    }
  }, [operations, operation, lots]);

  // const getMaxPercentValue = (idx) => {
  //   const total = pomaces?.reduce((acc, curr) => {
  //     return acc + (Number(curr?.quantity) || 0);
  //   }, 0);
  //   return 100 - total + (Number(pomaces[idx]?.quantity) || 0);
  // };

  const sendData = async () => {
    const data = getValues();
    const dests = [];
    const newBatchIds = [];

    try {
      data?.dest_tanks
        ?.filter(
          (d) =>
            d?.tankData?.value &&
            d?.quantity &&
            ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
              (d?.batch_name_selector?.value === false &&
                d?.batch_name_selector?.label))
        )
        ?.forEach((destT, index) => {
          const batch_name_value = destT?.batch_name_selector?.value;
          const batch_name_label = destT?.batch_name_selector?.label;
          const new_batch_name = destT?.new_batch_name || batch_name_label;
          
          const bType =
            (batch_name_value &&
            lots?.find((l) => l?.name === batch_name_label) === undefined &&
            !newBatchIds.includes(new_batch_name)) || (!batch_name_value && lots?.find((l) => l?.name === batch_name_label && l.state !== 'WINE') !== undefined)
              
              ? "NEW"
              : (lots?.find(
                  (l) =>
                    (l?.name === new_batch_name &&
                      batch_name_value === true) ||
                    (l?.name === batch_name_label && batch_name_value === false)
                ) ===
                  undefined) !==
                undefined
              ? (lots?.find((l) => l?.name === new_batch_name) ===
                  undefined &&
                  !batch_name_value &&
                  destT?.batch_name === undefined) ||
                (batch_name_value === true && // modify an existing operation that created PLANNED batches
                  new_batch_name?.length > 0 &&
                  !newBatchIds?.includes(new_batch_name) &&
                  lots?.find(
                    (l) => l?.name === new_batch_name && l?.status === "PLANNED"
                  ) !== undefined)
                ? "NEW" // lot not existing yet but created in another operation TO DO check
                : "OLD"
              : null;
          if (bType === "NEW" && !(new_batch_name in newBatchIds))
            newBatchIds.push(new_batch_name);
          else if (!bType) throw new Error();

          const t = tanks?.find((t) => t.id === destT?.tankData?.value);
          const dest = {
            tank_id: destT?.tankData?.value,
            quantity: Number(destT.quantity),
            is_new:
              batch_name_value === true && new_batch_name?.length > 0,
            new_batch_name: (batch_name_value === true
              ? new_batch_name
              : batch_name_label
            )?.trim(),
            new_batch_type: bType,
            old_batch_id:
              t?.batch_id ||
              "",
            old_quantity:
              t?.quantity ||
              0,
            new_quantity:
              (Number(
                t?.quantity ||
                  0
              ) || 0) + (Number(destT.quantity) || 0),
            ...(type === "RACKING" && {
              batch_type:
                (!isPressed?.[index] ? "FLOWER" : "PRESSED") || "PRESSED",
            }),
          };

          dests.push(dest);
        });

      if (type === "RACKING") {
        // check if there are flower and pressed batches at the same time with the same name
        const lotNames = new Set();
        dests?.reduce((_, item) => lotNames.add(item?.new_batch_name), null);
        const lotNamesArr = Array.from(lotNames) || [];

        for (let i = 0; i < lotNamesArr?.length; i++) {
          const new_name = lotNamesArr?.[i];
          const tmpBatchType = new Set();
          const batchTypes =
            dests
              ?.filter((t) => t?.new_batch_name === new_name)
              ?.reduce(
                (_, item) =>
                  item?.batch_type &&
                  ["FLOWER", "PRESSED"].includes(item?.batch_type) &&
                  tmpBatchType.add(item?.batch_type),
                null
              ) || [];
          if (batchTypes?.size !== 1) {
            toast.error(
              getLabel("toast_invalidDestTanksBatchTypes", { name: new_name }),
              {
                toastId: customIdInvalidBatchTypes,
                position: toast.POSITION.BOTTOM_RIGHT,
                exclude: true,
                autoClose: 5000,
              }
            );
            return;
          }
        }
      }
    } catch (err) {
      toast.error(getLabel("toast_invalidDestTanks"), {
        toastId: customIdInvalidLotName,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: 5000,
      });
      return;
    }

    if (dests?.length === 0 || data?.dest_tanks?.length !== dests?.length) {
      toast.error(
        getLabel(
          dests?.length === 0
            ? "toast_missingDestTanks"
            : "toast_invalidDestTanks"
        ),
        {
          toastId: customIdInvalidLotName,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
          autoClose: 5000,
        }
      );
      return;
    }
    const tmpDate = new Date(data?.expire_date)?.setHours(23, 59, 59);
    // console.log('dests', dests)
    // return;
    const requestPayload = {
      dest_tanks: dests,
      priority: data?.priority.value,
      // expire_date: addMinutes(
      //   new Date(data?.expire_date)?.setHours(23, 59, 59),
      //   -new Date(data?.expire_date).getTimezoneOffset()
      // ).toISOString(),
      is_exact_date: isExactDate,
      expire_date: new Date(tmpDate)?.toISOString(),
      type: type,
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      new_batch_name: data?.new_batch_name,
      src_tanks: [
        {
          tank_id: data?.tank_id_in?.value,
          batch_id: data?.tank_id_in?.lot_id,
          old_quantity: data?.tank_id_in?.quantity,
          new_quantity: Number(data?.tank_id_in?.quantity) - getTotalQuantity(),
          isDecantDone: wineMakingData?.dest_tanks?.some?.(
            (tank) => data?.tank_id_in?.value == tank?.tank_id
          ),
        },
      ],
      pomaces: data?.pomaces,
      note: data?.note,
    };

    if (wineMakingData?.dest_tanks?.length > 0) {
      const wineMakingDecantRequestPayload =
        JSON.parse(localStorage.getItem("wineMakingDecantRequestPayload")) ||
        [];
      wineMakingDecantRequestPayload.push(requestPayload);
      localStorage.setItem(
        "wineMakingDecantRequestPayload",
        JSON.stringify(wineMakingDecantRequestPayload)
      );

      // console.log(wineMakingData);

      localStorage.setItem(
        "wineMakingData",
        JSON.stringify({
          ...wineMakingData,
          dest_tanks: wineMakingData?.dest_tanks?.filter(
            (d) => d?.tank_id !== data?.tank_id_in?.value
          ),
        })
      );

      // handleCloseModal();
      navigate("/programming/DECANT/new");
      return;
    }
    console.log("requestPayload", requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id && !isDuplicate) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type:
        !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/${type}/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const openFormModal = () => {
    // trigger(); // TO DO remove trigger from all

    const expDate = new Date(getValues("expire_date"));
    if (new Date(expDate?.setHours(23, 59, 59)) < new Date()) {
      setError("expire_date", {
        type: "manual",
        message: getLabel("errorDateInThePast"),
      });
      return;
    } else clearErrors("expire_date");

    if (dest_tanks?.filter((t) => t?.tankData?.value)?.length === 0) {
      toast(getLabel("toast_destemmingMissingDests"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    if (
      dest_tanks?.filter(
        (t) =>
          (!t?.batch_name_selector?.value &&
            t?.batch_name_selector?.label?.length === 0) ||
          (t?.batch_name_selector?.value === true &&
            t?.new_batch_name?.length === 0)
      )?.length > 0
    ) {
      toast(getLabel("toast_destemmingMissingLotSelectors"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    const transferLiters = getTotalQuantity();

    const totalLitersAvailable = Number(getValues("tank_id_in")?.quantity);
    if (transferLiters <= totalLitersAvailable) {
      toast.dismiss(customIdInvalidQuantities);
      setIsOpen(true);
    } else {
      toast(
        getLabel("toast_transferInvalidQuantities", {
          sum: transferLiters || "0",
          total: totalLitersAvailable,
        }),
        {
          toastId: customIdInvalidQuantities,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    }
  };

  const customIdMissingDests = "custom-id-missing-dests";
  const customIdInvalidLotName = "custom-id-invalid-lot-name";
  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  const customIdInvalidBatchTypes = "custom-id-invalid-batch-types";
  const customId = "custom-id-different-color";
  const checkColors = () => {
    const colors = new Set();
    dest_tanks?.reduce(
      (_, item) =>
        item?.tankData?.color ? colors.add(item?.tankData?.color) : null,
      null
    );
    if (getValues("tank_id_in")?.color)
      colors.add(getValues("tank_id_in")?.color);

    if (colors?.size > 1)
      toast(getLabel("toast_WarningDiffentWineColor"), {
        toastId: customId,
        type: toast.TYPE.WARNING,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      });
    else toast.dismiss(customId);
  };

  const getTotalQuantity = () => {
    return (
      dest_tanks?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0) || 0
    );
  };

  const computeLotNames = (isValidate = false) => {
    const names = new Set();

    // shows only 6 months old must batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    // lots?.filter((l) => (l?.state === 'MUST' || l?.state === 'WINE') && (new Date(l?.insertion_date) >= addMonths(new Date(), -6)))?.reduce((_, l) => names.add(l?.name), null);
    lotNames
      ?.filter((l) => l?.state === "MUST" || l?.state === "WINE")
      ?.reduce((_, l) => names.add(l?.name), null);

    if (dest_tanks?.length === 1 && dest_tanks?.[0]?.tankData?.value === null)
      return names;
    dest_tanks
      ?.filter((tank) => tank?.tankData?.quantity > 0)
      ?.reduce((_, item) => names.add(item?.tankData?.lot), null);

    if (!isValidate) {
      for (const tank of dest_tanks) {
        if (tank?.new_batch_name?.length > 0) names.add(tank?.new_batch_name);
      }
      let i = 0;
      for (const tank of dest_tanks?.filter(
        (t) => t?.tankData?.value && "batch_name_selector" in t
      )) {
        if (
          tank?.batch_name_selector?.value === false &&
          !Array.from(names.values())?.includes(
            tank?.batch_name_selector?.label
          )
        ) {
          setValue(`dest_tanks.${i}.batch_name_selector`, {
            value: "",
            label: "",
          });
        }
        i += 1;
      }
    }

    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });

    return nameObjects?.sort((a, b) =>
      a?.label > b?.label ? 1 : b?.label > a?.label ? -1 : 0
    );
  };

  const getTankData = (t, isSrc = false) => {
    const tank = !isSrc ? t?.tankData : t;
    const quantity = !isSrc
      ? tank?.availability >= Number(t?.quantity) && Number(t?.quantity) >= 0
        ? Number(t?.quantity)
        : 0
      : -getTotalQuantity();
    const new_batch_name = !isSrc
      ? t?.batch_name_selector?.value === true && t?.new_batch_name
        ? t.new_batch_name
        : t?.batch_name_selector?.value === false
        ? t?.batch_name_selector?.label
        : ""
      : tank?.lot;

    const tankData = {};
    tankData.name = t?.label;
    tankData.is_src = isSrc;
    tankData.arrow_quantity = quantity;
    tankData.capacity = tank.capacity;
    tankData.old_quantity = tank.quantity;
    tankData.old_batch_name = tank.lot || "";
    tankData.quantity = tank.quantity + quantity;
    tankData.color =
      tank.quantity > 0 ? tank?.color : getValues("batch_id_in")?.color;
    tankData.batch_name = new_batch_name;

    if (tankData?.quantity < 0 && isSrc) {
      tankData.quantity = 0;
      tankData.arrow_quantity = -Math.abs(tankData?.old_quantity);
    }

    return tankData;
  };

  return (
    <div className="operation_form_wrapper">
      <form
        onSubmit={handleSubmit(openFormModal)}
        autoComplete="off"
        noValidate
      >
        <h6>{getLabel("fromTank")}</h6>
        {isWineMakingApplication && (
          <div className="operation_form_wrapper_row">
            <TankIcon
              tank={getValues("tank_id_in")}
              isText={true}
              isDestemming={false}
              isSrc={true}
              isRecap={false}
            />
          </div>
        )}
        {!isWineMakingApplication && (
          <div className="operation_form_wrapper_row">
            <Select
              control={control}
              defaultValue={operation?.tank_id_in}
              name="tank_id_in"
              isDisabled={wineMakingData?.dest_tanks}
              label={getLabel("operationTransferTank")}
              placeholder={getLabel("operationTransferTankPlaceholder")}
              error={errors}
              required={getLabel("inputRequiredError")}
              options={(type === "RACKING"
                ? tanks?.filter((t) =>
                    lots?.some(
                      (lot) =>
                        lot?.name === t.batch_name &&
                        lot.state === "MUST" &&
                        t.quantity > 0 &&
                        !["BARRIQUE", "TONNEAU"].includes(t.type)
                    )
                  )
                : tanks?.filter((t) => t.quantity > 0)
              )
                ?.filter(
                  (tank) =>
                    !dest_tanks?.some?.(
                      (field) => field.tankData.value == tank.id
                    )
                )
                ?.map?.((tank) => {
                  return {
                    value: tank.id,
                    label: tank.name,
                    name:
                      tank?.name +
                      ` (${
                        lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
                        getLabel("tankState_EMPTY")
                      })`,
                    quantity: tank.quantity,
                    capacity: tank.capacity,
                    color: tank.color,
                    lot: tank.batch_name,
                    lot_id: tank?.batch_id || "",
                  };
                })}
            />
            {getValues("tank_id_in") && (
              <>
                {/* <TankIcon
                  tank={getValues("tank_id_in")}
                  isText={true}
                  isDestemming={false}
                  isSrc={true}
                  isRecap={false}
                /> */}
                <DestTankDetail
                  tank={getTankData(getValues("tank_id_in"), true)}
                  isDrop={false}
                  isName={false}
                  isFirst={true}
                  isMobile={isMobile}
                ></DestTankDetail>
              </>
            )}
          </div>
        )}
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("toTanks")}</h6>
          {fields?.map?.((field, idx) => {
            return (
              <div key={field.id} className="operation_form_wrapper_row">
                <Select
                  label={getLabel("operationDestTankName")}
                  placeholder={getLabel("operationDestTanksPlaceholder")}
                  error={errors}
                  required={getLabel("inputRequiredError")}
                  name={`dest_tanks.${idx}.tankData`}
                  control={control}
                  options={tanks
                    ?.filter(
                      (tank) => 
                        !dest_tanks?.some?.(
                          (field) =>
                            field.tankData.value == tank.id ||
                            tank.id == getValues("tank_id_in")?.value
                        ) &&
                        !wineMakingData?.dest_tanks?.some?.(
                          (field) => field?.tank_id == tank.id
                        ) &&
                        !["BARRIQUE", "TONNEAU"].includes(tank.type)
                    )
                    ?.filter(function (t) {
                      if (Number(t?.quantity) === 0) return true;
                      if (
                        type === "DECANT" ||
                        (type === "RACKING" &&
                          t.state === "WINE" &&
                          t.quantity > 0)
                      )
                        return true;
                      return false;
                    })
                    ?.map?.((tank) => {
                      return {
                        value: tank?.id,
                        label: tank?.name,
                        name:
                          tank?.name +
                          ` (${
                            Number(tank?.quantity) <= 0 ? getLabel("tankState_EMPTY") : lots?.find((lot) => lot?.id === tank?.batch_id)
                              ?.name || getLabel("tankState_EMPTY")
                          })`,
                        capacity: Number(tank?.capacity),
                        quantity: Number(tank?.quantity),
                        availability:
                          Number(tank?.capacity) - Number(tank?.quantity),
                        color: tank?.color,
                        lot: tank.batch_name,
                      };
                    })}
                  onTableChange={checkColors(idx)}
                />
                {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                  <>
                    <DestTankDetail
                      tank={getTankData(dest_tanks?.[idx])}
                      isDrop={false}
                      isName={false}
                      isFirst={idx === 0}
                      isMobile={isMobile}
                    ></DestTankDetail>
                    <Input
                      name={`dest_tanks.${idx}.quantity`}
                      register={register}
                      type="number"
                      label={getLabel(`operationDestTankQuantity`)}
                      placeholder={getLabel(
                        "operationDestTanksQuantityPlaceholder"
                      )}
                      required={getLabel("inputRequiredError")}
                      max={{
                        value: Math.min(
                          dest_tanks[0]?.tankData?.availability,
                          Number(getValues("tank_id_in")?.quantity || 0)
                        ),
                        message: getLabel("errorMaxValue", {
                          value: Math.min(
                            dest_tanks[0]?.tankData?.availability,
                            Number(getValues("tank_id_in")?.quantity || 0)
                          ),
                        }),
                      }}
                      min={{
                        value: 1,
                        message: getLabel("errorMinValue", { value: 1 }),
                      }}
                      regExpPattern={{
                        value: INT_REGEX,
                        message: getLabel("errorInt"),
                      }}
                      error={getError(idx, errors?.dest_tanks, "quantity")}
                    />
                    {type === "RACKING" && (
                      <Switch
                        leftOption={{
                          label: getLabel("lotType_FLOWER"),
                        }}
                        rightOption={{
                          label: getLabel("lotType_PRESSED"),
                        }}
                        toggleHandler={(e) =>
                          setIsPressed([
                            ...isPressed.slice(0, idx),
                            !isPressed[idx],
                            ...isPressed.slice(idx + 1),
                          ])
                        }
                        status={isPressed[idx]}
                        // disabled={true}
                      />
                    )}
                    {getValues(`dest_tanks.${idx}.tankData`)?.value && (
                      <div className="new_batch_name">
                        <Select
                          control={control}
                          defaultValue={{
                            value: "",
                            label: "",
                          }}
                          name={`dest_tanks.${idx}.batch_name_selector`}
                          // name={`batch_name_selector.${idx}`}
                          // name="batch_name_selector"
                          label={getLabel("operationNewLotName")}
                          placeholder={getLabel(
                            "operationNewLotNamePlaceholder"
                          )}
                          error={errors}
                          required={getLabel("inputRequiredError")}
                          options={[
                            {
                              value: true,
                              label: getLabel("lotNewName"),
                              className: "bold",
                            },
                            ...computeLotNames(),
                          ]}
                          tooltip={
                            idx === 0
                              ? getLabel(
                                  "destemmingTankDestBatchNameSelectorTooltip"
                                )
                              : ""
                          }
                        />
                        {getValues(`dest_tanks.${idx}.batch_name_selector`)
                          ?.value && (
                          <Input
                            name={`dest_tanks.${idx}.new_batch_name`}
                            error={getError(
                              idx,
                              errors?.dest_tanks,
                              "new_batch_name"
                            )}
                            // name="new_batch_name"
                            register={register}
                            type="text"
                            label={getLabel("operationNewBatchName")}
                            placeholder={getLabel(
                              "operationNewBatchNamePlaceholder"
                            )}
                            // error={errors["new_batch_name"]}
                            required={getLabel("inputRequiredError")}
                            isReverse={true}
                            minLength={{
                              value: 3,
                              message: getLabel("errorMinLength", { value: 3 }),
                            }}
                            maxLength={{
                              value: 24,
                              message: getLabel("errorMaxLength", {
                                value: 24,
                              }),
                            }}
                            regExpPattern={{
                              value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                              message: getLabel("errorAlphaNumSpace_-"),
                            }}
                            validate={(value) => {
                              if (
                                getValues(
                                  `dest_tanks.${idx}.batch_name_selector`
                                )?.value &&
                                lots
                                  ?.map((l) => l?.status !== 'PLANNED' && l?.name?.toLowerCase?.())?.includes(value?.toLowerCase?.()?.trim())
                              ) {
                                // toast.error(
                                //   getLabel("toast_lotNameAlreadyPresent"),
                                //   {
                                //     toastId: customIdInvalidLotName,
                                //     position: toast.POSITION.BOTTOM_RIGHT,
                                //     exclude: true,
                                //     autoClose: 5000,
                                //   }
                                // );
                                // return false;
                                return getLabel(
                                  "errorNewBatchNameAlreadyTaken",
                                  {
                                    name: value,
                                  }
                                );
                              }
                              // else {
                              // toast.dismiss(customIdInvalidLotName);
                              // return true;
                              // }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {fields?.length > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      remove(idx);
                      setIsPressed(isPressed.splice(idx, 1));
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            );
          })}
          <button
            id="tanksD"
            onClick={(e) => {
              e.preventDefault();
              append({
                tankData: {},
                quantity: 0,
              });
              setIsPressed([...isPressed, false]);
            }}
          >
            +
          </button>
        </div>
        <h6>{getLabel("operationAdditional")}</h6>
        {!isWineMakingApplication && (
          <div className="operation_form_wrapper_row">
            <MultipleSelect
              name={`cellarman_ids`}
              control={control}
              label={getLabel("operationUser")}
              placeholder={getLabel("operationUserPlaceholder")}
              error={errors["cellarman_ids"]}
              required={getLabel("inputRequiredError")}
              options={users?.map?.((user) => {
                return {
                  value: user?.id,
                  label: user?.username,
                };
              })}
              tooltip={getLabel("destemmingCellarmenTooltip")}
            />
            <Select
              name="priority"
              control={control}
              label={getLabel("operationPriority")}
              placeholder={getLabel("operationPriorityPlaceholder")}
              error={errors}
              required={getLabel("inputRequiredError")}
              options={[
                {
                  label: getLabel("lotQuality_HIGH"),
                  value: "2",
                },
                {
                  label: getLabel("lotQuality_MEDIUM"),
                  value: "1",
                },
                {
                  label: getLabel("lotQuality_LOW"),
                  value: "0",
                },
              ]}
              // defaultValue={{
              //     value: "",
              //     label: "",
              //   }}
            />
            <DatePicker
              name="expire_date"
              control={control}
              label={getLabel("operationExpireDateForm")}
              error={errors["expire_date"]}
              required={getLabel("inputRequiredError")}
              dateFormat={configs.shortDateFormat}
              placeholder={getLabel("expiringDatePlaceholder")}
            />
            {getValues("expire_date") && <Input
              name={"expire_date_exact"}
              register={register}
              label={getLabel("isExactDate")}
              error={errors["expire_date_exact"]}
              // isLabel={false}
              type={"checkbox"}
              checked={isExactDate}
              onChange={() => setIsExactDate((prev) => !prev)}
              customClassName={"confirm"}
              tooltip={getLabel("destemmingExactDate")}
            />}
          </div>
        )}
        {type === "RACKING" && (
          <>
            <h6>{getLabel("operationPomaces")}</h6>
            <div className="operation_form_wrapper_row vertical">
              <Input
                name={`pomaces`}
                register={register}
                type="text"
                label={getLabel("operationPomacesDestination")}
                placeholder={getLabel(
                  "operationPomacesDestinationPlaceholder"
                )}
                errors={errors["pomaces"]}
                minLength={{
                  value: 3,
                  message: getLabel("errorMinLength", { value: 3 }),
                }}
                maxLength={{
                  value: 24,
                  message: getLabel("errorMaxLength", { value: 24 }),
                }}
                regExpPattern={{
                  value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                  message: getLabel("errorAlphaNumSpace_-"),
                }}
                tooltip={getLabel("destemmingPomacesTooltip")}
              />
            </div>
            {/* <div className="operation_form_wrapper_row vertical">
              {pomacesFields?.map?.((field, idx) => {
                return (
                  <div key={field.id} className="operation_form_wrapper_row">
                    <Input
                      name={`pomaces.${idx}.dest`}
                      register={register}
                      type="text"
                      label={getLabel("operationPomacesDestination")}
                      placeholder={getLabel(
                        "operationPomacesDestinationPlaceholder"
                      )}
                      errors={errors["pomaces_dest"]}
                      minLength={{
                        value: 3,
                        message: getLabel("errorMinLength", { value: 3 }),
                      }}
                      maxLength={{
                        value: 24,
                        message: getLabel("errorMaxLength", { value: 24 }),
                      }}
                      regExpPattern={{
                        value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                        message: getLabel("errorAlphaNumSpace_-"),
                      }}
                      tooltip={
                        idx === 0 ? getLabel("destemmingPomacesTooltip") : ""
                      }
                    />
                    {getValues(`pomaces.${idx}.dest`) && (
                      <Slider
                        name={`pomaces.${idx}.quantity`}
                        register={register}
                        label={getLabel("destemmingQuantityPercent")}
                        // error={errors[`pomaces.${idx}.quantity`]}
                        error={getError(idx, errors?.pomaces, "quantity")}
                        defaultValue={operation?.pomaces[idx]?.quantity || 0}
                        min={{
                          value: getMaxPercentValue(idx),
                          message: getLabel("errorMinValue", {
                            value: getMaxPercentValue(idx),
                          }),
                        }}
                        max={{
                          value: getMaxPercentValue(idx),
                          message: getLabel("errorMaxValue", {
                            value: getMaxPercentValue(idx),
                          }),
                        }}
                        tooltip={
                          idx === 0
                            ? getLabel("destemmingPomacesQuantityTooltip")
                            : ""
                        }
                      />
                    )}
                    {pomacesFields?.length > 0 && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeP(idx);
                        }}
                      >
                        -
                      </button>
                    )}
                  </div>
                );
              })}
              <div className="operation_form_wrapper_row">
                <p>{getLabel("operationPomacesAdd")}</p>
                <button
                  id="pomacesD"
                  onClick={(e) => {
                    e.preventDefault();
                    appendP({
                      quantity: 0,
                      dest: "",
                    });
                  }}
                >
                  +
                </button>
              </div>
            </div> */}
          </>
        )}
        <div className="operation_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        {!isWineMakingApplication && (
          <div className="operation_form_wrapper_row">
            <Submit label={getLabel("submitForm")}></Submit>
          </div>
        )}
      </form>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel(
          type === "DECANT"
            ? "modalNewDecantDescription"
            : "modalNewRackingDescription",
          {
            name: operation?.id || "",
          }
        )}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
        // setNewBatchName={setNewBatchName}
      >
        <OperationRecap
          type={type}
          control={control}
          register={register}
          getValues={getValues}
          errors={errors}
          lots={lots}
          lotNames={lots?.map((l) => l.name)}
          dest_tanks={dest_tanks?.map((d, index) => {
            return {
              ...d?.tankData,
              name: d?.tankData?.label,
              color: d?.tankData?.color || getValues("tank_id_in")?.color,
              old_quantity: Number(d?.quantity) + Number(d?.tankData?.quantity),
              quantity: Number(d?.quantity) + Number(d?.tankData?.quantity),
              add_quantity: Number(d?.quantity),
              batch_name:
                d?.batch_name_selector?.value === true
                  ? d?.new_batch_name
                  : d?.batch_name_selector?.label,
              ...(type === "RACKING" && {
                batch_type:
                  (!isPressed?.[index] ? "FLOWER" : "PRESSED") || "PRESSED",
              }),
            };
          })}
          src_tanks={[getTankData(getValues("tank_id_in"), true)]}
          // newBatchName={newBatchName}
        />
      </ConfirmationModal>
    </div>
  );
};

export default TransferForm;
