import { addDays, addMonths, format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import useLabels from "../../hooks/useLabels";
import { Button, CTA } from "../Generic";
import SecondaryBar from "../SecondaryBar";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfigs,
  selectUser,
  selectUsers,
} from "../../context/selectors";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  getUsers,
  updateUser,
  newUser,
  updateUserPassword,
  updateUserEmail,
} from "../../services/utils";
import {
  setUser,
  add,
  update,
  setUsers,
  emptyUsers,
} from "../../context/users/usersSlice";
import { Input, Label, Select, Submit } from "../FormComponents";
import {
  ALPHA_SPACE_APEX_REGEX,
  EMAIL_REGEX,
  INT_REGEX,
  NOT_USER_REGEX,
  PWD_REGEX,
  USER_REGEX,
} from "../../constants/regex";
import { updateUserSetting } from "../../services/utils";
import { toast } from "react-toastify";
import { getItem, setItem } from "../../services/LocalStorage";
import ReactDatePicker from "react-datepicker";
import italian_flag from "../../assets/icons/italy-48.png";
import french_flag from "../../assets/icons/french-48.png";
import spanish_flag from "../../assets/icons/spain-48.png";
import american_flag from "../../assets/icons/american-48.png";

import "./styles/index.scss";
import { empty } from "../../context/labels/labelsSlice";
import { setLocale } from "../../context/configs/configsSlice";

const UserCustomization = ({}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const users = useSelector(selectUsers);
  const currentUser = useSelector(selectUser);
  const wineriesSession = getItem("wineriesList") || [];
  const currentUserClientName =
    wineriesSession?.find((winery) => winery.id === getItem("activeWineryId"))
      ?.client_name || null;

  const configs = useSelector(selectConfigs);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [localStorageLanguage, setLocalStorageLanguage] = useState(getItem("i18Language") || "it_IT");
  const [user, setUser] = useState(
    users?.length > 0
      ? users.find((dbUser) => dbUser.id === Number(id)) || {}
      : {}
  );
  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: addMonths(
      new Date(),
      configs.settings.nextOperationMonthsInFutureDefault || 3
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    getValues,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      email: user?.client_email || "",
      name: user?.name || "",
      surname: user?.surname || "",
      role: user?.type
        ? { label: getLabel(user?.type), value: user?.type }
        : "",
    },
    mode: "onTouched",
  });
  
  const { language } = useWatch({ control });

  useEffect(() => {
    if (id && (!users || users.length === 0)) {
      loadUsers();
    }
    if (user) {
      setValue("email", user?.client_email || "");
      setValue("name", user?.name || "");
      setValue("surname", user?.surname || "");
      setValue(
        "role",
        user?.type ? { label: getLabel(user?.type), value: user?.type } : ""
      );
    }
    setValue(
      "language", { value: localStorageLanguage, label: getLabel(`language_${localStorageLanguage}`)}
    );
  }, []);

  useEffect(() => {
    if (!users || users.length === 0) {
      loadUsers();
    }

    if (id && users.length > 0 && Object.keys(user).length === 0) {
      const singleUser =
        users.find((dbUser) => dbUser.id === Number(id)) || null;
      if (singleUser) {
        setUser(singleUser);
        setValue("email", singleUser?.client_email || "");
        setValue("name", singleUser?.name || "");
        setValue("surname", singleUser?.surname || "");
        setValue(
          "role",
          singleUser?.type
            ? { label: getLabel(singleUser?.type), value: singleUser?.type }
            : ""
        );
      }
    }
  }, [users]);

  useEffect(() => {
    const currentUserId = users?.find(
      (u) => u.username === currentUser?.userName
    )?.id;
    if (
      !window.location?.pathname?.includes("/new") &&
      currentUserId != id &&
      currentUserId
    )
      navigate(`/users/${id}`);
  }, [user, users]);

  const loadUsers = async () => {
    const currentUsers = await getUsers(axiosPrivate);
    dispatch(setUsers(currentUsers));
  };

  const updateEmail = async () => {
    const email = getValues("email");
    if (!email.match(EMAIL_REGEX)) {
      console.log("Invalid e-mail");
      return;
    }

    await updateUserEmail(email, axiosPrivate);
  };

  const updatePassword = async () => {
    const oldPassword = getValues("oldPassword");
    const password = getValues("password");

    if (!oldPassword.match(PWD_REGEX) || !password.match(PWD_REGEX)) {
      console.log("Invalid password");
      return;
    }

    const response = await updateUserPassword(
      oldPassword,
      password,
      axiosPrivate
    );
    if (response && response?.success) {
      setUser({});
      dispatch(emptyUsers());
      navClickHandler();
    }
  };

  const [defaultUsername, setDefaultUsername] = useState("");
  const updateDefaultUsername = () => {
    setDefaultUsername("");
    if (
      getValues("new_name") &&
      getValues("new_surname") &&
      (getValues("new_name").charAt(0) + "." + getValues("new_surname"))
        ?.replace(NOT_USER_REGEX, "")
        ?.replace(/\s+/g, "")?.length > 3
    ) {
      setDefaultUsername(
        (getValues("new_name").charAt(0) + "." + getValues("new_surname"))
          ?.replace(NOT_USER_REGEX, "")
          ?.replace(/\s+/g, "")
          ?.toLowerCase()
      );
    }
  };

  const submitForm = async (data) => {
    if (id) {
      const requestPayload = {
        ...(data?.name !== user?.name && { name: data?.name }),
        ...(data?.surname !== user?.surname && { surname: data?.surname }),
        ...(id && { id: id }),
      };
      console.log(requestPayload);
      const response = await updateUser(
        user?.username,
        requestPayload,
        axiosPrivate
      );
      if (response && response?.success) {
        updateStore(requestPayload);
        navClickHandler();
      }
    } else {
      const email = getValues("new_email");
      const name = getValues("new_name");
      const surname = getValues("new_surname");
      const username = getValues("new_username");
      const role = getValues("new_role");

      if (!email.match(EMAIL_REGEX)) {
        console.log("Invalid e-mail");
        return;
      }

      const requestPayload = {
        ...(name && { name: name }),
        ...(surname && { surname: surname }),
        ...(username && { username: username }),
        ...(email && { email: email }),
        ...(role && { role: Number(role) }),
        ...(id && { id: id }),
      };

      const response = await newUser(username, requestPayload, axiosPrivate);
      console.log(response);
      // if (response && response?.success) {
      //     setUser({});
      //     // dispatch(emptyUsers());
      //     navClickHandler();
      // }
    }
  };

  const updateStore = (payload) => {
    if (payload.id) {
      dispatch(update(payload));
    } else {
      dispatch(add(payload));
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/users/${id}`);
    } else {
      navigate("/users");
    }
  };

  const changeLanguage = async (l) => {
    if (localStorageLanguage === l?.value) return;

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    const response = await updateUserSetting(
      "language",
      l?.value || "it_IT",
      axiosPrivate
    );

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { key: l?.label || "" })
        : getLabel(response?.error),
      type: !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });
    if (response && response?.success) {
        setItem("i18Language", l?.value);
        setItem("i18LanguageLocale", l?.value?.split("_")?.[0] || "it");
        
      setLocalStorageLanguage(l?.value);
    //   dispatch(empty());
      dispatch(setLocale({ locale: l?.value?.split("_")?.[0] || "it" }));
    }
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      if (id) {
        navigate(`/users/${id}`);
      } else {
        navigate("/wineries");
      }
    };

    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{getLabel("usersCustomizePage") + " " + user?.username}</h2>
        </div>
      </div>
    );
  };

  const renderWarning = () => {
    return (
      <div className={`warning_wrapper warning_exp`}>
        <div className="warning_label">
          {getLabel("operationsToBeCompleted")}
        </div>
        <div className="warning_value">7</div>
      </div>
    );
  };

  const renderInterval = () => {
    return (
      <div className="interval_value">
        <div className="interval_vwrapper">
          <span>{getLabel("dateFrom")}</span>
          <ReactDatePicker
            selected={dates.startDate}
            onChange={(date) =>
              setDates({
                ...dates,
                startDate: date.setHours(0, 0, 0),
              })
            }
            selectsStart
            startDate={dates.startDate}
            endDate={dates.endDate}
            minDate={new Date()}
            dateFormat={configs.shortDateFormat}
          />
        </div>
        <div className="interval_vwrapper">
          <span>{getLabel("dateTo")}</span>
          <ReactDatePicker
            selected={dates.endDate}
            onChange={(date) =>
              setDates({
                ...dates,
                endDate: date.setHours(23, 59, 59),
              })
            }
            selectsEnd
            startDate={dates.startDate}
            endDate={dates.endDate}
            minDate={addDays(dates.startDate, 1)}
            dateFormat={configs.shortDateFormat}
          />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="user_form_wrapper">
        <form onSubmit={handleSubmit(submitForm)} autoComplete="off" noValidate>
          <h4>{getLabel("settingsChooseLanguage")}</h4>
          <div className="user_form_wrapper_row">
            <Select
              label={getLabel("chooseLanguage")}
              placeholder={getLabel("chooseLanguagePlaceholder")}
              required={getLabel("inputRequiredError")}
              name={"language"}
              control={control}
              options={[
                {
                  value: "it_IT",
                  label: getLabel("language_it_IT"),
                },
                {
                  value: "en_GB",
                  label: getLabel("language_en_GB"),
                },
                {
                  value: "es_ES",
                  label: getLabel("language_es_ES"),
                },
                {
                  value: "fr_FR",
                  label: getLabel("language_fr_FR"),
                },
              ]}
              onTableChange={(l) => changeLanguage(l)}
            />
            {language?.value && (
              <img
                className="lang_icon"
                src={
                  language?.value === "es_ES"
                    ? spanish_flag
                    : language?.value === "en_GB"
                    ? american_flag
                    : language?.value === "fr_FR"
                    ? french_flag
                    : italian_flag
                }
                alt="flag"
              />
            )}
          </div>
          <h4>{getLabel("programmingPage")}</h4>
          <div className="user_form_wrapper_row center">
            <Input
              name="expire_operation_deadline"
              register={register}
              type="number"
              label={getLabel("clientCustomToBeCompletedTimeInterval")}
              placeholder={getLabel(
                "clientCustomToBeCompletedTimeIntervalPlaceholder"
              )}
              error={errors["expire_operation_deadline"]}
              onChange={updateDefaultUsername}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 2,
                message: getLabel("errorMaxLength", { value: 2 }),
              }}
              max={{
                value: 31,
                message: getLabel("errorMaxValue", { value: 31 }),
              }}
              regExpPattern={{
                value: INT_REGEX,
                message: getLabel("errorInt"),
              }}
              tooltip={getLabel("clientCustomToBeCompletedTimeIntervalTooltip")}
            />
            {renderWarning()}
          </div>
          <div className="user_form_wrapper_row center">
            <Input
              name="operation_deadline"
              register={register}
              type="number"
              label={getLabel("clientCustomOpsTimeInterval")}
              placeholder={getLabel("clientCustomOpsTimeIntervalPlaceholder")}
              error={errors["operation_deadline"]}
              onChange={updateDefaultUsername}
              minLength={{
                value: 1,
                message: getLabel("errorMinLength", { value: 1 }),
              }}
              maxLength={{
                value: 2,
                message: getLabel("errorMaxLength", { value: 2 }),
              }}
              max={{
                value: 12,
                message: getLabel("errorMaxValue", { value: 12 }),
              }}
              regExpPattern={{
                value: INT_REGEX,
                message: getLabel("errorInt"),
              }}
              tooltip={getLabel("clientCustomOpsTimeIntervalTooltip")}
            />
            {renderInterval()}
          </div>

          {/* {currentUser?.userName === user?.username && <>
                <div className="user_form_wrapper_row">
                    <Input
                        name="name"
                        register={register}
                        type="text"
                        label={getLabel('clientName')}
                        placeholder={getLabel('clientNamePlaceholder')}
                        error={errors['name']}
                    />
                    <Input
                        name="surname"
                        register={register}
                        type="text"
                        label={getLabel('clientSurname')}
                        placeholder={getLabel('clientSurnamePlaceholder')}
                        error={errors['surname']}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Label label={getLabel('wineryClientName')}>
                        {user?.client_name}
                    </Label>
                    <Label label={getLabel('userRole')}>
                        {getLabel(`user_type_${user?.type}`)}
                    </Label>
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name="email"
                        register={register}
                        type="text"
                        regExpPattern={{
                            value: EMAIL_REGEX,
                            message: getLabel('inputPatternError')
                        }}    
                        label={getLabel('userEmail')}
                        placeholder={getLabel('userEmailPlaceholder')}
                        error={errors['email']}
                    />
                    <CTA onClick={updateEmail}>{getLabel('updateClientEmail')}</CTA>
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name='oldPassword'
                        register={register}
                        type="password"
                        label={getLabel('oldPassword')}
                        placeholder={getLabel('oldPasswordPlaceholder')}
                        error={errors['oldPassword']}
                    />
                    <Input
                        name='password'
                        register={register}
                        regExpPattern={{
                            value: PWD_REGEX,
                            message: getLabel('inputPatternError')
                        }}
                        type="password"
                        label={getLabel('newPassword')}
                        placeholder={getLabel('newPasswordPlaceholder')}
                        error={errors['password']}
                    />
                    <Label label={getLabel('passwordExpireDate')}>{format(
                            new Date(user?.password_expire_date || null),
                            configs.shortDateFormat
                        )}
                    </Label>
                    <CTA onClick={updatePassword}>{getLabel('updateClientPassword')}</CTA>
                </div>
                <div className="user_form_wrapper_row">
                    <Submit
                        label={getLabel('submitForm')}
                    />
                </div>
                </>}
                {currentUser?.userName !== user?.username && <>
                <div className="user_form_wrapper_row">
                    <Input
                        name="new_name"
                        register={register}
                        type="text"
                        label={getLabel('clientName')}
                        placeholder={getLabel('clientNamePlaceholder')}
                        error={errors['new_name']}
                        onChange={updateDefaultUsername}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: ALPHA_SPACE_APEX_REGEX, message: getLabel('errorAlphaSpaceApex')}}
                    />
                    <Input
                        name="new_surname"
                        register={register}
                        type="text"
                        label={getLabel('clientSurname')}
                        placeholder={getLabel('clientSurnamePlaceholder')}
                        error={errors['new_surname']}
                        onChange={updateDefaultUsername}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: ALPHA_SPACE_APEX_REGEX, message: getLabel('errorAlphaSpaceApex')}}
                    />
                    <Input
                        name="new_username"
                        register={register}
                        type="text"
                        label={getLabel('userName')}
                        placeholder={defaultUsername && defaultUsername?.length > 0 ? (getLabel('recommendedUserNamePlaceholder', {recommended: defaultUsername})): getLabel('userNamePlaceholder')}
                        error={errors['new_username']}
                        minLength={{value: 4, message: getLabel('errorMinLength', {value: 4})}}
                        maxLength={{value: 25, message: getLabel('errorMaxLength', {value: 25})}}
                        regExpPattern={{value: USER_REGEX, message: getLabel('errorUsername')}}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Label label={getLabel('wineryClientName')}>
                        {currentUserClientName}
                    </Label>
                    <Select
                        control={control}
                        name="new_role"
                        label={getLabel('userRole')}
                        defaultValue={{
                            value: 3,
                            label: getLabel('user_type_USER')
                        }}
                        required={getLabel('inputRequiredError')}
                        errors={errors['type']}
                        placeholder={getLabel('userRolePlaceholder')}
                        options={[{
                            value: 3,
                            label: getLabel('user_type_USER')
                        },
                        // {
                        //     value: 2,
                        //     label: getLabel('user_type_ANALYST'),
                        //     disabled: true
                        // }
                        ]}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Input
                        name="new_email"
                        register={register}
                        type="text"
                        label={getLabel('userEmail')}
                        placeholder={getLabel('userEmailPlaceholder')}
                        error={errors['new_email']}
                        minLength={{value: 5, message: getLabel('errorMinLength', {value: 5})}}
                        maxLength={{value: 35, message: getLabel('errorMaxLength', {value: 35})}}
                        regExpPattern={{value: EMAIL_REGEX, message: getLabel('errorEmail')}}
                    />
                </div>
                <div className="user_form_wrapper_row">
                    <Submit
                        label={getLabel('submitForm')}
                    />
                </div>
                </>} */}
        </form>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("usersNavLink"),
          getLabel(id ? "usersUpdatePage" : "usersNewPage"),
        ]}
        isBasePathNeeded={false}
      />
      {renderNavMenu()}
      {renderForm()}
    </div>
  );
};

export default UserCustomization;
