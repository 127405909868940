import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { ConfirmationModal, InfoCard, OperationRecap } from "../Generic";
import {
  selectConfigs,
  selectDrugs,
  selectUnits,
  selectWarehouseByType,
} from "../../context/selectors";
import {
  getWarehouseByType,
  getDrugs,
  newWarehouseByType,
  deleteWarehouseByType,
  updateWarehouseByType,
  getUnits,
  newDrug,
  updateDrug,
  deleteDrug,
  updateDrugWarehouse,
} from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDrugItem,
  removeDrugItem,
  setDrugs,
  updateDrugItem,
} from "../../context/substances/drugsSlice";

import additives from "../../assets/icons/additives.png";
import adjuvants from "../../assets/icons/adjuvants.png";
import { SkeletonTableEdit } from "../Skeleton/Skeleton";
import { ROLES } from "../../constants/base";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import TableEdit from "../TableEdit";
import { createColumnHelper } from "@tanstack/react-table";
import TableCell from "../TableEdit/TableCell";
import {
  addWarehouse,
  removeWarehouse,
  setWarehouse,
  updateWarehouse,
} from "../../context/warehouse/warehouseSlice";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import EditCell from "../TableEdit/EditCell";
import { INT_FLOAT_REGEX } from "../../constants/regex";
import "./styles/index.scss";
import { setUnits } from "../../context/units/unitsSlice";
import MultipleRowsCell from "../TableEdit/MultipleRowsCell";

const Warehouse = ({ isMobile }) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const substances = useSelector(selectDrugs);
  const units = useSelector(selectUnits);
  const configs = useSelector(selectConfigs);
  const [substancesLen, setSubstancesLen] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveId, setIsRemoveId] = useState(-1);
  const [isModifyMode, setIsModifyMode] = useState(-1);

  const warehouseData = useSelector(selectWarehouseByType(type));
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    getValues,
    setError,
    control,
  } = useForm({
    defaultValues: {
      // name: "",
    },
    mode: "onTouched",
  });

  const { ingredients, batches } = useWatch({ control });

  const arrayFields = useFieldArray({
    control,
    name: "ingredients",
  });

  const arrayFields2 = useFieldArray({
    control,
    name: "batches",
  });

  const loadSubstances = async () => {
    const currentSubstances = await getDrugs(axiosPrivate);
    dispatch(setDrugs(currentSubstances));
  };

  const loadWarehouse = async () => {
    const currentData = await getWarehouseByType(axiosPrivate, type);
    dispatch(setWarehouse({ type: type, data: currentData }));
  };

  useEffect(() => {
    if (
      ["treatments", "stock"].includes(type) &&
      (!substances || substances.length === 0)
    ) {
      loadSubstances();
    }
    if (
      ["caps", "bottles"].includes(type) &&
      (!warehouseData || warehouseData.length === 0)
    ) {
      loadWarehouse();
    }
  }, [type]);

  const setModifyMode = (row_id) => {
    setIsModifyMode(row_id);
  };

  const renderHeader = () => {
    return (
      <div className="primary_container_menu">
        <h2>
          {getLabel("warehouse", {
            type: (type === "stock"
              ? getLabel("warehouseStockNavLink")
              : type === "bottles"
              ? getLabel("warehouseBottlesNavLink")
              : type === "treatments"
              ? getLabel("warehouseTreatmentNavLink")
              : type === "caps"
              ? getLabel("warehouseCapsNavLink")
              : ""
            )?.toLowerCase(),
          })}
        </h2>
      </div>
    );
  };

  const loadUnits = async () => {
    const currentUnits = await getUnits(axiosPrivate);
    dispatch(setUnits(currentUnits));
  };

  useEffect(() => {
    if (!units || units.length === 0) {
      loadUnits();
    }
  }, []);

  useEffect(() => {
    if (!substances || substances?.length === 0) return;
    const tmp = {};
    tmp.tot = substances?.length || 0;
    const additive = substances?.filter((t) => t?.type === "ADDITIVE");
    const adjuvant = substances?.filter((t) => t?.type === "ADJUVANT");
    tmp.additive = additive?.length;
    tmp.adjuvant = adjuvant?.length;

    setSubstancesLen(tmp);
  }, [substances]);

  const columnHelper = createColumnHelper();

  const warehouseColumns =
    type === "stock"
      ? [
          columnHelper.accessor("label", {
            header: getLabel("treatmentName"),
            cell: TableCell,
            meta: {
              filterVariant: "text",
            },
          }),
          columnHelper.accessor("batch_number", {
            header: getLabel("drugBatchNumbers"),
            cell: MultipleRowsCell,
            meta: {
              array: "batches",
              selector: "batch_number",
              isFilterDisabled: true,
            },
          }),
          columnHelper.accessor("batch_quantity", {
            header: getLabel("drugBatchQuantity"),
            cell: MultipleRowsCell,
            meta: {
              array: "batches",
              selector: "batch_quantity",
              isFilterDisabled: true,
            },
          }),
          columnHelper.accessor("batch_unitary_cost", {
            header: getLabel("drugBatchCost"),
            cell: MultipleRowsCell,
            meta: {
              array: "batches",
              selector: "batch_unitary_cost",
              isFilterDisabled: true,
            },
          }),
          columnHelper.accessor("expire_date", {
            header: getLabel("drugExpireDate"),
            cell: MultipleRowsCell,
            meta: {
              array: "batches",
              selector: "expire_date",
              isFilterDisabled: true,
            },
          }),
          columnHelper.display({
            header: "",
            id: "edit",
            cell: EditCell,
            meta: {
              isEditable: role === ROLES.CLIENT,
              role: role,
            },
          }),
        ]
      : type === "treatments"
      ? [
          columnHelper.accessor("name", {
            header: getLabel("drugName"),
            cell: TableCell,
            meta: {
              filterVariant: "text",
            },
          }),
          columnHelper.accessor(
            (row) =>
              row?.type ? getLabel(`drugType_${row.type?.toUpperCase()}`) : "",
            {
              header: getLabel("drugType"),
              cell: TableCell,
              meta: {
                isFilterDisabled: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) => `${row?.quantity} ${row?.unit || ""}`,
            {
              header: getLabel("drugQuantity"),
              cell: TableCell,
              meta: {
                type: "number",
                editable: true,
                regExpPattern: INT_FLOAT_REGEX,
                isFilterDisabled: true,
                register: register,
              },
            }
          ),
          columnHelper.accessor(
            (row) =>
              row?.recommended_quantity
                ? `${row?.recommended_quantity} ${row?.unit || ""}`
                : "",
            {
              header: getLabel("substanceRange"),
              cell: TableCell,
              meta: {
                isFilterDisabled: true,
              },
            }
          ),
          // columnHelper.accessor(
          //   (row) =>
          //     quantities?.length > 0
          //       ? `${quantities[row?.index] || ""}` || ""
          //       : (row?.quantity || "") +
          //         (row?.quantity ? " " + (row?.unit || "")  : ""),
          //   {
          //     header: getLabel("drugQuantity"),
          //     cell: TableCell,
          //     meta: {
          //       isFilterDisabled: true,
          //     },
          //   }
          // ),
          columnHelper.accessor(
            (row) =>
              `${
                row?.min_temperature ? String(row?.min_temperature) : "n/a"
              } - ${
                row?.max_temperature ? String(row?.max_temperature) : "n/a"
              } \u00b0C` || "",
            {
              header: getLabel("substanceTemperature"),
              cell: TableCell,
              meta: {
                type: "number",
                isFilterDisabled: true,
              },
            }
          ),
          columnHelper.accessor("stocks", {
            header: getLabel("drugStockQuantity"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            },
          }),
          columnHelper.display({
            header: "",
            id: "edit",
            cell: EditCell,
            meta: {
              isEditable: role === ROLES.CLIENT,
              role: role,
            },
          }),
        ]
      : [
          columnHelper.accessor("name", {
            header: getLabel(type === "caps" ? "capName" : "bottleName"),
            cell: TableCell,
            meta: {
              filterVariant: "text",
            },
          }),
          columnHelper.accessor("origin", {
            header: getLabel("capOrigin"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            },
          }),
          columnHelper.accessor("quantity", {
            header: getLabel(
              type === "caps" ? "capQuantity" : "bottleQuantity"
            ),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            },
          }),
          columnHelper.accessor((row) => row?.notes || "", {
            header: getLabel("capNote"),
            cell: TableCell,
            meta: {
              isFilterDisabled: true,
            },
          }),
          columnHelper.display({
            header: "",
            id: "edit",
            cell: EditCell,
            meta: {
              isEditable: role === ROLES.CLIENT,
              role: role,
            },
          }),
        ];
  
  const renderDrugsNavMenu = () => {
    return (
      <div className="cards_wrapper">
        <div>
          <h5 className="tanks_title">
            {getLabel("numTotSubstances")}
            <span className="tanks_span">{substancesLen?.tot || "0"}</span>
          </h5>
        </div>
        <div className={`tanks_wrapper`}>
          <div className="tanks_recap">
            <div className="tanks_recap_wrapper">
              <InfoCard
                label={getLabel("numTotAdjuvants")}
                number={substancesLen?.adjuvant}
                img={isMobile || true ? adjuvants : null}
              />
              <InfoCard
                label={getLabel("numTotAdditives")}
                number={substancesLen?.additive}
                img={isMobile || true ? additives : null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const customIdInvalidIngredientsSum = "custom-id-invalid-ingredients-sum";

  const submitTreatmentForm = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const data = getValues();

    const id = data?.id || isRemoveId;

    const ingredientData = ingredients?.map((i) => {
      return { ...i, quantity: Number(i?.quantity) };
    });
    const requestPayload = {
      ...data,
      ingredients: ingredientData,
      ...((data?.unit_id && {
        unit_id: data?.unit_id.value,
        unit: data?.unit_id.label,
      }) ||
        0),
      ...((data?.type && { type: data?.type.value }) || ""),
      ...(id && { id: Number(id) }),
    };

    if (isRemoveId === -1 && ingredientData?.length > 0) {
      const ingredientsSum = ingredientData?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0);
      if (ingredientsSum <= 0 || ingredientsSum > 100) {
        toast.error(
          getLabel("toast_invalidIngredientsSum", { value: ingredientsSum }),
          {
            toastId: customIdInvalidIngredientsSum,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
            autoClose: 5000,
          }
        );
        return;
      } else if (ingredientsSum < 100) {
        toast.warning(
          getLabel("toast_invalidIngredientsSumWarning", {
            value: ingredientsSum,
          }),
          {
            toastId: customIdInvalidIngredientsSum,
            position: toast.POSITION.BOTTOM_RIGHT,
            exclude: true,
            autoClose: 5000,
          }
        );
        return;
      }
    }

    console.log("requestPayload", requestPayload);
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await (isModifyMode > 0
      ? updateDrug(id, requestPayload, axiosPrivate)
      : isRemoveId > 0
      ? deleteDrug(id, axiosPrivate)
      : newDrug(requestPayload, axiosPrivate));

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.name || "" })
        : getLabel(response?.error),
      type:
        !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      if (isRemoveId > 0) {
        removeSubstanceItem(response);
        setIsRemoveId(-1);
      } else if (isModifyMode > 0) {
        updateSubstanceItem(response);
        setIsModifyMode(-1);
      } else addSubstanceItem(response);

      handleCloseModal(type);
    }
  };

  const addSubstanceItem = (item) => {
    dispatch(addDrugItem(item.data));
  };

  const removeSubstanceItem = (item) => {
    dispatch(removeDrugItem({ id: item?.data?.id }));
  };

  const updateSubstanceItem = (item) => {
    if (!item?.data?.id) {
      console.error("Missing item ID");
      return;
    }
    dispatch(
      updateDrugItem({
        id: item.data.id,
        data: item.data,
      })
    );
  };

  const submitCapForm = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const data = getValues();

    const requestPayload = {
      type: type,
    };

    if (type === "caps" || type === "bottles") {
      requestPayload.name = data?.new_warehouse_name;
      requestPayload.quantity = data?.new_warehouse_quantity;
      requestPayload.origin = data?.new_warehouse_origin;
      requestPayload.notes = data?.new_warehouse_notes;
    }

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });

    const response = await (isModifyMode > 0
      ? updateWarehouseByType(type, isModifyMode, requestPayload, axiosPrivate)
      : isRemoveId > 0
      ? deleteWarehouseByType(type, isRemoveId, axiosPrivate)
      : newWarehouseByType(type, requestPayload, axiosPrivate));

    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: type || "" })
        : getLabel(response?.error),
      type:
        !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      if (isRemoveId > 0) {
        removeWarehouseItem(type, response);
        setIsRemoveId(-1);
      } else if (isModifyMode > 0) {
        updateWarehouseItem(response);
        setIsModifyMode(-1);
      } else addWarehouseItem(type, response);

      handleCloseModal(type);
    }
  };

  const addWarehouseItem = (type, item) => {
    dispatch(addWarehouse({ type, data: item?.data }));
  };

  const removeWarehouseItem = (type, item) => {
    dispatch(removeWarehouse({ type, id: item?.data?.id }));
  };

  const updateWarehouseItem = (item) => {
    if (!item?.data?.id) {
      console.error("Missing item ID");
      return;
    }
    dispatch(
      updateWarehouse({
        type: item.type, // This should match "caps", "treatments", etc.
        id: item.data.id,
        data: item.data, // The actual updated data
      })
    );
  };

  const updateWarehouseStockItem = (item) => {
    console.log("item.data", item.data);
    if (!item?.data?.id) {
      console.error("Missing item ID");
      return;
    }

    dispatch(
      updateDrugItem({
        id: item.data.id,
        data: item.data,
      })
    );
  };

  const removeRow = (id) => {
    setIsRemoveId(id);
    setIsModalOpen(true);
  };

  const submitStockForm = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    const data = getValues();

    const id = data?.id;
    const batchesData = batches?.map((i) => {
      return {
        ...i,
        stock: Number(i?.stock),
        cost: Number(Number(i?.cost)?.toFixed(2)),
      };
    });
    const requestPayload = {
      batches: batchesData,
      ...(id && { id: Number(id) }),
    };

    // console.log("requestPayload", requestPayload);

    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    const response = await updateDrugWarehouse(
      id,
      requestPayload,
      axiosPrivate
    );

    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { name: data?.name || "" })
        : getLabel(response?.error),
      type:
        !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      updateWarehouseStockItem(response);
      handleCloseModal(type);
    }
  };

  const handleCloseModal = (type) => {
    if (["caps", "bottles", "treatments", "stock"].includes(type))
      setIsModalOpen(false);

    if (isRemoveId > 0) setIsRemoveId(-1);
    if (isModifyMode > 0) setIsModifyMode(-1);
  };

  const handleOpenModal = (modifyModeId = -1) => {
    setModifyMode(modifyModeId);

    if (type === "caps" || type === "bottles") {
      if (modifyModeId === -1) {
        reset({
          id: "",
          new_warehouse_name: "",
          new_warehouse_quantity: "",
          new_warehouse_origin: "",
          new_warehouse_notes: "",
        });
      } else if (modifyModeId > 0) {
        const c = warehouseData?.find((cc) => cc.id === modifyModeId) || {};
        setValue("id", c?.id);
        setValue("new_warehouse_name", c?.name);
        setValue("new_warehouse_quantity", c?.quantity);
        setValue("new_warehouse_origin", c?.origin);
        setValue("new_warehouse_notes", c?.notes);
      }
    } else if (type === "treatments") {
      if (modifyModeId === -1) {
        reset({
          id: "",
          name: "",
          min_range: "",
          max_range: "",
          min_temperature: "",
          max_temperature: "",
          type: "",
          ingredients: [],
        });
      } else if (modifyModeId > 0) {
        const s = substances?.find((cc) => cc.id === modifyModeId) || {};
        setValue("id", s?.id);
        setValue("name", s?.name);
        setValue("min_range", s?.min_range);
        setValue("max_range", s?.max_range);
        setValue("min_temperature", s?.min_temperature);
        setValue("max_temperature", s?.max_temperature);
        setValue("quantity", s?.quantity);
        setValue("unit_id", {
          value: s?.unit_id,
          label: s?.unit,
        });
        setValue("type", {
          value: s?.type,
          label: getLabel(`drugType_${s?.type}`),
        });
        const ingredients =
          s?.ingredients?.map((i) => {
            return { name: i?.name, quantity: Number(i?.quantity) };
          }) || [];
        setValue("ingredients", ingredients);
      }
    } else if (type === "stock") {
      if (modifyModeId === -1) {
        reset({
          id: "",
          name: "",
          unit: { label: "", value: "" },
          batches: [],
        });
      } else if (modifyModeId > 0) {
        const s = substances?.find((cc) => cc.id === modifyModeId) || {};
        setValue("id", s?.id || "");
        setValue("name", s?.name || "");
        setValue("unit_id", {
          label: s?.unit,
          value: s?.unit_id,
        });
        setValue("type", {
          label: getLabel(`drugType_${s?.type}`),
          value: s?.type,
        });
        setValue("min_range", s?.min_range || "");
        setValue("max_range", s?.max_range || "");
        setValue("min_temperature", s?.min_temperature || "");
        setValue("max_temperature", s?.max_temperature || "");
        setValue("notes", s?.notes || "");

        const unit_options = getUnitOptions(s);
        if (unit_options?.length === 1) setValue("unit", unit_options?.[0]);
        else setValue("unit", { label: "", value: "" });
        const batches = s?.batches || [
          { batchesData: { value: null, label: null } },
        ];
        setValue("batches", batches);
      }
    }
    setIsModalOpen(true);
  };

  const getUnitOptions = (substance) => {
    const unit_id = substance?.unit_id || null;
    if (!unit_id) return [];

    const options = [
      {
        value: "3",
        label: getLabel("drugBatchUnit_L"),
      },
      {
        value: "4",
        label: getLabel("drugBatchUnit_Kg"),
      },
      {
        value: "5",
        label: getLabel("drugBatchUnit_Unit"),
      },
    ];

    switch (unit_id) {
      case 1:
      case 2:
      case 4:
      case 6:
      case 7:
        // Kg
        return [options[1]];
      case 3:
        // L
        return [options[0]];
      case 5:
        // unit
        return [options[2]];
    }

    return [];
  };

  if (!["stock", "treatments", "caps", "bottles", "stock"].includes(type))
    return;

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[
          getLabel("warehouseNavLink"),
          type === "stock"
            ? getLabel("warehouseStockNavLink")
            : type === "bottles"
            ? getLabel("warehouseBottlesNavLink")
            : type === "treatments"
            ? getLabel("warehouseTreatmentNavLink")
            : type === "caps"
            ? getLabel("warehouseCapsNavLink")
            : "",
        ]}
        isBasePathNeeded={true}
      />
      {renderHeader()}
      <div className="warehouse_wrapper">
        {type === "stock" && (
          <>
            {role === ROLES.CLIENT && (
              <div className="confirmation_wrapper">
                <ConfirmationModal
                  isOpen={isModalOpen}
                  onConfirm={submitStockForm}
                  onClose={() => handleCloseModal(type)}
                  // description={getLabel(isModifyMode > 0 ? "modalUpdateStockDescription" : isRemoveId > 0 ? "modalUpdateStockDescription" : "modalUpdateStockDescription", { name: (substances?.find(s => isModifyMode > 0 ? s?.id !== isModifyMode : isRemoveId > 0 ? s?.id === isRemoveId : true))?.name || ''})}
                  description={getLabel("modalUpdateStockDescription", {
                    name:
                      substances?.find((s) =>
                        isModifyMode > 0
                          ? s?.id !== isModifyMode
                          : isRemoveId > 0
                          ? s?.id === isRemoveId
                          : true
                      )?.name || "",
                  })}
                  isOperationRecap={true}
                  totalStep={0}
                  errors={errors}
                  getValues={getValues}
                  // configs={configs}
                >
                  <OperationRecap
                    type={
                      isModifyMode > 0
                        ? "NEW_STOCK"
                        : isRemoveId > 0
                        ? "REMOVE_STOCK"
                        : "NEW_STOCK"
                    }
                    control={control}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    units={units}
                    configs={configs}
                    arrayFields={arrayFields2}
                    additionalType={type}
                    additionalData={substances?.filter((s) =>
                      isModifyMode > 0
                        ? s?.id !== isModifyMode
                        : isRemoveId > 0
                        ? s?.id === isRemoveId
                        : true
                    )}
                  />
                </ConfirmationModal>
              </div>
            )}
            <Suspense fallback={<SkeletonTableEdit colCount={3} />}>
              <TableEdit
                columns={warehouseColumns}
                defaultData={substances?.map((t) => {
                  return { ...t, opType: type?.toUpperCase() };
                })}
                type={type}
                isMobile={isMobile}
                isEditable={role === ROLES.CLIENT}
                setValue={setValue}
                removeRowHandler={removeRow}
                modalHandler={handleOpenModal}
                setModifyMode={setModifyMode}
                rowClickHandler={(row) => {
                  navigate(`/warehouse/stock/${row.original.id}`);
                }}
              />
            </Suspense>
          </>
        )}
        {type === "treatments" && (
          <>
            {renderDrugsNavMenu()}
            {role === ROLES.CLIENT && (
              <div className="confirmation_wrapper">
                <ConfirmationModal
                  isOpen={isModalOpen}
                  onConfirm={submitTreatmentForm}
                  onClose={() => handleCloseModal(type)}
                  description={getLabel(
                    isModifyMode > 0
                      ? "modalUpdateTreatmentDescription"
                      : isRemoveId > 0
                      ? "modalRemoveTreatmentDescription"
                      : "modalNewTreatmentDescription"
                  )}
                  isOperationRecap={true}
                  totalStep={0}
                  errors={errors}
                  getValues={getValues}
                  // configs={configs}
                >
                  <OperationRecap
                    type={
                      isModifyMode > 0
                        ? "NEW_TREATMENT"
                        : isRemoveId > 0
                        ? "REMOVE_TREATMENT"
                        : "NEW_TREATMENT"
                    }
                    control={control}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    units={units}
                    arrayFields={arrayFields}
                    additionalType={type}
                    additionalData={substances?.filter((s) =>
                      isModifyMode > 0
                        ? s?.id !== isModifyMode
                        : isRemoveId > 0
                        ? s?.id === isRemoveId
                        : true
                    )}
                  />
                </ConfirmationModal>
              </div>
            )}
            <Suspense fallback={<SkeletonTableEdit colCount={3} />}>
              <TableEdit
                columns={warehouseColumns}
                defaultData={substances?.map((t) => {
                  return { ...t, opType: type?.toUpperCase() };
                })}
                type={type}
                isMobile={isMobile}
                isEditable={role === ROLES.CLIENT}
                setValue={setValue}
                removeRowHandler={removeRow}
                modalHandler={handleOpenModal}
                setModifyMode={setModifyMode}
                rowClickHandler={(row) => {
                  navigate(`/warehouse/treatment/${row.original.id}`);
                }}
              />
            </Suspense>
          </>
        )}
        {(type === "caps" || type === "bottles") && (
          <>
            {role === ROLES.CLIENT && (
              <div className="confirmation_wrapper">
                <ConfirmationModal
                  isOpen={isModalOpen}
                  onConfirm={submitCapForm}
                  onClose={() => handleCloseModal(type)}
                  description={getLabel(
                    isModifyMode > 0
                      ? type === "bottles"
                        ? "modalUpdateBottleDescription"
                        : "modalUpdateCapDescription"
                      : isRemoveId > 0
                      ? type === "bottles"
                        ? "modalRemoveBottleDescription"
                        : "modalRemoveCapDescription"
                      : type === "bottles"
                      ? "modalNewBottleDescription"
                      : "modalNewCapDescription"
                  )}
                  isOperationRecap={true}
                  totalStep={0}
                  errors={errors}
                  getValues={getValues}
                  // configs={configs}
                >
                  <OperationRecap
                    type={
                      isModifyMode > 0
                        ? "NEW_WAREHOUSE"
                        : isRemoveId > 0
                        ? "REMOVE_WAREHOUSE"
                        : "NEW_WAREHOUSE"
                    }
                    control={control}
                    register={register}
                    getValues={getValues}
                    errors={errors}
                    additionalType={type}
                    additionalData={warehouseData?.filter((c) =>
                      isModifyMode > 0
                        ? c?.id !== isModifyMode
                        : isRemoveId > 0
                        ? c?.id === isRemoveId
                        : true
                    )}
                  />
                </ConfirmationModal>
              </div>
            )}
            <Suspense fallback={<SkeletonTableEdit colCount={3} />}>
              <TableEdit
                columns={warehouseColumns}
                defaultData={warehouseData?.map((c) => {
                  return { ...c, opType: type?.toUpperCase() };
                })}
                type={type}
                isMobile={isMobile}
                isEditable={role === ROLES.CLIENT}
                setValue={setValue}
                removeRowHandler={removeRow}
                modalHandler={handleOpenModal}
                setModifyMode={setModifyMode}
              />
            </Suspense>
          </>
        )}
      </div>
    </div>
  );
};

export default Warehouse;
