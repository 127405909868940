import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../../hooks/useLabels";
import { getLots, getTanks, getTankTracking } from "../../../services/utils";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { setTanks } from "../../../context/tanks/tanksSlice";
import {
  selectActiveWinery,
  selectConfigs,
  selectLots,
  selectTanks,
} from "../../../context/selectors";
import SecondaryBar from "../../SecondaryBar";
import { Button, CTA } from "../../Generic";

import "./styles/index.scss";
import { setLots } from "../../../context/lots/lotsSlice";
import Timeline from "../Timeline";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import TraceabilityTree from "../TraceabilityTree";

const Tracking = ({ type = 'tank' }) => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const configs = useSelector(selectConfigs);
  const [getLabel] = useLabels();
  const [tank, setTank] = useState({});
  const [lot, setLot] = useState({});
  const [traceData, setTraceData] = useState([]);
  const [dates, setDates] = useState({
    startDate: new Date(new Date().getFullYear() - 1, 7, 1),   // august 1st
    endDate: new Date(),                                       // today
  });
  

  const pageName = type === 'tank' ? getLabel("tankTrackingTitlePage", { name: tank?.name || id }) : 
    getLabel("lotTrackingTitlePage", { name: lot?.name || id });

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
    if (!lots || lots.length === 0) {
      loadLots();
    }

    loadTraceData();

  }, []);

  useEffect(() => {
    if (type === 'tank' && tanks && tanks.length > 0) {
      setTank(
        tanks.find((dbTank) => {
          return dbTank.id === Number(id);
        }) || {}
      );
    }
    if (type === 'lot' && lots && lots.length > 0) {
      setLot(
        lots.find((dbLot) => {
          return dbLot.id === Number(id);
        }) || {}
      );
    }
  }, [tanks, lots]);


  const loadTraceData = async () => {
    const currentTraceData = await getTankTracking(axiosPrivate, id);
    setTraceData(currentTraceData);
  };


  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate(type === 'tank' ? `/tanks/${id}` : type === 'lot' ? `/lots/${id}` : '/');
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row ctas">
          {renderInterval()}
          <NavLink onClick={'print'}>
            <CTA>{getLabel("print")}</CTA>
          </NavLink>  
        </div>
      </div>
    );
  };

  const renderTracking = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{type === 'tank' ? getLabel("tankTrackingPage") : getLabel("lotTrackingPage")}</h4>
          <Timeline type={type} dates={dates} lotName={type === 'lot' ? lot?.name : ''} labelName={type === 'tank' ? getLabel("tankTrackingTitlePage", { name: tank?.name || id }) : 
            getLabel("lotTrackingTitlePage", { name: lot?.name || id })} name={type === 'tank' ? tank?.name || id : lot?.name || id } />
          {/* <TraceabilityTree traceData={traceData} /> */}
        </div>
      </div>
    );
  };


  const renderInterval = () => {
    return (
      <div className="interval_row">
        <div className="interval_value">
          <span>{getLabel("dateFrom")}</span>
          <ReactDatePicker
            selected={dates.startDate}
            onChange={(date) =>
              setDates({
                ...dates,
                startDate: date.setHours(0, 0, 0),
              })
            }
            selectsStart
            // startDate={dates.startDate}
            // endDate={dates.endDate}
            maxDate={addDays(dates.endDate, -1)}
            dateFormat={configs.shortDateFormat}
          />
          <span>{getLabel("dateTo")}</span>
          <ReactDatePicker
            selected={dates.endDate}
            onChange={(date) =>
              setDates({
                ...dates,
                endDate: date.setHours(23, 59, 59),
              })
            }
            selectsEnd
            // startDate={dates.startDate}
            // endDate={dates.endDate}
            minDate={addDays(dates.startDate, 1)}
            maxDate={new Date()}
            dateFormat={configs.shortDateFormat}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[type === 'tank' ? getLabel("tanksNavLink") : getLabel("lotsNavLink"),
        type === 'tank' ? getLabel("tankTrackingPage", { name: ''}) : getLabel("lotTrackingPage", { name: ''})]} />
      {renderNavMenu()}
      {renderTracking()}
    </div>
  );
};

export default Tracking;
