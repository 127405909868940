import { configureStore } from '@reduxjs/toolkit';
import labelsReducer from './labels/labelsSlice';
import userReducer from './user/userSlice';
import configReducer from './configs/configsSlice';
import historyReducer from './history/historySlice';
import wineriesReducer from './wineries/wineriesSlice';
import tanksReducer from './tanks/tanksSlice';
import operationsReducer from './operations/operationsSlice';
import expOperationsReducer from './expOperations/expOperationsSlice';
import usersReducer from './users/usersSlice';
import lotsReducer from './lots/lotsSlice';
import treatmentsSlice from './protocols/treatmentsSlice';
import analysisSlice from './protocols/analysisSlice';
import drugsSlice from './substances/drugsSlice';
import examsSlice from './substances/examsSlice';
import clientsSlice from './clients/clientsSlice';
import unitsSlice from './units/unitsSlice';
import storedAnalysisResultsSlice from './substances/storedAnalysisResultsSlice';
import wineMakingSlice from './protocols/wineMakingSlice';
import wineVarietiesSlice from './wineVarieties/wineVarietiesSlice';
import wineLabelsSlice from './wineLabels/wineLabelsSlice';
import userSocketsSlice from './userSockets/userSocketsSlice';
import sensorsSlice from './sensors/sensorsSlice';
import warehouseSlice from './warehouse/warehouseSlice';

export default configureStore({
    reducer: {
        labels: labelsReducer,
        user: userReducer,
        users: usersReducer,
        configs: configReducer,
        history: historyReducer,
        wineries: wineriesReducer,
        tanks: tanksReducer,
        operations: operationsReducer,
        expOperations: expOperationsReducer,
        lots: lotsReducer,
        analysis: analysisSlice,
        treatments: treatmentsSlice,
        exams: examsSlice,
        drugs: drugsSlice,
        clients: clientsSlice,
        units: unitsSlice,
        analysisResults: storedAnalysisResultsSlice,
        wineMaking: wineMakingSlice,
        wineLabels: wineLabelsSlice,
        wineVarieties: wineVarietiesSlice,
        userSockets: userSocketsSlice,
        sensors: sensorsSlice,
        warehouse: warehouseSlice,
    }
})