import { useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  DatePicker,
  Input,
  MultipleSelect,
  Select,
  Submit,
  TextArea,
} from "../FormComponents";
import useLabels from "../../hooks/useLabels";
import { empty, setOperations } from "../../context/operations/operationsSlice";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperations,
  newOperation,
  updateOperation,
} from "../../services/utils";
import { BottleIcon, ConfirmationModal, CTA, OperationRecap } from "../Generic";
import {
  ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
  INT_REGEX,
} from "../../constants/regex";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { selectAllLotNames, selectOperations } from "../../context/selectors";
import { DestTankDetail } from "../OperationsDetail/Details";
import { NavLink } from "react-router-dom";

const BottlingForm = ({
  operation,
  lots,
  activeWinery,
  tanks,
  users,
  configs,
  type,
  isDuplicate = false,
  isMobile,
}) => {
  const { id } = useParams();
  const [getLabel] = useLabels();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isExactDate, setIsExactDate] = useState(false);
  const operations = useSelector(selectOperations);
  const lotNames = useSelector(selectAllLotNames);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      dests: operation?.dests || [
        { bottle_lot: null, cap_lot: null },
      ],
      priority: operation?.priority || "",
      expire_date: operation?.expire_date || null,
      type: { type },
      tank_id_in: operation?.tank_id_in || "",
      cellarman_ids: operation?.cellarman_ids || "",
    },
    mode: "onTouched",
  });

  const { dests } = useWatch({ control });

  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "dests",
    });
  

  const loadOperations = async () => {
    const loadedOperations = await getOperations(axiosPrivate);
    dispatch(setOperations(loadedOperations));
  };

  useEffect(() => {
    if (id && (!operations || operations?.length === 0)) loadOperations();
    if (id && operations?.length > 0) {
      const operation = operations?.find((o) => o.id === Number(id)) || null;
      if (operation === null) return;

      const tank = tanks?.find(
        (t) => t.id === operation?.src_tanks[0]?.tank_id
      );
      setValue("tank_id_in", {
        value: tank?.id,
        label: tank?.name,
        color: tank?.color,
        quantity: tank?.quantity,
        capacity: tank?.capacity,
        color: tank?.color,
        lot: tank?.batch_name,
      });

      setValue("priority", {
        label: getLabel(`operationPriority${operation?.priority}`),
        value: operation?.priority,
      });

      const dests = [];
      operation?.dest_tanks?.forEach((s) => {
        const bottles = s?.bottles || [];
        const batch_name = s?.batch_name;
        const is_new = s?.is_new === true;
          for (const bottle of bottles) {
            dests.push({
              batch_name: batch_name,
              cap_lot: bottle?.cap_lot,
              bottle_lot: bottle?.bottle_lot,
              quantity: bottle?.quantity,
              new_batch_name: is_new ? s?.batch_name : "",
              batch_name_selector:
                is_new
                  ? {
                      value: true,
                      label: getLabel("lotNewName"),
                    }
                  : {
                      value: false,
                      label: s?.batch_name,
                    },
            });
          }
      });
      replace(dests);
      
      const cellarmans = [];
      operation?.cellarman_ids?.map((u) => {
        const user = users?.find((us) => us.id === u);
        cellarmans.push({
          value: u,
          label: user?.username,
        });
      });

      setValue("cellarman_ids", cellarmans);

      setValue("expire_date", operation?.expire_date);

      setValue("note", operation?.note);
    }
  }, [operations, operation, lots]);

  const sendData = async () => {
    const data = getValues();
    const dests = [];
    const newBatchIds = [];

    try {
      data?.dests
        ?.filter(
          (d) =>
            d?.quantity &&
            ((d?.batch_name_selector?.value === true && d?.new_batch_name) ||
              (d?.batch_name_selector?.value === false &&
                d?.batch_name_selector?.label))
        )
        ?.forEach((destT, index) => {
          const batch_name_value = destT?.batch_name_selector?.value;
          const batch_name_label = destT?.batch_name_selector?.label;
          const new_batch_name = destT?.new_batch_name || batch_name_label;

          const bType =
            (batch_name_value &&
            lots?.find((l) => l?.name === batch_name_label) === undefined &&
            !newBatchIds.includes(new_batch_name)) || (!batch_name_value && lots?.find((l) => l?.name === batch_name_label && l.state !== 'BOTTLED') !== undefined)
              ? "NEW"
              : (lots?.find(
                  (l) =>
                    (l?.name === new_batch_name &&
                      batch_name_value === true) ||
                    (l?.name === batch_name_label && batch_name_value === false)
                ) ===
                  undefined) !==
                undefined
              ? (lots?.find((l) => l?.name === new_batch_name) ===
                  undefined &&
                  !batch_name_value &&
                  destT?.batch_name === undefined) ||
                (batch_name_value === true && // modify an existing operation that created PLANNED batches
                  new_batch_name?.length > 0 &&
                  !newBatchIds?.includes(new_batch_name) &&
                  lots?.find(
                    (l) => l?.name === new_batch_name && l?.status === "PLANNED"
                  ) !== undefined)
                ? "NEW" // lot not existing yet but created in another operation TO DO check
                : "OLD"
              : null;
            
          if (bType === "NEW" && !(new_batch_name in newBatchIds))
            newBatchIds.push(new_batch_name);
          else if (!bType) throw new Error();

          const new_lot_name = (batch_name_value === true
            ? new_batch_name
            : batch_name_label
          )?.trim();
          const lot = lots?.find((l) => l.name === new_lot_name);
          const dest = {
            quantity: Number(destT.quantity),
            is_new:
              batch_name_value === true && new_batch_name?.length > 0,
            new_batch_name: new_lot_name,
            new_batch_type: bType,
            batch_id:
              lot?.id ||
              "",
              cap_lot: destT.cap_lot,
              bottle_lot: destT.bottle_lot,
          };

          dests.push(dest);
        });
    } catch (err) {
      toast.error(getLabel("toast_invalidDestTanks"), {
        toastId: customIdInvalidLotName,
        position: toast.POSITION.BOTTOM_RIGHT,
        exclude: true,
        autoClose: 5000,
      });
      return;
    }
    // console.log('dests', dests)
    // return
    if (dests?.length === 0 || data?.dests?.length !== dests?.length) {
      toast.error(
        getLabel(
          dests?.length === 0
            ? "toast_missingDestTanks"
            : "toast_invalidDestTanks"
        ),
        {
          toastId: customIdInvalidLotName,
          position: toast.POSITION.BOTTOM_RIGHT,
          exclude: true,
          autoClose: 5000,
        }
      );
      return;
    }
    const tmpDate = new Date(data?.expire_date)?.setHours(23, 59, 59);
    // console.log('dests', dests)
    // return;
    const requestPayload = {
      dest_tanks: dests,
      priority: data?.priority.value,
      is_exact_date: isExactDate,
      expire_date: new Date(tmpDate)?.toISOString(),
      type: type,
      winery_id: activeWinery?.id,
      cellarman_ids: data?.cellarman_ids?.map((cellMan) => cellMan?.value),
      new_batch_name: data?.new_batch_name,
      src_tanks: [
        {
          tank_id: data?.tank_id_in?.value,
          batch_id: data?.tank_id_in?.lot_id,
          old_quantity: data?.tank_id_in?.quantity,
          new_quantity: Number(data?.tank_id_in?.quantity) - getTotalQuantity(),
        },
      ],
      note: data?.note,
    };

    console.log("requestPayload", requestPayload);
    // return;
    const toastId = toast.loading(getLabel("toast_inProgress"), {
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      exclude: true,
    });
    let response = null;

    if (id && !isDuplicate) {
      response = await updateOperation(id, requestPayload, axiosPrivate);
    } else {
      response = await newOperation(requestPayload, axiosPrivate);
    }
    console.log(response);
    toast.update(toastId, {
      render: response?.success
        ? getLabel(response?.success, { type: operation?.type || "" })
        : getLabel(response?.error),
      type:
        !response || response?.error ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
      isLoading: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
    });

    if (response && response?.success) {
      dispatch(empty());
      navClickHandler();
    }
  };

  const navClickHandler = () => {
    if (id) {
      navigate(`/programming/${type}/${id}`);
    } else {
      navigate("/programming");
    }
  };

  const getError = (idx, data, key) => {
    if (
      !errors ||
      Object.keys(errors)?.length === 0 ||
      !data ||
      data?.length <= idx
    )
      return;

    return data?.[idx]?.[key] || null;
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const openFormModal = () => {
    // trigger(); // TO DO remove trigger from all

    const expDate = new Date(getValues("expire_date"));
    if (new Date(expDate?.setHours(23, 59, 59)) < new Date()) {
      setError("expire_date", {
        type: "manual",
        message: getLabel("errorDateInThePast"),
      });
      return;
    } else clearErrors("expire_date");

    if (dests?.filter((t) => t?.quantity)?.length === 0) {
      toast(getLabel("toast_destemmingMissingDests"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }

    if (
      dests?.filter(
        (t) =>
          (!t?.batch_name_selector?.value &&
            t?.batch_name_selector?.label?.length === 0) ||
          (t?.batch_name_selector?.value === true &&
            t?.new_batch_name?.length === 0)
      )?.length > 0
    ) {
      toast(getLabel("toast_destemmingMissingLotSelectors"), {
        toastId: customIdMissingDests,
        type: toast.TYPE.ERROR,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    } else {
      toast.dismiss(customIdMissingDests);
    }
    const transferLiters = getTotalQuantity();

    const totalLitersAvailable = Number(getValues("tank_id_in")?.quantity);
    if (transferLiters <= totalLitersAvailable) {
      toast.dismiss(customIdInvalidQuantities);
      setIsOpen(true);
    } else {
      toast(
        getLabel("toast_bottlingInvalidQuantities", {
          sum: transferLiters || "0",
          total: totalLitersAvailable,
        }),
        {
          toastId: customIdInvalidQuantities,
          type: toast.TYPE.ERROR,
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      
      return;
    }
  };

  const customIdMissingDests = "custom-id-missing-dests";
  const customIdInvalidLotName = "custom-id-invalid-lot-name";
  const customIdInvalidQuantities = "custom-id-invalid-quantities";
  const customIdInvalidBatchTypes = "custom-id-invalid-batch-types";
  const customId = "custom-id-different-color";
  const checkColors = () => {
    const colors = new Set();
    dests?.reduce(
      (_, item) =>
        item?.color ? colors.add(item?.color) : null,
      null
    );
    if (getValues("tank_id_in")?.color)
      colors.add(getValues("tank_id_in")?.color);

    if (colors?.size > 1)
      toast(getLabel("toast_WarningDiffentWineColor"), {
        toastId: customId,
        type: toast.TYPE.WARNING,
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
      });
    else toast.dismiss(customId);
  };

  const getTotalQuantity = () => {
    return (
      dests?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0) || 0
    );
  };

  const computeLotNames = (isValidate = false) => {
    const names = new Set();

    // shows only 6 months old must batches. TO DO: what if a batch of the previous year has the same name?? I hope it is deactivated
    // lots?.filter((l) => (l?.state === 'MUST' || l?.state === 'WINE') && (new Date(l?.insertion_date) >= addMonths(new Date(), -6)))?.reduce((_, l) => names.add(l?.name), null);
    lotNames
      ?.filter((l) => l?.state === "MUST" || l?.state === "WINE")
      ?.reduce((_, l) => names.add(l?.name), null);

    if (dests?.length === 1 && dests?.[0]?.value === null)
      return names;
    dests
      ?.filter((tank) => tank?.quantity > 0)
      ?.reduce((_, item) => names.add(item?.lot), null);

    if (!isValidate) {
      for (const tank of dests) {
        if (tank?.new_batch_name?.length > 0) names.add(tank?.new_batch_name);
      }
      let i = 0;
      for (const tank of dests?.filter(
        (t) => t?.value && "batch_name_selector" in t
      )) {
        if (
          tank?.batch_name_selector?.value === false &&
          !Array.from(names.values())?.includes(
            tank?.batch_name_selector?.label
          )
        ) {
          setValue(`dests.${i}.batch_name_selector`, {
            value: "",
            label: "",
          });
        }
        i += 1;
      }
    }

    const nameObjects = [];
    names?.forEach((s) => {
      if (s) nameObjects.push({ value: false, label: s });
    });

    return nameObjects?.sort((a, b) =>
      a?.label > b?.label ? 1 : b?.label > a?.label ? -1 : 0
    );
  };

  const getTankData = (t, isDetail = false) => {
    const tank = t; //!isDetail ? t?.tankData : t;
    const quantity = !isDetail
      ? tank?.availability >= Number(t?.quantity) && Number(t?.quantity) >= 0
        ? Number(t?.quantity)
        : 0
      : -getTotalQuantity();
    const new_batch_name = !isDetail
      ? t?.batch_name_selector?.value === true && t?.new_batch_name
        ? t.new_batch_name
        : t?.batch_name_selector?.value === false
        ? t?.batch_name_selector?.label
        : ""
      : tank?.lot;

    const tankData = {};
    tankData.name = t?.label;
    tankData.is_src = isDetail;
    tankData.arrow_quantity = getTotalQuantity();
    tankData.capacity = tank.capacity;
    tankData.old_quantity = tank.quantity;
    tankData.old_batch_name = tank.lot || "";
    tankData.quantity = tank.quantity - tankData.arrow_quantity;
    tankData.color =
      tank.quantity > 0 ? tank?.color : getValues("batch_id_in")?.color;
    tankData.batch_name = tank.quantity - tankData.arrow_quantity <= 0 ? " " : tankData?.old_batch_name;

    if (tankData?.quantity < 0 && isDetail) {
      tankData.quantity = 0;
      tankData.arrow_quantity = -Math.abs(tankData?.old_quantity);
    }

    return tankData;
  };

  return (
    <div className="operation_form_wrapper">
      <form
        onSubmit={handleSubmit(openFormModal)}
        autoComplete="off"
        noValidate
      >
        <h6>{getLabel("fromTank")}</h6>
        <div className="operation_form_wrapper_row">
          <Select
            control={control}
            defaultValue={operation?.tank_id_in}
            name="tank_id_in"
            // isDisabled={wineMakingData?.dest_tanks}
            label={getLabel("operationTransferTank")}
            placeholder={getLabel("operationTransferTankPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={tanks?.filter((t) => t.quantity > 0 && t.state === 'WINE')
              ?.filter(
                (tank) =>
                  !dests?.some?.(
                    (field) => field?.value == tank.id
                  )
              )
              ?.map?.((tank) => {
                return {
                  value: tank.id,
                  label: tank.name,
                  name:
                    tank?.name +
                    ` (${
                      lots?.find((lot) => lot?.id === tank?.batch_id)?.name ||
                      getLabel("tankState_EMPTY")
                    })`,
                  quantity: tank.quantity,
                  capacity: tank.capacity,
                  color: tank.color,
                  lot: tank.batch_name,
                  lot_id: tank?.batch_id || "",
                };
              })}
          />
          {getValues("tank_id_in") && (
            <>
              <DestTankDetail
                tank={getTankData(getValues("tank_id_in"), true)}
                isDrop={false}
                isName={false}
                isFirst={true}
                isMobile={isMobile}
              ></DestTankDetail>
            </>
          )}
        </div>
        <div className="operation_form_wrapper_row vertical">
          <h6>{getLabel("toTanks")}</h6>
          {fields?.map?.((field, idx) => {
            return (
              <div key={field.id} className="operation_form_wrapper_row">
                <Input
                  name={`dests.${idx}.quantity`}
                  register={register}
                  type="number"
                  label={getLabel(`quantity`)}
                  placeholder={getLabel(
                    "operationDestTanksQuantityPlaceholder"
                  )}
                  required={getLabel("inputRequiredError")}
                  max={{
                    value: Number(getValues("tank_id_in")?.quantity || 0),
                    message: getLabel("errorMaxValue", {
                      value: Number(getValues("tank_id_in")?.quantity || 0)
                    }),
                  }}
                  min={{
                    value: 1,
                    message: getLabel("errorMinValue", { value: 1 }),
                  }}
                  regExpPattern={{
                    value: INT_REGEX,
                    message: getLabel("errorInt"),
                  }}
                  error={getError(idx, errors?.dests, "quantity")}
                />
                <BottleIcon
                  color={getValues('tank_id_in')?.color}
                  batch_name={
                    (dests?.[idx]?.batch_name_selector?.value === true
                    ? dests?.[idx]?.new_batch_name
                    : dests?.[idx]?.batch_name_selector?.label)
                  || dests?.[idx]?.batch_name || ""}
                  capLot={dests?.[idx]?.cap_lot}
                  bottleLot={dests?.[idx]?.bottle_lot}
                  isDetail={true}
                />
                {dests?.[idx]?.quantity > 0 && <>
                  <Input
                    name={`dests.${idx}.bottle_lot`}
                    register={register}
                    label={getLabel('bottleLot')}
                    placeholder={getLabel(
                      "bottleLotPlaceholder"
                    )}
                    required={getLabel("inputRequiredError")}
                    minLength={{
                      value: 3,
                      message: getLabel("errorMinLength", { value: 3 }),
                    }}
                    maxLength={{
                      value: 24,
                      message: getLabel("errorMaxLength", { value: 24 }),
                    }}
                    regExpPattern={{
                      value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                      message: getLabel("errorAlphaNumSpace_-"),
                    }}
                    tooltip={getLabel("bottleLotTooltip")}
                    error={getError(idx, errors?.dests, "bottle_lot")}
                  />
                  <Input
                    name={`dests.${idx}.cap_lot`}
                    register={register}
                    label={getLabel('capLot')}
                    placeholder={getLabel(
                      "capLotPlaceholder"
                    )}    
                    required={getLabel("inputRequiredError")}
                    minLength={{
                      value: 3,
                      message: getLabel("errorMinLength", { value: 3 }),
                    }}
                    maxLength={{
                      value: 24,
                      message: getLabel("errorMaxLength", { value: 24 }),
                    }}
                    regExpPattern={{
                      value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                      message: getLabel("errorAlphaNumSpace_-"),
                    }}
                    tooltip={getLabel("capLotTooltip")}    
                    error={getError(idx, errors?.dests, "cap_lot")}
                  />
                  <div className="new_batch_name">
                    <Select
                      control={control}
                      defaultValue={{
                        value: "",
                        label: "",
                      }}
                      name={`dests.${idx}.batch_name_selector`}
                      // name={`batch_name_selector.${idx}`}
                      // name="batch_name_selector"
                      label={getLabel("operationNewLotName")}
                      placeholder={getLabel(
                        "operationNewLotNamePlaceholder"
                      )}
                      error={errors}
                      required={getLabel("inputRequiredError")}
                      options={[
                        {
                          value: true,
                          label: getLabel("lotNewName"),
                          className: "bold",
                        },
                        ...computeLotNames(),
                      ]}
                      tooltip={
                        idx === 0
                          ? getLabel(
                              "destemmingTankDestBatchNameSelectorTooltip"
                            )
                          : ""
                      }
                    />
                    {getValues(`dests.${idx}.batch_name_selector`)
                      ?.value && (
                      <Input
                        name={`dests.${idx}.new_batch_name`}
                        error={getError(
                          idx,
                          errors?.dests,
                          "new_batch_name"
                        )}
                        // name="new_batch_name"
                        register={register}
                        type="text"
                        label={getLabel("operationNewBatchName")}
                        placeholder={getLabel(
                          "operationNewBatchNamePlaceholder"
                        )}
                        // error={errors["new_batch_name"]}
                        required={getLabel("inputRequiredError")}
                        isReverse={true}
                        minLength={{
                          value: 3,
                          message: getLabel("errorMinLength", { value: 3 }),
                        }}
                        maxLength={{
                          value: 24,
                          message: getLabel("errorMaxLength", {
                            value: 24,
                          }),
                        }}
                        regExpPattern={{
                          value: ALPHA_NUM_SPACE_DASH_UNDERSCORE_REGEX,
                          message: getLabel("errorAlphaNumSpace_-"),
                        }}
                        validate={(value) => {
                          if (
                            getValues(
                              `dests.${idx}.batch_name_selector`
                            )?.value &&
                            lots
                              ?.map((l) => l?.status !== 'PLANNED' && l?.name?.toLowerCase?.())?.includes(value?.toLowerCase?.()?.trim())
                          ) {
                            return getLabel(
                              "errorNewBatchNameAlreadyTaken",
                              {
                                name: value,
                              }
                            );
                          }
                          // else {
                          // toast.dismiss(customIdInvalidLotName);
                          // return true;
                          // }
                        }}
                      />
                    )}
                  </div>
                </>}
                {fields?.length > 1 && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      remove(idx);
                    }}
                  >
                    -
                  </button>
                )}
              </div>
            );
          })}
          <button
            id="tanksD"
            onClick={(e) => {
              e.preventDefault();
              append({ bottle_lot: null, cap_lot: null });
            }}
          >
            +
          </button>
        </div>
        <h6>{getLabel("operationAdditional")}</h6>
        <div className="operation_form_wrapper_row">
          <MultipleSelect
            name={`cellarman_ids`}
            control={control}
            label={getLabel("operationUser")}
            placeholder={getLabel("operationUserPlaceholder")}
            error={errors["cellarman_ids"]}
            required={getLabel("inputRequiredError")}
            options={users?.map?.((user) => {
              return {
                value: user?.id,
                label: user?.username,
              };
            })}
            tooltip={getLabel("destemmingCellarmenTooltip")}
          />
          <Select
            name="priority"
            control={control}
            label={getLabel("operationPriority")}
            placeholder={getLabel("operationPriorityPlaceholder")}
            error={errors}
            required={getLabel("inputRequiredError")}
            options={[
              {
                label: getLabel("lotQuality_HIGH"),
                value: "2",
              },
              {
                label: getLabel("lotQuality_MEDIUM"),
                value: "1",
              },
              {
                label: getLabel("lotQuality_LOW"),
                value: "0",
              },
            ]}
            // defaultValue={{
            //     value: "",
            //     label: "",
            //   }}
          />
          <DatePicker
            name="expire_date"
            control={control}
            label={getLabel("operationExpireDateForm")}
            error={errors["expire_date"]}
            required={getLabel("inputRequiredError")}
            dateFormat={configs.shortDateFormat}
            placeholder={getLabel("expiringDatePlaceholder")}
          />
          {getValues("expire_date") && <Input
            name={"expire_date_exact"}
            register={register}
            label={getLabel("isExactDate")}
            error={errors["expire_date_exact"]}
            // isLabel={false}
            type={"checkbox"}
            checked={isExactDate}
            onChange={() => setIsExactDate((prev) => !prev)}
            customClassName={"confirm"}
            tooltip={getLabel("destemmingExactDate")}
          />}
        </div>
        
        <div className="operation_form_wrapper_row">
          <TextArea
            name="note"
            register={register}
            label={getLabel("clientNotes")}
            placeholder={getLabel("clientNotesPlaceholder")}
            error={errors["note"]}
          />
        </div>
        <div className="operation_form_wrapper_row">
          <Submit label={getLabel("submitForm")}></Submit>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isOpen}
        onConfirm={sendData}
        onClose={handleCloseModal}
        description={getLabel("modalNewBottlingDescription")}
        isOperationRecap={true}
        totalStep={0}
        errors={errors}
        getValues={getValues}
        // setNewBatchName={setNewBatchName}
      >
        <OperationRecap
          type={type}
          dest_tanks={dests?.map((d, index) => {
            return {
              ...d,
              name: d?.label,
              color: d?.color || getValues("tank_id_in")?.color,
              add_quantity: Number(d?.quantity),
              batch_name:
                d?.batch_name_selector?.value === true
                  ? d?.new_batch_name
                  : d?.batch_name_selector?.label,
            };
          })}
          src_tanks={[getTankData(getValues("tank_id_in"))]}
        />
      </ConfirmationModal>
    </div>
  );
};

export default BottlingForm;
