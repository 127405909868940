import useLabels from "../../hooks/useLabels";
import SecondaryBar from "../SecondaryBar";
import { CTA, InfoCard } from "../Generic";
import { selectActiveWinery, selectDrugs, selectTanks } from "../../context/selectors";
import { getDrugs, getTanks } from "../../services/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ROLES } from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import tank from "../../assets/icons/tank.png";
import ReportCard from "./ReportCard";
import { setTanks } from "../../context/tanks/tanksSlice";
import { Select } from "../FormComponents";
import { useForm, useWatch } from "react-hook-form";

const Report = ({ isMobile }) => {
  const [getLabel] = useLabels();
  const { auth } = useAuth();
  const role = auth?.role || ROLES.GUEST;
  const activeWinery = useSelector(selectActiveWinery);
  const tanks = useSelector(selectTanks);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      tank_id: { label: "", value: "" }
    },
    mode: "onTouched",
  });

  const { tank_id } = useWatch({ control });

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery?.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
  }, []);

  const renderHeader = () => {
    return (
      <div className="primary_container_menu">
        <h2>{getLabel("report")}</h2>
      </div>
    );
  };

  const renderTanksReport = () => {
    return (<div className="card_wrapper">
      <h6 className="card_title">{getLabel("reportTanks")}</h6>
      <div className="card">
        <div className="card_row">
          <ReportCard
            img={tank}
            alt_img={""}
            isMobile={isMobile}
          >
            <Select
              control={control}
              name="tank_id"
              isDisabled={tanks?.filter((t) => t.quantity > 0)?.length === 0}
              label={getLabel("operationDestTankName")}
              placeholder={getLabel("operationTransferTankPlaceholder")}
              // required={getLabel("inputRequiredError")}
              options={tanks
                ?.filter((t) => t.quantity > 0)
                ?.map?.((tank) => {
                  return {
                    value: tank.id,
                    label: tank.name,
                    name:
                      tank?.name +
                      ` (${tank?.batch_name ||
                        getLabel("tankState_EMPTY")
                      })`,
                  };
                })}
            />
            {tank_id?.value && <div>
              <Select
                control={control}
                name="tank_select"
                isDisabled={tanks?.filter((t) => t.quantity > 0)?.length === 0}
                label={getLabel("operationDestTankName")}
                placeholder={getLabel("operationTransferTankPlaceholder")}
                // required={getLabel("inputRequiredError")}
                options={[
                  {
                    value: "",
                    label: "",
                  }, {
                    value: "",
                    label: "costi",
                  }, {
                    value: "",
                    label: "prodotti",
                  },
                  ]
                }
              />
            </div>}
          </ReportCard>
        </div>
      </div>
    </div>);
  }

  const renderCostsReport = () => {
    return (<div className="card_wrapper">
      <h6 className="card_title">{getLabel("reportCosts")}</h6>
      <div className="card">
        <div className="card_row">
          <ReportCard
            img={tank}
            alt_img={""}
            isMobile={isMobile}
          >
            <Select
              control={control}
              name="tank_id"
              isDisabled={tanks?.filter((t) => t.quantity > 0)?.length === 0}
              label={getLabel("operationDestTankName")}
              placeholder={getLabel("operationTransferTankPlaceholder")}
              // required={getLabel("inputRequiredError")}
              options={tanks
                ?.filter((t) => t.quantity > 0)
                ?.map?.((tank) => {
                  return {
                    value: tank.id,
                    label: tank.name,
                    name:
                      tank?.name +
                      ` (${tank?.batch_name ||
                        getLabel("tankState_EMPTY")
                      })`,
                  };
                })}
            />
            {tank_id?.value && <div>
              <Select
                control={control}
                name="tank_select"
                isDisabled={tanks?.filter((t) => t.quantity > 0)?.length === 0}
                label={getLabel("operationDestTankName")}
                placeholder={getLabel("operationTransferTankPlaceholder")}
                // required={getLabel("inputRequiredError")}
                options={[
                  {
                    value: "",
                    label: "",
                  }, {
                    value: "",
                    label: "costi",
                  }, {
                    value: "",
                    label: "prodotti",
                  },
                  ]
                }
              />
            </div>}
          </ReportCard>
        </div>
      </div>
    </div>);
  }

  return (
    <div className="primary_container">
      <SecondaryBar
        breadCrumb={[getLabel("reportNavLink")]}
        isBasePathNeeded={true}
      />
      {renderHeader()}
      {renderTanksReport()}
      {renderCostsReport()}
    </div>
  );
};

export default Report;
