import { useSelector } from "react-redux";
import "./styles/index.scss";
import { selectActiveWinery } from "../../context/selectors";
import useLabels from "../../hooks/useLabels";
import { useNavigate } from "react-router-dom";

const SecondaryBar = ({ breadCrumb, isBasePathNeeded = true }) => {
  const activeWinery = useSelector(selectActiveWinery);
  const [getLabel] = useLabels();
  const navigate = useNavigate();

  return (
    <div className="secondary_bar_container">
      <div className="secondary_bar_title">
        {isBasePathNeeded && (
          <span>
            {getLabel("winery", { activeWinery: activeWinery?.name })}
          </span>
        )}
        {breadCrumb?.map((page, index) => {
          if (typeof page === "object" && page.link) {
            return (
              <span
                key={index}
                onClick={() => navigate(page.link)}
                className="secondary_bar_link"
              >
                {page.label}
              </span>
            );
          } else {
            return <span key={index}>{page}</span>;
          }
        })}
      </div>
    </div>
  );
};

export default SecondaryBar;
