import { useParams } from "react-router-dom";
import Loader from "../Loader";
import loadable from "@loadable/component";

const LazySubstanceWarehouseForm = loadable(() => import("../SubstanceWarehouseForm"), { fallback: <Loader/> });
const LazySubstanceForm = loadable(() => import("../SubstanceForm"), { fallback: <Loader/> });

// maintain type param in the components
// but if type === stock use the LazySubstanceWarehouseForm
// if treatments use LazySubstanceForm

const WarehouseUpdateRouter = () => {
  const { type } = useParams();

  if (type === "stock") {
    return <LazySubstanceWarehouseForm />;
  }
  
  return <LazySubstanceForm />;
};

export default WarehouseUpdateRouter;
