import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useLabels from "../../../hooks/useLabels";
import { getLots, getLotTrackingTree, getTanks, getTankTrackingTree } from "../../../services/utils";
import ReactFlowProvider from "react-flow-renderer";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { setTanks } from "../../../context/tanks/tanksSlice";
import {
  selectActiveWinery,
  selectConfigs,
  selectLots,
  selectTanks,
} from "../../../context/selectors";
import SecondaryBar from "../../SecondaryBar";
import { Button, CTA } from "../../Generic";

import "./styles/index.scss";
import { setLots } from "../../../context/lots/lotsSlice";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import FlowChart from "../FlowChart";

import './styles/index.scss'
import Loader from "../../Loader";

const TrackingTree = ({ type = 'tank' }) => {
  const activeWinery = useSelector(selectActiveWinery);
  const { id } = useParams();
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const tanks = useSelector(selectTanks);
  const lots = useSelector(selectLots);
  const configs = useSelector(selectConfigs);
  const [getLabel] = useLabels();
  const [tank, setTank] = useState({});
  const [lot, setLot] = useState({});
  const [traceData, setTraceData] = useState([]);
  const [showOperationData, setShowOperationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState({
    startDate: new Date(new Date().getFullYear() - 1, 7, 1),   // august 1st
    endDate: new Date(),                                       // today
  });
  

  const pageName = type === 'tank' ? getLabel("tankTrackingTreeTitlePage", { name: tank?.name || id }) : 
    getLabel("lotTrackingTreeTitlePage", { name: lot?.name || id });

  useEffect(() => {
    if (!tanks || tanks.length === 0) {
      loadTanks();
    }
    if (!lots || lots.length === 0) {
      loadLots();
    }

    loadTraceData();
  }, []);

  useEffect(() => {
    if (type === 'tank' && tanks && tanks.length > 0) {
      setTank(
        tanks.find((dbTank) => {
          return dbTank.id === Number(id);
        }) || {}
      );
    }
    if (type === 'lot' && lots && lots.length > 0) {
      setLot(
        lots.find((dbLot) => {
          return dbLot.id === Number(id);
        }) || {}
      );
    }
  }, [tanks, lots]);

  const loadTraceData = async () => {
    const currentTraceData = await (type === 'tank' ? getTankTrackingTree(axiosPrivate, id) : getLotTrackingTree(axiosPrivate, id));
    // const filteredTransfers = filterTransfersByTankId(currentTraceData, id);

    // setTraceData(filteredTransfers);
    console.log('currentTraceData', currentTraceData)
    setTraceData(currentTraceData);
    setIsLoading(false);
  };

  const  filterTransfersByTankId = (data, tankId) => {
    return data.map(operation => ({
      ...operation,
      transfers: operation.transfers.filter(transfer => transfer.destination_tank_id === Number(tankId))
  }));
  }

  const loadTanks = async () => {
    const currentTanks = await getTanks(activeWinery.id, axiosPrivate);
    dispatch(setTanks(currentTanks));
  };

  const loadLots = async () => {
    const currentLots = await getLots(activeWinery.id, axiosPrivate);
    dispatch(setLots(currentLots));
  };

  const renderNavMenu = () => {
    const navClickHandler = () => {
      navigate(type === 'tank' ? `/tanks/${id}` : type === 'lot' ? `/lots/${id}` : '/');
    };
    return (
      <div className="primary_container_menu">
        <div className="primary_container_row">
          <Button arrowDirection="left" onClick={navClickHandler} />
          <h2>{pageName}</h2>
        </div>
        <div className="primary_container_row ctas">
          {renderInterval()}
          <NavLink onClick={'print'}>
            <CTA>{getLabel("print")}</CTA>
          </NavLink>  
        </div>
      </div>
    );
  };

  const toggleShowOperationData = (operation_id) => {
    setShowOperationData(operation_id);
  }

  const renderTrackingTree = () => {
    return (
      <div>
        <div className="primary_container_menu">
          <h4>{type === 'tank' ? getLabel("tankTrackingTreePage") : getLabel("lotTrackingTreePage")}</h4>
          <div className="panel-wrapper">
            <div className="left-panel">
              {isLoading && <div className="loader_wrapper"><Loader /></div>}
              {!isLoading && traceData?.length === 0 && <h5 className="alert_no_info">{getLabel("tankTrackingTreeNoData")}</h5>}
              {!isLoading && traceData?.length > 0 && 
                <FlowChart operations={traceData} toggleShowOperationData={toggleShowOperationData} />
              }
              {/* </ReactFlowProvider> */}
            </div>
            {(showOperationData !== null) && <div className="right-panel">
              <p>{showOperationData}</p>
            </div>}
          </div>
        </div>
      </div>
    );
  };


  const renderInterval = () => {
    return (
      <div className="interval_row">
        <div className="interval_value">
          <span>{getLabel("dateFrom")}</span>
          <ReactDatePicker
            selected={dates.startDate}
            onChange={(date) =>
              setDates({
                ...dates,
                startDate: date.setHours(0, 0, 0),
              })
            }
            selectsStart
            // startDate={dates.startDate}
            // endDate={dates.endDate}
            maxDate={addDays(dates.endDate, -1)}
            dateFormat={configs.shortDateFormat}
          />
          <span>{getLabel("dateTo")}</span>
          <ReactDatePicker
            selected={dates.endDate}
            onChange={(date) =>
              setDates({
                ...dates,
                endDate: date.setHours(23, 59, 59),
              })
            }
            selectsEnd
            // startDate={dates.startDate}
            // endDate={dates.endDate}
            minDate={addDays(dates.startDate, 1)}
            maxDate={new Date()}
            dateFormat={configs.shortDateFormat}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="primary_container">
      <SecondaryBar breadCrumb={[type === 'tank' ? getLabel("tanksNavLink") : getLabel("lotsNavLink"),
        type === 'tank' ? getLabel("tankTrackingTreePage", { name: ''}) : getLabel("lotTrackingTreePage", { name: ''})]} />
      {renderNavMenu()}
      {renderTrackingTree()}
    </div>
  );
};

export default TrackingTree;
